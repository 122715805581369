.info-bar {
  background: @blue;
  padding: 1rem;
  color: white;
  margin-top: 2px;
  font-weight: 300;
  font-family: open_sanslight, sans-serif;

  p {
    margin: 0;
  }

  a {
    color: @orange;
  }
}