.dispatch-client {
    padding: 0 0 10px 0;

    h3 {
        margin: 5px 20px 10px 0;
        display: inline-block;
        vertical-align: middle;
        color: @blue;
    }

    .link {
        height: auto;
    }
}

.dispatch-client-contracts {
    .dataTable;
    margin: 15px 0 70px 0;

    .buttons {
        text-align: center;
    }

    .inline-buttons {
        width: 400px;

        a {
            height: auto;
        }
    }
}

.dispatch-add-client,
.dispatch-edit-client {
    margin-top: 50px;

    h3 {
        margin-bottom: 30px;
    }

    .headings {

        &.blue {
            color: @blue;
        }

        &.red {
            color: @red;
        }

        &.big {
            font-size: 1.625em;
            margin-bottom: 30px;
        }

        &.medium {
            font-size: 1.250em;
            margin-bottom: 25px;
        }
    }

    .buttons {
        .tabButton {
            background: @footerBg;
            border: none;
            border-top: 3px solid @blue;
            font-size: 1.25em;
            text-align: center;

            padding: 20px 15px;
            margin-right: 7px;
            width: 220px;
            display: inline-block;

            &:hover {
                color: @red;
            }

            &.active {
                color: @red;
                border-top: 3px solid @red;
                .font-bold;
            }
        }
    }

    .container {
        background: @footerBg;
        padding: 50px 30px 30px 30px;
        margin: 0 -30px;
    }

    .fCell {
        margin-bottom: 35px;
    }

    label {
        .formLabelBig;
    }
}

.dispatch-add-client,
.dispatch-edit-client,
.dispatch-form,
#RCDispatchNote_form {

    .dijit.checkbox,
    input[dojotype="dijit.form.CheckBox"].checkbox {
        float: left;
        margin-right: 15px;
        margin-top: 3px;
    }

    #documents_array {
        padding-bottom: 20px;

        h4 {
            color: @blue;
            margin-top: 25px;
            font-size: 1.65em;

            .required {
                color: @red;
            }
        }

        table {
            border-collapse: collapse;
            margin: 15px 0;
            width: 100%;

            td {
                padding: 10px 15px 10px 0;
            }
        }

        .item {
            margin: 15px 15px 15px 0;
            min-width: 30%;
            display: inline-block;
            vertical-align: top;

            label {
                .checkBoxLabel;
                margin: 0;

                span {
                    width: auto;
                    float: none;
                    display: inline;
                }
            }
        }
    }

    label[for="form_element_form_foreign"] {
        .checkBoxLabel;
    }
}

#RCDispatchNote_form {
    label {
        .formLabelBig;
        margin-top: 35px;
    }

    .dijitTextBoxDisabled .dijitButtonNode {
        right: 4px;
        top: 4px;

        .dijitArrowButtonInner {
            margin-left: 4px;
        }
    }
}

.dispatch-form {
    padding-left: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
    border-left: 3px solid @blue;

    label {
    }
}

.dispatch-preview {
    ul {
        list-style-type: disc !important;
        margin: 5px 10px 10px 30px !important;
        padding: 0 !important;

        li {
            background: none !important;
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    .details {
        margin-left: 30px;

        h3 {
            font-size: 1.5em;
        }
    }
}

.smlouvy {
    .dispatchBtn.icon-preview {
        display: inline-block;
        margin-bottom: 15px;
        height: auto;
    }
}

.dispatchBtn.icon-preview {
    @media @md {
        margin-left: 20px !important;
    }

    @media @xs {

        margin-top: 20px !important;
    }
}

@media @sm {

    .dispatch-client h3 {
        display: block;
    }

    .dispatchBtn {
        margin-left: 20px !important;
    }
}

