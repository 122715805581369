img {
    border: none;
}

p {
    margin-bottom: 15px;
}

sup {
    bottom: 0.3em;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.2em;
    position: relative;
    vertical-align: baseline;
}

strong {
    .font-bold;
}

a, .defaultLink {
    background: transparent;
    color: @links;
    outline: 0;
    .trans();

    &:hover {
        color: @linksHover;
    }
}

a.selected {
    cursor: text;
    text-decoration: none;
}

#content ul,
#content ol,
#rightCol ul,
#rightCol ol {
    list-style: none;
    margin: 0 0 15px 0;
}

#content ul ul,
#content ol ol,
#rightCol ul ul,
#rightCol ul ul {
    margin: 5px 0 0;
}

.-hidden{
    display: none;
}
