.link,
.button {
    background: @blue;
    border: none;
    color: @white;
    line-height: normal;
    display: inline-block;
    text-decoration: none;
    border-radius: 3px;
    outline: none;
    font-size: 0.875em;
    padding: 7px 15px;
    height: 34px;
    .font-regular;

    @media @md {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .border-box;
    .box-shadow(none);
    .trans();

    .dijitButtonNode {
        background: none;
        border: none;
        padding: 0;
    }

    .dijitButtonText {
        background: none;
        border: none;
        padding: 0;
    }

    .dijitInline {
        display: inline;
    }

    &:hover,
    &:focus,
    &:active {
        color: @orange;
        border: none;
    }

    &.active {
        background: @orange;
        color: @blue;
    }

    &.-outline {
        background-color: transparent;
        border: 1px solid @blue;
        color: @blue;
        line-height: 1.3em;

        @media @md {
            line-height: normal;
        }

        &:hover,
        &:focus,
        &:active {
            color: @blue;
            border: 1px solid @blue;
            background-color: @orange;
        }

        &.active {
            background: @blue;
            color: white;
            cursor: auto;
        }
    }

    &.smallBtn {
        vertical-align: middle;
        font-size: 0.875em;
        padding: 7px 25px;
        height: 34px;
    }

    &.dispatchBtn {
        .defaultLink;
        .resetLink;
        position: relative;

        font-size: 1em;
        padding: 0;
        margin: 0 10px 4px 30px;
        .font-regular;


        &:before {
            content: '';
            display: block;
            .size(15px, 19px);
            position: absolute;
            left: -20px;
            top: 50%;
            margin-top: -9px;

            background-color: transparent;
            background-repeat: no-repeat;
            background-position: left top;

        }

        &.icon-delete {
            &:before {
                background-image: url("@{images}/icon_red-trash_small.png");
            }
        }

        &.icon-edit {
            &:before {
                background-image: url("@{images}/icon_red-pen_small.png");
            }
        }

        &.icon-preview {
            &:before {
                background-image: url("@{images}/icon_red-eye_small.png");
            }
        }

        &.icon-close {
            &:before {
                background-image: url("@{images}/icon_red-lock_small.png");
            }
        }

        &.icon-print {
            &:before {
                background-image: url("@{images}/icon_red-printer_small.png");
            }
        }

        &.icon-newContract {
            &:before {
                background-image: url("@{images}/icon_red-doc_small.png");
            }
        }

        &.icon-contracts {
            &:before {
                background-image: url("@{images}/icon_red-folder_small.png");
            }
        }
    }

    &.dispatchBtnIcon {
        .size(15px, 19px);
        padding: 0;
        display: inline-block;

        background-color: transparent;
        background-repeat: no-repeat;
        background-position: left top;

        span {
            display: none;
        }

        &.icon-delete {
            background-image: url("@{images}/icon_red-trash_small.png");
        }

        &.icon-edit {
            background-image: url("@{images}/icon_red-pen_small.png");
        }

        &.icon-preview {
            background-image: url("@{images}/icon_red-eye_small.png");
        }

        &.icon-close {
            background-image: url("@{images}/icon_red-lock_small.png");
        }

        &.icon-print {
            background-image: url("@{images}/icon_red-printer_small.png");
        }

        &.icon-newContract {
            background-image: url("@{images}/icon_red-doc_small.png");
        }

        &.icon-contracts {
            background-image: url("@{images}/icon_red-folder_small.png");
        }
    }
}

.resetLink,
.resetButton {
    padding: 0;
    font-size: 1em;
    display: inline;
    .font-regular;
}

