.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.clr {
    border: none;
    clear: both;
    height: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    visibility: hidden;
}

.clear {
    clear: both;
}

.none {
    display: none;
}

.bold {
    .font-bold;
}

.italic {
    font-style: italic;
}

.nopadding {
    padding: 0;
}

.noindent {
    margin-left: 0;
    padding-left: 0;
}

.nobullet {
    list-style: none;
}

.small {
    font-size: 0.9em;
}

.hide {
    left: 0;
    position: absolute;
    top: -25000px;
}

.red-text {
    color: @red;
}

.mt15 {
    margin-top: 15px;
}

.fs-13 {
    font-size: 13px;
}

.floatleft {
    float: left;
    margin: 0 10px 10px 0;
}

.floatright {
    float: right;
    margin: 0 0 10px 10px;
}

.rgt {
    float: right;
}

.lft {
    float: left;
}

img.lft {
    margin: 0 15px 7px 0;
}

img.rgt {
    margin: 0 0 7px 15px;
}

.toPrint {
    display: none;
}

.mobileDevice {
    display: none;
}

.tabletOnly {
    display: none;
}

#isTabletWrapper {
    display: none;
}

.middle {
    vertical-align: middle;
}


@media @lg {

    .tabletOnly {
        display: block;
    }

    span.tabletOnly {
        display: inline;
    }

    #isTabletWrapper {
        display: block;
    }
}

@media @md {

    .tabletOnly,
    span.tabletOnly,
    #isTabletWrapper {
        display: none;
    }

    .desktopDevice {
        display: none;
    }

    .mobileDevice {
        display: block;
    }
}
