@import "reset.less";
@import "variables.less";
@import "fonts.less";
@import "mixins.less";

@import "components/breadcrumbs";
@import "components/buttons";
@import "components/calendar";
@import "components/clients";
@import "components/common";
@import "components/contacts";
@import "components/data-export";
@import "components/detail-info";
@import "components/dialog";
@import "components/dijit-edits";
@import "components/discussion";
@import "components/dispatch";
@import "components/document";
@import "components/file-storage";
@import "components/filter";
@import "components/footer";
@import "components/forms";
@import "components/gallery";
@import "components/header";
@import "components/headings";
@import "components/helpers";
@import "components/highslide";
@import "components/homepage";
@import "components/info-bar";
@import "components/last-sync";
@import "components/layout";
@import "components/login";
@import "components/menu";
@import "components/news";
@import "components/pagination";
@import "components/recaptcha";
@import "components/search";
@import "components/settings";
@import "components/tables";
@import "components/welcome";

* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    background-color: @white;
    color: @black;
    .font-regular;
    font-size: 1em;
    line-height: 1.4em;
    margin: 0;
    padding: 0;
    height: 100%;

    @media @md {
        font-size: .9em;
    }
}
