.main-menu {
    display: flex;

    @media @md {
        display: block;
    }

    > div {
        flex: 1 1 100%;
    }
}

#topMenuWrapper {
    background-color: @blue;
    position: relative;
    z-index: 3;
    padding-right: 20px;
    min-height: 59px;

    @media @md {
        padding-right: 0;
    }
}

#topMenu {
    font-size: 18px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;

    @media @xl {
        font-size: 16px;
        padding-left: 15px;
    }

    @media @lg {
        font-size: 14px;
        padding-left: 20px;
    }

    @media @md {
        display: block;
        padding-left: 0;
    }

    ul {
        list-style: none;
    }

    .firstmenu {
        display: flex;
    }

    li {
        position: relative;
        flex: 1 1 auto;

        &:hover {

            ul.submenu {
                display: block;
            }
        }

        a {
            border: none;
            color: @white;
            line-height: 1.4em;
            padding: 0 25px;
            .font-light;
            text-decoration: none;
            text-align: center;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;

            @media @xl {
                padding-left: 15px;
                padding-right: 15px;
            }

            @media @lg {
                padding-left: 10px;
                padding-right: 10px;
            }

            &:hover {
                color: @orange;
            }
        }

        .hit-label {
            padding: 17px 0;
        }

        a.open {
            color: @blue;
            background: @orange;

            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 2px;
                height: 100%;
                background: @white;
                .border-box;
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }
        }

        &.hasSubmenu {
            &:hover {
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 7px;
                    background: @white;
                    border-left: 2px solid @blue;
                    border-right: 2px solid @blue;
                    position: absolute;
                    z-index: 80;
                    bottom: -7px;
                    left: 0;

                    .border-box;
                }
            }
        }
    }

    ul.firstmenu > li {
        float: left;
    }

    ul.submenu {
        display: none;

        margin-top: 5px;
        padding: 20px 30px;
        width: auto;
        min-width: 350px;
        background-color: @white;
        border: 2px solid @blue;

        position: absolute;
        z-index: 70;

        li {
            a {
                padding: 15px 0;
                color: @blue;
                border-bottom: 1px solid @graySeparator;
                justify-content: flex-start;

                &:hover {
                    color: @red;
                    font-family: @f-bold;
                    background: @white;
                }

                &.open {
                    background: @white;

                    &:before, &:after {
                        display: none;
                    }
                }
            }

            &.active a {
                background: @white;

                &.open {
                    color: @red;
                }
            }

            &:last-child a {
                border: none;
            }

            &:hover {
                background: @white;

                &:before {
                    display: none;
                }
            }
        }

        .hit-label {
            padding: 0;
        }


    }

    // a[href="/e-services/platby-pojistneho/"]+.submenu {
    // 	display:none!important;
    // }

    .secondBritanikaMenu {
        float: right;

        .submenu {
            right: 0;
            min-width: 270px;

            &:before {
                left: auto;
                right: 0;
            }
        }

        &.iconMenu {

            li {

                a {
                    background-color: transparent;

                    text-align: left;
                    padding: 59px 0 0 70px;
                    .square(32px);

                    .hit-label {
                        display: none;
                    }

                    &.open {
                        &:after {
                            background: transparent;
                        }
                    }
                }

                &.item0 a, &.item1 a, &.item2 a {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 32px 32px;
                }

                &.item0 a {
                    background-image: url("@{images}/icon_white-settings.png");
                }

                &.item1 a {
                    background-image: url("@{images}/icon_white-info.png");
                }

                &.item2 a {
                    background-image: url("@{images}/icon_white-rules.png");
                }

                .submenu {

                    li {
                        margin: 0;

                        a {
                            .square(auto);
                            padding: 15px 0;
                            background-image: none;

                            .hit-label {
                                display: block;
                            }
                        }
                    }

                    [data-tooltip]:before,
                    [data-tooltip]:after {
                        display: none;
                    }
                }

                &.last {
                    margin-right: 15px;

                    [data-tooltip]:before {
                        left: auto;
                        right: 0;
                        top: auto;
                        margin-bottom: 10px;
                        margin-left: -75px;
                    }

                    [data-tooltip]:after {

                    }
                }

            }

            [data-tooltip] {
                position: relative;
                z-index: 20;
                cursor: pointer;
                .border-box;
            }

            /* Hide the tooltip content by default */

            [data-tooltip]:before,
            [data-tooltip]:after {
                visibility: hidden;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
                opacity: 0;
                pointer-events: none;
                .border-box;
                .trans;
            }

            /* Position tooltip above the element */

            [data-tooltip]:before {
                position: absolute;
                bottom: 110%;
                left: 50%;
                right: auto;
                top: auto;
                margin-bottom: 10px;
                margin-left: -75px;
                padding: 5px 15px;
                width: 150px;
                height: auto;
                background-color: @blue;
                content: attr(data-tooltip);
                text-align: center;
                font-size: 0.813em;
                height: auto;
            }

            /* Triangle hack to make tooltip look like a speech bubble */

            [data-tooltip]:after {
                position: absolute;
                bottom: 110%;
                left: 50%;
                right: auto;
                top: auto;
                margin-left: -10px;
                width: 0;
                height: auto;
                border-top: 10px solid @blue;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
                content: " ";
                font-size: 0;
                line-height: 0;
            }

            /* Show tooltip content on hover */

            [data-tooltip]:hover:before,
            [data-tooltip]:hover:after {
                visibility: visible;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
                opacity: 1;
            }

        }

        /**** MEGAMENU ***********************/

        &.iconMenu {
            .item0, .item2 {
                .submenu {
                    width: 95vw;
                    // min-width: 980px;
                    max-width: 1120px;
                    right: -140px;
                    padding-left: 30px;
                    padding-right: 30px;
                    .border-box;

                    li {
                        float: left;
                        width: 25%;
                        padding-left: 30px;
                        padding-right: 30px;

                        .border-box;

                        &.hasSubmenu {
                            a {
                                border: none;

                                .hit-label {
                                    display: inline-block !important;
                                }

                                &:after {
                                    content: '';
                                    .size(18px, 10px);
                                    display: inline-block !important;
                                    margin: 0 0 0 15px;

                                    // background: url("@{images}/icon_blue-caret-down.png") 100% 50% no-repeat !important;
                                    // background-size: 18px 10px !important;

                                    position: relative;
                                    top: auto;
                                    bottom: auto;
                                    left: auto;
                                    right: auto;
                                    visibility: visible;
                                    opacity: 1;
                                    border: none;
                                }
                            }

                            li {
                                float: none;
                                width: 100%;
                                padding-right: 0;
                                padding-left: 0;

                                background: url("@{images}/icon_red-dot.png") 0 25px no-repeat !important;
                                background-size: 5px 5px !important;

                                a {
                                    margin-left: 20px;
                                    padding: 15px 0;
                                    border-bottom: 1px solid @graySeparator;
                                    background-image: none !important;
                                    position: relative;

                                    &:after {
                                        display: none !important;
                                    }
                                }

                                &:last-child {
                                    a {
                                        border: none;
                                        padding-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .item2 {
                .submenu {
                    width: auto;
                    min-width: 270px;
                    padding-left: 0;
                    padding-right: 0;
                    right: 0;

                    li {
                        float: none;
                        width: auto;
                    }
                }
            }
        }
    }
}


@media @md {

    #languageSwitcher {
        display: none;
    }

    .navigation {
        display: none;
    }

    #topMenu {
        font-size: 1.2em;

        .firstmenu {
            display: block;
            max-width: none;
        }

        ul {
            margin: 0 20px;
        }

        li {
            a {
                color: @blue;
                border-bottom: 1px solid @graySeparator;
                display: block;
                padding: 11px 0;
                background-color: transparent;
                justify-content: flex-start;
                text-align: left;

                &:hover {
                    color: @red;
                    background-color: transparent;
                }
            }

            &:hover {
                background-color: transparent;
            }

            a.open {
                background-color: transparent;
                color: @red;

                &:before, &:after {
                    display: none;
                }
            }

            &.hasSubmenu {
                &:hover {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        ul.firstmenu > li {
            float: none;
            display: block;
            background-image: url("@{images}/mobile-menu_bullet.png");
            background-position: right 12px;
            background-repeat: no-repeat;
            background-size: 7px 22px;
        }

        ul.submenu {
            display: block;
            min-width: initial;

            margin: -10px 0 0 0;
            padding: 0 0 10px 0;
            background-color: @white;
            border: none;
            border-bottom: 1px solid @graySeparator;

            position: static;

            li {
                a {
                    padding: 7px 15px;
                    border: none;
                    font-size: .9em;

                    &:hover {
                        color: @red;
                        background: @white;
                    }

                    &.open {
                    }
                }
            }

            &:before {
                display: none;
            }
        }

        .secondBritanikaMenu {
            float: none;

            &.iconMenu {
                li {
                    a {
                        // background-color: transparent;

                        padding: 17px 0;
                        .square(auto);

                        .hit-label {
                            display: block;
                        }
                    }

                    [data-tooltip]:before,
                    [data-tooltip]:after {
                        display: none;
                    }

                    &.last {
                        margin-right: 0;
                    }

                    .submenu {
                        right: auto;

                        margin: -10px 0 0 0;
                        padding: 0 0 10px 0;

                        li {
                            margin: 0;

                            a {
                                background-color: @white;
                                padding: 7px 15px;
                                background-image: none;
                                border: none;

                                .hit-label {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            /**** MEGAMENU ***********************/

            &.iconMenu {
                .item0, .item2 {
                    .submenu {
                        width: auto;
                        min-width: initial;
                        max-width: initial;
                        right: auto;
                        padding-left: 0;
                        padding-right: 0;

                        li {
                            float: none;
                            width: auto;
                            padding-left: 0;
                            padding-right: 0;

                            .border-box;

                            &.hasSubmenu {
                                ul {
                                }

                                li {
                                    background-position: 0 17px !important;

                                    a {
                                        padding: 7px 0;
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /**** MEGAMENU END ***********************/
        }
    }

    #topMenuWrapper {
        display: none;
        padding-top: 120px;
        padding-bottom: 50px;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 200;

        background: @white;
        .box-shadow(0 0 25px rgba(0, 0, 0, .55));
    }
}

@media @sm {
    #topMenuWrapper {
        padding-top: 170px;
    }
}

.reportButton {
    white-space: nowrap;
    font-family: @f-bold;
    font-size: 1em;
    text-decoration: none;
    background: @blue;
    color: @blue;
    display: flex;
    align-items: center;
    padding: 0 30px 0 0;

    .on-line-hlaseni-skod & {
        display: none;
    }

    @media @md {
        background: none;
        padding: 0 20px;
        margin-bottom: 30px;
    }

    span {
        display: block;
        background: @orange;
        border-radius: 3px;
        padding: 10px 20px;
        transition: all 300ms;

        @media @md {
            margin: 0 auto;
            width: 100%;
            max-width: 350px;
            text-align: center;
        }
    }

    &:hover,
    &:active,
    &:focus {
        span {
            background: @orange;
            color: @blue;
        }
    }
}
