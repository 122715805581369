.britanikaCustoms-inlineHeading {
    display: inline-block;
    margin-right: 25px;
}

.britanikaCustoms-switch {
    float: left;
    width: auto;
    margin-right: 50px;

    .pipeSeparator {
        display: none;
    }
}

.clients {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 25px;

    @media @md {
        display: block;
    }

    .clients__filter {
        margin-bottom: 0;
    }
}

.clients__create {
    padding-left: 7px;

    @media @md {
        margin-top: 10px;
        padding-left: 0;
    }
}

.britanikaCustoms-alphaFilter {
    margin-top: 0;

    @media @md {
        float: left;
        margin: -40px 0 0 0;
    }

    .pipeSeparator {
        display: none;
    }

    .link {
        background: none;
        padding: initial;
        padding: 0;
        margin-right: 10px;
        display: inline;
        height: auto;
        font-size: 1.125em;
        line-height: 1.75em;

        text-decoration: none;
        background: transparent;
        border-bottom: 1px solid @linksUnderline;
        color: @links;
        outline: 0;

        .trans();

        &:hover {
            border-bottom: 1px solid @linksUnderlineHover;
            color: @linksHover;
        }

        &.active {
            color: @red;
            border: none;
        }
    }
}
