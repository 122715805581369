.highlighted {
    font-size: 1.3em;
    font-style: italic;
    margin: 20px 0;
}

.filter-stripe {
    background: #f3f5f7;
    padding: 30px 0;
    margin-bottom: 35px;
}

.filter-form {
    fieldset {
        margin-bottom: 0 !important;
    }

    .item {
        display: inline-block;
        position: relative;
        padding: 5px 35px 5px 0;
        box-sizing: border-box;

        &:first-child {
            padding-left: 0;
        }

        input, label {
            display: inline-block;
            vertical-align: middle;
        }

        .checkbox-input {
            position: absolute;
            left: 5px;
            top: 50%;
            margin-top: -15px;
            width: 30px;
            height: 30px;
            background: #fff;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25) inset;
        }

        input[type="checkbox"] {
            width: 30px;
            height: 30px;
            opacity: 0;
            position: relative;
            cursor: pointer;
            z-index: 5;

            &:checked + span {
                background: #fff url(../images/checked.png) 8px 10px no-repeat;

                & + label {
                    color: #000000;
                }
            }
        }

        label {
            padding-left: 50px;
            margin: 0 0 0 10px;
            position: relative;
            color: #a2a3a5;

            &:before {
                content: "";
                position: absolute;
                left: 0px;
                top: 50%;
                background: url(../images/news_sprite.png) no-repeat;
                width: 37px;
                height: 35px;
                margin-top: -17px;
            }

            .item-last & {
                padding-left: 10px;
                background: none;
            }
        }

        &.item-last {
            label {
                padding-left: 10px;

                &:before {
                    content: none
                }
            }
        }

        &.britanika-news {
            label {
                padding-left: 35px;

                &:before {
                    background-position: 0 -42px;
                }
            }

            input:checked + span + label:before {
                background-position: 0 0;
            }
        }

        &.hypoteka {
            label:before {

                background-position: -84px -42px;
            }

            input:checked + span + label:before {

                background-position: -83px 0;
            }
        }

        &.investice {
            label:before {
                background-position: -126px -42px;
            }

            input:checked + span + label:before {
                background-position: -126px 0;
            }
        }

        &.pojisteni {
            label:before {
                background-position: -40px -41px;
            }
        }

        input:checked + span + label:before {
            background-position: -39px 0;
        }
    }

    .filter-form_left {
        padding-right: 330px;
    }

    .filter-form_right {
        position: absolute;
        padding-left: 70px;
        border-left: 1px solid #ccc;
        top: 0;
        right: 0;
    }
}

#extensiveSearch {
    padding: 35px 0 30px 0;
    margin-top: 25px;
    border-top: 3px solid @orange;

    .clearfix();

    select {
        margin-left: 7px;
        margin-right: 40px;
        min-width: 110px;
        max-width: 150px;
    }

    .filterForm.halfBox {
        width: 80%;

        label {
            font-size: 1.4em;
            color: @blue;
            .font-light;
        }
    }

    .searchButton.halfBox {
        width: 20%;

        .link.active {
            background: @blue;

            &:hover {
                background: @red;
            }
        }
    }

}

.filterState {
    margin: 0 0 25px 0;
    float: left;
}

#listingfilter-welcome {
    .filterState {
        margin-top: 0;
    }
}

.toggleButtons {
    margin-bottom: 25px;
    position: relative;
    float: right;

    @media @xl {
        float: none;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 0;
    }

    #listingfilter-contracts & {
        top: 3px;
    }
}

.searchButton {
    text-align: right;
}

.toggleRightArrow {
    position: relative;
}

.toggleRightArrow.active {
    &:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        margin-right: -7px;
        bottom: -7px;
        left: 50%;
        z-index: 1;
        border-style: solid;
        border-width: 7px 7px 0;
        border-color: @blue transparent transparent;
    }
}

table.dataTable th > .dijitTextBox,
table.dataTable th > .dijitComboBox,
table.dataTable th > .dijitSpinner,
table.dataTable th > .dijitTextArea,
table.dataTable th > select,
table.dataTable th > input {
    // margin: 0;
    // padding: 2px 0;
    // text-indent: 2px;
    width: 100%;
    .border-box;
}


.filterSelectedItems {
    display: none;
}


@media @xl {

    .filter-form {
        .filter-form_left {
            padding-right: 275px;
        }

        .item {
            &:not(.item-last) {
                width: 49.5%;
                padding-right: 5px;
            }
        }

        .filter-form_right {
            padding-left: 30px;
        }
    }

    .filterState {
        float: none;
        width: 100%;
        margin-top: 25px;
    }
}


@media @lg {


    .toggleButtons {
        float: none;
        width: 100%;
        text-align: left;
        position: static;
    }
}

@media @md {

    .toggleButtons,
    .searchButton {
        text-align: left;
    }

    a#toggleFilters {
        display: none;
        // schovane rozsirene vyhladavanie
    }

    #listingfilter-welcome {
        .filterState {
            margin-top: 0;
        }
    }

    /* Filtered Search info*/
    #extensiveSearch {
        display: none !important;
    }

    .filterRow {
        display: none !important;
    }

    .filterSelectedItems {
        padding: 15px;
        margin-top: 15px;
        border: 1px solid lighten(@orange, 65%);
        background-color: lighten(@orange, 70%);

        display: block;
        .clearfix();

        h4 {
            margin-bottom: 5px;
        }
    }

    .filterSelectedItems_resetBtn {
        display: table;
        margin-top: 15px;
        margin-left: 20px;
        position: relative;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 3px;
            left: -20px;
            .square(14px);
            background: url("@{images}/icon_red-close-cross.png") 0 0 no-repeat;
            background-size: 14px 14px;
        }
    }


    #filterSettingsContent {
        width: auto;
        max-width: initial;
        padding: 15px 20px;
    }

    #exportData {
        width: auto;
        max-width: initial;
        padding: 15px 20px;
    }

    .filter-form {
        .filter-form_right {
            position: static;
            border-left: none;
            padding-left: 0
        }

        .filter-form_left {
            padding-right: 0;
        }
    }
}

@media @sm {
    .filter-form {
        .item:not(.item-last) {
            width: 100%;
            padding-right: 0
        }
    }
}

.company-filter {
    display: flex;
    margin-bottom: 25px;

    select {
        visibility: hidden;
    }

    @media @md {
        display: block;
    }

    label {
        margin: 9px 7px 9px 0;
        display: inline-block;
        color: @blue;
        font-size: 1.4em;
        font-family: @f-light;
        font-weight: @fw-light !important;
    }

    .dijitComboBox {
        width: 410px;

        @media @md {
            width: 100%;
        }
    }
}

.myClientsBox {
    margin-bottom: 15px;

    @media @md {
        margin-top: 20px;
    }

    label {
        color: @blue;
        font-size: 1.4em;
        font-family: @f-light;
        font-weight: @fw-light !important;
    }
}
