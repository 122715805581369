.dijitValidationContainer {
    input {
        .box-shadow(none);
    }
}

.dijitArrowButtonContainer {
    input {
        .box-shadow(none);
    }
}

.dijitInputContainer {
    input {
    }
}

.dijitTextBoxFocused {
    outline: none;
}

.tundra .dijitTextBox,
.tundra .dijitComboBox,
.tundra .dijitSpinner,
.tundra .dijitTextArea,
.tundra .dijitInlineEditor {
    position: relative;

    input {
        font-size: 1em;
        color: @blue;
        vertical-align: middle;
        #font > #family > .open-sans();
        padding: 6px 35px 6px 15px;
        margin: 0;
        outline: none;
    }
}

.dijitSpinner,
.dijitTextArea[cols] {
    min-width: 40%;
    .box-shadow(1px 1px 3px rgba(0, 0, 0, .1) inset);
}

.dijitTextArea[cols] {
    min-height: 100px;
}

.tundra {

    .dijitTextBox,
    .dijitComboBox,
    .dijitRadio,
    .dijitCheckBox,
    textarea {
        background: @white;
        margin: 0;
        border: 1px solid @inputBorder;
        border-radius: 3px;
        .box-shadow(1px 1px 3px rgba(0, 0, 0, .1) inset);
        max-width: 700px;
        width: 100%;
    }

    .dijitCheckBox,
    .dijitRadio {
        width: 24px;
        height: 24px;
        position: relative;
        top: -2px;

        &.dijitCheckBoxChecked {
            background: @white url("@{images}/icon_red-checked.png") center center no-repeat;
        }
        &.dijitRadioChecked {
            &::before {
                content: '';
                position: absolute;
                top: 3px;
                left: 3px;
                width: 16px;
                height: 16px;
                background: @blue;
                border-radius: 50%;
            }
        }
    }

    .dijitCheckBox,
    .dijitRadio {
        .dijitCheckBoxInput {
            .square(24px);
            cursor: pointer;
        }
    }

    .dijitRadio {
        border-radius: 50%;
    }

    .dijitInputContainer {
        margin: 0;

        input {
            margin: 0;
        }

        .dijitInputInner {
            padding-left: 11px !important;
            font-size: 1.1em;
            line-height: normal;
            .font-light;
            outline: none;
            .box-shadow(none);
        }
    }

    .dijitButtonNode {
        border: none;
        background: none;
        float: none;
        width: 35px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        .trans();

        &:hover {
            top: 3px;
        }

        input {
            background: url("@{images}/icon_red-caret-down.png") 50% 50% no-repeat;
            background-size: 17px 11px;

            width: 35px;
            height: 40px;

            position: absolute;
            top: 3px;
            left: 0;
        }

        .dijitArrowButtonInner {
            background-position: center center;
        }
    }

    .dijitMenu {
        border-color: @orange;
        background: @white;
        color: @blue;

        .dijitMenuItem {
            padding-left: 20px;
        }

        .dijitMenuItemSelected {
            background: @red;
            color: @white;
        }
    }

    .dijitPlaceHolder {
        color: @blue;
        font-style: normal;
        .font-regular;
        font-size: 16px;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-left: 10px;
    }

}

table.dataTable {
    thead {
        .filterRow {

            .dijitTextBox,
            .dijitComboBox,
            .dijitSpinner,
            .dijitTextArea,
            .dijitInlineEditor {
                position: relative;
                vertical-align: initial;
                margin-top: -1px;

                input {
                    font-size: 1em;
                    color: @blue;
                    vertical-align: middle !important;
                    padding: 6px 15px 5px 7px;
                    margin: 0;
                }
            }

            .dijitInputContainer {
                margin: 0 2px 0 0;

                .dijitInputInner {
                    padding-left: 8px !important;
                    padding-right: 3px !important;
                    font-size: .9em;
                    .font-light;

                    .box-shadow(none);
                }
            }

            .dijitButtonNode {

                width: 18px;
                height: 18px;

                &:hover {
                    top: 2px;
                }

                input {
                    background: url("@{images}/icon_red-caret-down-indent_small.png") 100% 50% no-repeat;
                    background-size: 20px auto;

                    width: 22px;
                    height: 20px;

                    position: absolute;
                    top: 2px;
                    left: 0;
                }

                &.dijitDownArrowButton {
                    background-color: @white !important;
                    margin-top: 3px;
                    margin-right: 2px;
                    padding: 0;

                    .dijitArrowButtonInner {
                        background-position: right center;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
}

table.dojoDndAvatar {
    input[type=checkbox] {
        display: none;
    }
}

@media @lg {
    .dijitComboBox {
        //min-width: 70%;
    }
}

@media @md {
    .dijitTextBox,
    .dijitComboBox,
    .dijitSpinner,
    .dijitTextArea[cols] {
        width: 100%;
        box-sizing: border-box;
    }
}

.dijitTextBoxError .dijitValidationContainer {
    margin: 0 35px 0 0;
}

.tundra .dijitTextArea,
textarea {
    font-size: 1.1em;
    line-height: normal;
    .font-light;
    color: @blue;
    padding: 7px 13px;
    min-height: 120px;
}
