// // Original Renomia styles - reset
// // ------------------------------------------------------------------------


html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, menu, time, mark, audio, video  {
 background:transparent;
 border:0;
 font-size:100%;
 margin:0;
 outline:0;
 padding:0;
 vertical-align:baseline;
}
article, aside, figure, footer, header, hgroup, nav, section  {
 display:block;
}
nav {
 ul {
   list-style:none;
 }
}
blockquote {
 quotes:none;
}
q {
 quotes:none;
}
blockquote:before, blockquote:after, q:before, q:after  {
 content:none;
}

ins {
 background-color:#ffff99;
 color:#000000;
 text-decoration:none;
}
mark {
 background-color:#ffff99;
 color:#000000;
 font-style:italic;
 font-weight:bold;
}
del {
 text-decoration: line-through;
}
abbr[title] {
 border-bottom:1px dotted #000000;
 cursor:help;
}
dfn[title] {
 border-bottom:1px dotted #000000;
 cursor:help;
}
table {
 border-collapse: collapse;
 border-spacing:0;
 border: 0;
 font-size: 1em;
 margin-bottom: 15px;
 table-layout: fixed;
}
hr {
 border-top:1px solid #cccccc;
 border:0;
 display:block;
 height:1px;
 margin:1em 0;
 padding:0;
}

input, 
select, 
button {
 border: none;
 vertical-align: middle;
 
 -webkit-appearance: none;
 border-radius: 0;
}

label, input[type=button], input[type=submit], button  {
 cursor: pointer;
}
input[type="radio"] {
 vertical-align: text-bottom;
}
input[type="checkbox"] {
 vertical-align: bottom;
}
.ie6 {
  input {
    vertical-align: text-bottom;
  }
}
.ie7 {
  img {
    -ms-interpolation-mode: bicubic;
  }
}
/* wysiwyg reset */
font {
  color: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
}