.table {
    display: table;

    > * {
        display: table-cell;
        padding: 5px 5px 5px 0;

        &:last-child {
            padding-right: 0;
        }
    }
}

th {
    border: 0;
    padding: 5px 10px;
    text-align: left;
    vertical-align: top;
}

td {
    border: 0;
    padding: 5px 10px;
    text-align: left;
    vertical-align: top;
}

.dataTable {
    border-collapse: collapse;
    border-spacing: 0;
    color: @black;
    font-size: 14px;
    margin-bottom: 15px;
    width: 100%;
    .font-regular;

    th {
        border-bottom: 3px solid @orange;
        padding: 15px 10px 15px 0;
        text-align: left;
        .font-regular;
        font-size: 13px;
        line-height: 1.3em;
        vertical-align: bottom;
    }

    td {
        border-bottom: 1px solid @graySeparator;
        padding: 15px 10px 15px 0;

        &.imageInside {
            padding-left: 45px !important;
            background-position: 5px 8px;
        }

        &.money {
            text-align: right;
            padding-right: 30px;
        }
    }

    td[data-title="Popis škody"] {
        line-height: normal;
        opacity: .7;
        font-style: italic;

    }

    tr {
        &:first-child td {
            padding-top: 20px;

            &.imageInside {
                background-position: 5px 23px;
            }
        }

        &:last-child td {
            padding-bottom: 20px;
            border-bottom: 3px solid @orange;

            &.imageInside {
                background-position: 5px 8px;
            }
        }

        &:hover {
            td {
                background: @grayHover;
            }
        }
    }

    thead {
        tr.labels {
            word-wrap: break-word;

            a {
                color: gray;
                //background: url("@{images}/icon_blue-circle.png") 100% 50% no-repeat;
                background-size: 9px 9px;
                text-decoration: none;
                padding: 0;
                border: none;

                &.otaznik {
                    .square(18px);
                    padding-right: 0;
                    display: inline-block;
                    background: url("@{images}/icon_circle-red-question.png") 0 0 no-repeat;


                    img {
                        display: none;
                    }
                }
            }
        }

        .filterRow {
            input, select {
                font-size: 0.875em;
                padding: 6px 15px 6px 7px;
                margin: 3px 2px 2px 2px;
            }

            select {
                background: @white url("@{images}/icon_red-caret-down-indent_small.png") 100% 50% no-repeat;
                background-size: 20px auto;
            }

            th {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            &.operators {
                th {
                    // text-align: center;
                    border-bottom: 1px solid @graySeparator;
                }

                td {
                    text-align: center;
                }

                select {
                    max-width: 60px;
                }
            }

            &.values {
                th {
                    // text-align: center;
                }

                select[name=operator_commission_paid_date_till] {
                    max-width: 60px;
                }

                select[name=operator_maturity_date_till] {
                    max-width: 60px;
                }
            }
        }
    }

    tbody {
        tr.red {
            background-color: @redBackground;

            &:hover td {
                background-color: @redBackgroundHover;
            }
        }

        td {
            word-wrap: break-word;
        }
    }
}

/**/

table.contactTable {
    border-collapse: collapse;
    border-left: 1px solid @blue;
    border-spacing: 0;
    border-top: 1px solid @blue;
    width: 100%;

    th {
        border-bottom: 1px solid @blue;
        border-right: 1px solid @blue;
        word-wrap: break-word;
    }

    td {
        border-bottom: 1px solid @blue;
        border-right: 1px solid @blue;
        word-wrap: break-word;
    }

    thead {
        th {
            background-color: #dbe7ef;
        }

        td {
            background-color: #dbe7ef;
        }
    }

    .summary {
        width: 60%;
    }

    .labels {
        width: 100px;
    }
}

.sortArrow {
    margin: 0 0 3px 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
}

/**/

table.fancyTable {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 15px 0;
    border-top: 3px solid @red;
    border-bottom: 3px solid @red;
    width: 100%;
    max-width: 800px;

    th {
        text-align: left;
        padding: 15px;
        border-bottom: 1px solid @graySeparator;
        .font-bold;
        // width: 10%;
    }

    td {
        // text-align: right;
        padding: 15px;
        border-bottom: 1px solid @graySeparator;

        &.imageInside {
            padding-left: 45px !important;
            background-position: 5px 8px;
        }

        &.money {
            text-align: right;
            padding-right: 30px;
        }
    }

    td, th {
        vertical-align: middle;
    }

    tr {
        &:last-child td {
            border-bottom: none;
        }
    }

    .money {
        text-align: right;
    }

    .contract-yes-no {
        text-align: center;

        a {
            border: none;
            display: inline-block;
            vertical-align: middle;

            img {
                display: block;
            }
        }
    }
}

.responsiveTable {
    &.filter {
        input {
            padding: 12px 10px 12px 15px;
        }

        input[type="submit"],
        input[type="button"] {
            padding: 10px 30px;
        }
    }
}

table.britanikaCustoms-clientDetailTable {
    td {
        padding: 5px 35px 5px 0;
    }

    .fancyTable {
        td {
            padding: 15px;
        }
    }
}


/* tables */
@media only screen and (max-width: 1440px) {


    table.cols20,
    table.cols19,
    table.cols18,
    table.cols17 {
        font-size: .9em;

        .filterRow.values {
            width: 100px;
        }

        thead {
            float: left;

            th, td {
                border-bottom: 1px solid @graySeparator;
                position: relative;
            }

            th:after {
                .theadRedBorder;
            }
        }

        tbody {
            display: block;
            width: auto;
            position: relative;
            overflow-x: auto;
            white-space: nowrap;

            .theadRedBorderPadding;
        }

        tr {
            display: inline-block;
            vertical-align: top;
        }

        th, td {
            display: block;
            line-height: 25px;
            min-height: 25px;
        }

        br {
            display: none;
        }

        span.tabletOnly {
            display: inline;
        }

        &.dataTable, &.dispatch-client-contracts {
            thead {
                tr.filterRow.operators {
                    margin-left: 20px;

                    th {
                        padding: 15px 0;
                        line-height: 25px;
                        min-height: 25px;
                        display: block;
                    }
                }

                tr.filterRow.values {
                    margin-left: 0;

                    th {
                        padding: 15px 0;
                        line-height: 25px;
                        min-height: 25px;
                        display: block;
                    }
                }

                th {
                    border-left: none;

                    select, input, .dijitComboBox {
                        margin: -5px 0;
                    }
                }

                th, td {
                    word-wrap: normal; /*white-space: nowrap;*/
                }

                .filterRow.operators {
                    select + select {
                        display: inline;
                        margin: 0 10px 0 4px;
                    }
                }
            }

            th > .dijitDateTextBox {
                max-width: 50px;
            }

            tr:first-child td {
                padding-top: 15px;
            }

            tr:last-child td {
                padding-bottom: 15px;
                border-bottom: 1px solid @graySeparator;
            }

            .filterRow.values {
                th > .dijitDateTextBox {
                    max-width: 100%;
                }

                th > select + .dijitDateTextBox {
                    max-width: 40px;
                }
            }

            br {
                display: none;
            }
        }

    }
}


@media @xxl {


    .cols16,
    .cols15,
    .cols14,
    .cols13,
    .cols12,
    .cols11,
    .cols10 {

        font-size: .9em;

        .filterRow.values {
            width: 100px;
        }

        thead {
            float: left;

            th, td {
                border-bottom: 1px solid @graySeparator;
                position: relative;
            }

            th:after {
                .theadRedBorder;
            }
        }

        tbody {
            display: block;
            width: auto;
            position: relative;
            overflow-x: auto;
            white-space: nowrap;

            .theadRedBorderPadding;
        }

        tr {
            display: inline-block;
            vertical-align: top;
        }

        th, td {
            display: block;
            line-height: 25px;
            min-height: 25px;
        }

        span.tabletOnly {
            display: inline;
        }

        br {
            display: none;
        }

        &.dataTable, &.dispatch-client-contracts {
            thead {
                tr.filterRow.operators {
                    margin-left: 20px;

                    th {
                        padding: 15px 0;
                        line-height: 25px;
                        min-height: 25px;
                        display: block;
                    }
                }

                tr.filterRow.values {
                    margin-left: 0;

                    th {
                        padding: 15px 0;
                        line-height: 25px;
                        min-height: 25px;
                        display: block;
                    }
                }

                th {
                    border-left: none;

                    select, input, .dijitComboBox {
                        margin: -5px 0;
                    }
                }
            }

            tr:first-child td {
                padding-top: 15px;
            }

            tr:last-child td {
                padding-bottom: 15px;
                border-bottom: 1px solid @graySeparator;
            }
        }
    }

    .dataTable, .dispatch-client-contracts {
        thead {
            th, td {
                word-wrap: normal; /*white-space: nowrap;*/
            }

            .filterRow.operators {
                select + select {
                    display: inline;
                    margin: 0 10px 0 4px;
                }

                th:after {
                    display: none;
                }
            }
        }

        th > .dijitDateTextBox {
            max-width: 50px;
        }

        .inline-buttons {
            width: auto;
        }

        .filterRow.values {
            th > .dijitDateTextBox {
                max-width: 100%;
            }

            th > select + .dijitDateTextBox {
                max-width: 40px;
            }
        }

        br {
            display: none;
        }
    }
}

@media @lg {


    /**/
    .dataTable, .dispatch-client-contracts {
        font-size: .9em;
        margin-top: 25px;

        thead {
            float: left;
            white-space: nowrap;

            tr.filterRow.operators {
                margin-left: 20px;

                th {
                    padding: 15px 0;
                    line-height: 25px;
                    min-height: 25px;
                    display: block;
                }

                select, input, .dijitComboBox {
                    margin: -5px 0;
                }
            }

            tr.filterRow.values {
                margin-left: 0;

                th {
                    padding: 15px 0;
                    line-height: 25px;
                    min-height: 25px;
                    display: block;
                }

                select, input {
                    margin: -5px 0;
                }

                .dijitComboBox {
                    margin: -5px 0;
                    height: 30px;
                }
            }

            th, td {
                border-bottom: 1px solid @graySeparator;
                position: relative;
            }

            th:after {
                .theadRedBorder;
            }

            tr.labels {
                word-wrap: normal;
            }
        }

        tbody {
            display: block;
            width: auto;
            position: relative;
            overflow-x: scroll;
            white-space: nowrap;

            .theadRedBorderPadding;
        }

        tr {
            display: inline-block;
            vertical-align: top;
        }

        tr:first-child td {
            padding-top: 15px;

            &.imageInside {
                background-position: 0px 8px;
            }
        }

        tr:last-child td {
            padding-bottom: 15px;
            border-bottom: 1px solid @graySeparator;
        }

        th, td {
            display: block;
            width: auto;
            line-height: 25px;
            min-height: 25px;
        }

        td {
            &.imageInside {
                padding-left: 40px !important;
                background-position: 0px 8px;
            }
        }

        .filterRow.values {
            width: 100px;
        }

        &.noTabletAdjustment {
            // snad to nebudeme potrebovat :]

            // thead {
            // 	float: none;
            // 	white-space: normal;
            // 	tr + tr {
            // 		margin-left: 0;
            // 		border-left: none;
            // 	}
            // 	th, td {
            // 		border-left: 1px solid #003876;
            // 	}
            // }

            // tbody {
            // 	display: table-row-group;
            // 	width: inherit;
            // 	overflow-x: auto;
            // 	white-space: normal;
            // }

            // tr { display: table-row; vertical-align: inherit; }

            // th, td {
            // 	display: table-cell;
            // 	line-height: normal;
            // 	min-height: inherit;
            // }
            // .filterRow.values {
            // 	width: auto;
            // }

        }
    }
}


@media @md {


    table.dataTable, table.dispatch-client-contracts {
        display: block;
        border: none;
        float: none;
        overflow: auto;

        th > .dijitDateTextBox {
            min-width: 50px;
        }

        thead, tbody, tr, th, td {
            display: block;
            border: none;
            float: none;
            overflow: auto;
        }

        tr {
            border-top: 3px solid @red;
            margin: 0;

            &:hover td {
                background: none;
            }
        }

        td {
            padding: 10px;
            position: relative;
            padding-left: 60%;
            text-align: right;
            white-space: normal;
            border-bottom: 1px solid @graySeparator;
            min-height: 41px;
            line-height: 1.4em;

            &:before {
                padding: 10px;
                content: attr(data-title);
                position: absolute;
                left: 0;
                top: 0;
                font-weight: bold;
            }

            &.imageInside {
                position: relative;
                padding-left: 60%;
                text-align: right;
                white-space: normal;
                background-position: -100%;
                background-image: none !important;
            }

            &.inline-buttons {
                padding-left: 0;
            }
        }

        td[data-title="Popis škody"],
        td[data-title="Pojistitel"] {
            font-size: 1em;
            line-height: normal;
        }

        br {
            display: block;
        }

        tbody {
            padding-left: 0;
        }

        thead {
            font-size: .8em;
            margin-bottom: 5px;
            position: relative;

            tr, tr + tr {
                border: none;
            }

            tr {
                /*	position: absolute; top: -9999px; left: -9999px;*/
                display: inline-block;
            }

            th, td {
                line-height: 30px;
                min-height: 30px;
                border-bottom: 1px solid @graySeparator;
            }

            .labels {
                display: none;
            }

            .filterRow.operators {
                /*width: 69.9%;*/
                display: block;
                padding-right: 150px;

                th {
                    position: relative;
                    /*padding-left: 70%;*/
                    text-align: right;

                    &:before {
                        content: attr(data-title);
                        position: absolute;
                        left: 4px;
                    }
                }

                select + select {
                    margin: 0 0 0 1px;
                }
            }

            .filterRow.values {
                /*width: 29.9%;*/
                width: 150px;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        /**/

        &.noTabletAdjustment {
            // snad to nebudeme potrebovat :]

            // display: block;
            // border: none;
            // float: none;
            // overflow: auto;

            // thead, tbody, tr, th, td {
            // 	display: block;
            // 	border: none;
            // 	float: none;
            // 	overflow: auto;
            // }

            // td {
            // 	line-height: 25px;
            // 	min-height: 25px;
            // }
        }

    }


    table.responsiveTable,
    .documentText table {

        display: block;

        thead, tbody {
            display: inline-block;
            max-width: 49.9%;
        }

        th, td {
            display: block;
            min-height: 2.5em;
            line-height: 2em;
            white-space: nowrap;
        }
    }

    table.simpleResponsive {
        display: block;

        thead, tbody, tr, th, td {
            display: block;
        }
    }

    table.contactTable {
        display: block;

        thead, tbody, tr, th, td, .summary, .labels {
            display: block;
            width: auto;
        }
    }

    table.fancyTable {
        td.britanikaCustoms-insurerWebsite {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    table.britanikaCustoms-clientDetailTable {
        border-collapse: collapse;
        border-spacing: none;
        width: 100%;

        td {
            float: left;
            width: 100%;
            padding: 5px 0;
        }

        .fancyTable {
            td {
                float: none;
                width: auto;
                padding: 15px;
            }
        }
    }

    .dataTable, .dispatch-client-contracts {
        margin-bottom: 30px;
    }
}


@media @sm {
    table.fancyTable {
        .contract-yes-no {
            img {
                width: 19px;
            }
        }
    }
}

.filterRow {
    background: @footerBg;
}

