.error {
    color: @error;
    .font-bold;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;

    .error {
        display: block;
        margin-bottom: 5px;
    }
}

textarea {
    border: 1px solid @blue;
    font-family: Tahoma, sans-serif;
    font-size: 1em;
    padding: 2px;
    vertical-align: middle;
}

#main {

    fieldset {
        margin-bottom: 15px;
    }
}

.fCell {
    margin-bottom: 10px;
}

label {
    display: block;
    margin-bottom: 5px;
    outline: none !important;
    .font-bold;

    span {
        display: block;
        float: left;
        width: 100px;
    }
}

label.inlineLabel {
    display: inline;
    white-space: nowrap;
    vertical-align: middle;

    span {
        display: inline;
        float: none;
        width: auto;
    }
}

p.buttons {
    padding-left: 100px;
}

.searchForm {
    .field {
        width: 300px;
    }

    label {
        margin-right: 15px;
    }
}

input {
    .font-light;
    font-size: 1em;
    color: @blue;
    vertical-align: middle;
    padding: 10px 10px 10px 15px;
    border: 1px solid @inputBorder;
    border-radius: 3px;

    .box-shadow(1px 1px 3px rgba(0, 0, 0, .1) inset);
}

input[type=text]::-ms-clear {
    display: none;
    width : 0;
    height: 0;
}

.inputCheckbox.inputCheckbox {
    .square(30px);
    background: @white;
    vertical-align: middle;
    cursor: pointer;
    margin-top: -1px;
    outline: none;
    border: 1px solid @inputBorder;
    border-radius: 3px;
    .box-shadow(1px 1px 3px rgba(0, 0, 0, .1) inset);

    &:checked {
        background: @white url("@{images}/icon_red-checked.png") center center no-repeat;
    }
}

select {
    .font-light;
    font-size: 1em;
    color: @blue;
    vertical-align: middle;
    padding: 6px 35px 6px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background: @white url("@{images}/icon_red-caret-down_indent.png") 110% 50% no-repeat;
    background-size: 35px 11px;
    border: 1px solid @inputBorder;
    border-radius: 3px;
    width: 100%;

    .box-shadow(1px 1px 3px rgba(0, 0, 0, .1) inset);
    .trans;

    &:hover {
        background-position: 110% 58%;
    }
}

select::-ms-expand {
    display: none;
}

.checkBoxLabel {
    font-size: 1.125em;
    color: @black;
    vertical-align: middle;
    display: block;
    padding-top: 5px;
    .font-light;
}

.formLabelBig {
    color: @blue;
    font-size: 1.625em;
    margin-bottom: 20px;
    .font-light;

    display: table;

    .required {
        color: @red;
        float: right;
        margin-left: 7px;
    }
}

#RCReportClaim_form,
#RCCreateClient_form,
#RCDispatchNote_form {

    label[for="form_element_form_foreign"] {
        .checkBoxLabel;
    }

    .fCell {
        margin-bottom: 35px;

        &.rcDoc {
            border-left: 3px solid @blue;
            padding: 0 33px 0 30px;

            .closeButton {
                font-size: 0;
                line-height: 0;

                .square(23px);
                display: inline-block;
                padding: 0;
                margin-left: 25px;
                outline: none;

                background: url("@{images}/icon_close-cross.png") no-repeat;
                background-size: 46px 23px;
                background-position: -23px 0;
                transition: none;

                &:hover {
                    background-position: 0 0;
                }
            }
        }
    }

    .britanikaCustoms-userName,
    .britanikaCustoms-phone,
    .britanikaCustoms-mobile,
    .britanikaCustoms-mail {
        margin-bottom: 8px;

        span {
            .font-bold;
        }
    }

    .britanikaCustoms-heading {
        color: @blue;
        font-size: 1.625em;
        margin: 50px 0 25px 0;

        strong {
            .font-light;
        }
    }

    .britanikaCustoms-mail {
        margin-bottom: 40px;
    }

    .britanikaCustoms-addDoc {

        .button {
            margin-right: 20px;
            margin-bottom: 10px;
        }

    }

    .britanikaCustoms-buttons {
         .button {
            margin-right: 20px;
            margin-bottom: 10px;
        }

        a {
            color: @red;

            &:hover {
                color: @linksHover;
            }
        }
    }
}

#RCLostUserPassword_form,
#RCChangeUserPassword_form {
    label {
        .formLabelBig;
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    .link {
        .resetLink;
        .defaultLink;
    }
}

#RCLostUserPassword_form {
    label {
        font-size: 1.250em;
    }
}

#recaptcha_widget_div {
    margin-bottom: 25px;
}

#RCChangeUserPassword_form {
    margin: 35px 0 0 0;

    label {
        // margin-top: 20px;
    }

    .dijitTextBox {
        margin: 5px 0 20px 0;
    }
}

#RCCheckPIN_form {
    margin-top: 50px;

    label[for="personal_identification_number"] {
        .formLabelBig;
    }

    label[for="form_element_form_foreign"] {
        .checkBoxLabel;

    }

    .dijit.checkbox {
        float: left;
        margin-right: 15px;
        margin-top: 3px;
    }
}

.createClient_form, .dispatchNote_form {
    select {
        width: 240px;
        min-width: 40%;
        padding: 13px 18px;
        font-size: 1.25em;
    }
}

@media @md {

    #RCCreateClient_form {
        .row {
            .fCell {
                display: block;
            }
        }
    }
}

.fake-label {
    margin-bottom: 15px;
    .font-bold;
}

.radioCell,
.checkboxCell {
    display: flex;
    margin-bottom: 10px;

    div.dijitRadio,
    div.dijitCheckBox {
        margin: 0 10px 0 0;
        flex: 0 0 24px;
    }
}

.report-claim-form {
    max-width: 550px;
    margin: 0 auto;
}
