#welcomeText {
    border: 1px solid #003777;
    display: none;
    margin-bottom: 22px;
    padding: 26px 28px 14px 28px;
    position: relative;
}

#welcomeCross {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    vertical-align: top;

    a {
        .font-regular;
        text-decoration: underline;
    }

    img {
        margin-left: 10px;
        margin-top: 2px;
        vertical-align: top;
    }
}
