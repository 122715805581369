#footer {
    background: @gray;
    color: @blue;
    font-size: 0.875em;
    padding: 30px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 0;

    @media @md {
        margin-top: 0;
    }

    @media @sm {
        text-align: center;
        display: block;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

.footer-copy {
    @media @sm {
        float: none;
        margin: 0 0 20px;
    }
}

.footer-social {
    ul {
        display: flex;

        @media @sm {
            justify-content: center;
        }

        li {
            a {
                .square(38px);
                display: inline-block;
                position: relative;
                border-radius: 50%;
                border: 2px solid @links;
                margin-left: 15px;

                @media @sm {
                    margin: 0 7px;
                }

                span {
                    display: none;
                }

                &:hover {
                    background: #fff;
                }

                &:after {
                    content: "";
                    .square(20px);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -10px;
                    margin-left: -10px;
                    background-image: url("@{images}/sprite_social-icons.png");
                    background-size: 75px 47px;
                    background-repeat: no-repeat;

                    transition: none;
                }
            }

            &.yt a {

                &:after {
                    background-position: 2px 0;
                }
            }

            &.li a {
                &:after {
                    background-position: -21px -1px;
                }
            }

            &.fb a {
                &:after {
                    background-position: -14px -25px;
                }
            }

            &.tw a {
                &:after {
                    background-position: -48px -1px;
                }
            }
        }
    }
}
