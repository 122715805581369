#content {
    .fileStorage {
        .fileList {
            margin-left: 0;

            li {
                padding: 2px 0 6px 43px !important;
                line-height: 2em;
                background-position: 0 0;
                color: #bcbcbc;

                @media @md {
                    padding: 6px 0 6px 43px !important;
                }
            }
        }
    }
}
