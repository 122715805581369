#logo {
    margin-left: 30px;
    border: none;
    line-height: 0;

    @media @md {
        position: relative;
        z-index: 500;
        margin-left: 20px;
    }

    img {
        max-height: 50px;
    }
}

#top {
    margin-top: 35px;
    margin-bottom: 35px;

    @media @sm {
        margin-bottom: 95px;
    }

    .claim {
        background: url("@{images}/claim.png") 0 50% no-repeat;
        min-height: 20px;
        min-width: 202px;
        display: none;

        @media @md {
            margin: 30px 0;
        }

        @media @xl {
            display: none;
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loginBox {
    margin: 0 auto;
    min-width: 400px;
}

.user-box {
    margin-right: 30px;
    margin-left: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media @md {
        display: block;
        text-align: center;
    }
}

.user-info {
    color: @blue;
    line-height: normal;
    padding: 0 20px 0 60px;
    background: url("@{images}/icon_user-avatar.png") 0 50% no-repeat;
    background-size: 46px 46px;
    min-height: 60px;
    display: flex;
    align-items: center;
    font-size: 14px;
    .font-light;

    @media @md {
        text-align: center;
        padding: 60px 0 0 0;
        margin: 0 0 20px 0;
        font-size: 16px;
        background-position: top center;
        display: block;
        min-height: auto;
    }

    p {
        margin: 0;
        padding: 0;
    }

    span {
        display: block;
    }
}

.logged-user-fullName {
    font-size: 20px;
    line-height: 1em;
    white-space: nowrap;

    @media @lg {
        white-space: normal;
    }
}

.logged-user-company,
.logged-user-brokerName {
    font-size: 24px;
    margin: 0;
    padding: 0;
    .font-light;
}

.user-buttons {
    flex: 0 0 255px;
    text-align: right;

    @media @md {
        text-align: center;
    }

    .button {
        vertical-align: middle;
    }

    &.loginForm {
        margin-top: 12px;
    }

    .logout-btn {
        padding-left: 34px;
        background-image: url("@{images}/icon_blue-lock_small.png");
        background-position: 15px 50%;
        background-repeat: no-repeat;
        background-size: 14px 14px;
    }
}

.mobileMenuButton,
.mobileUserButton {
    position: absolute;
    top: 45px;
    z-index: 210;
    color: @white;
    font-weight: @fw-regular;
    padding: 10px 20px 10px 45px;
    display: inline-block;
    text-decoration: none;
    border: none;
    background-color: @blue;
    border-radius: 3px;

    @media @sm {
        top: 120px;
    }

    &:before {
        content: '';
        display: block;
        .size(16px, 21px);
        .trans;
        position: absolute;
    }

    &:hover {
        color: @orange;
        border: none;
    }

    &:focus,
    &:active {
        color: white;
    }

    &.active {
        background-color: @orange;
        color: white;

        &:before {
            background-position: 0 -42px;
        }
    }
}

.mobileMenuButton {
    right: 20px;

    &:before {
        left: 20px;
        top: 10px;
        background: url("@{images}/sprite_menu-close-icon.png") 0 0 no-repeat;
    }
}

.mobileUserButton {
    right: 140px;

    &:before {
        left: 20px;
        top: 9px;
        background: url("@{images}/sprite_user-close-icon.png") 0 0 no-repeat;
    }

    &.active {
        right: 20px;
        z-index: 220;

        &:before {
            background-position: 0 -41px;
        }
    }
}


