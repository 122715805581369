.pagination {
    margin-top: 45px;
    float: left;
    font-size: 1.125em;
    height: 22px;


    @media @lg {
        float: none;
        text-align: left;
        margin-top: 30px;
    }

    a {
        float: left;
        margin-bottom: 10px;

        @media @lg {
            float: none;
        }
    }

    &.linkGroup {
        a {
            padding: 0;
            margin: 0 12px;
            .font-bold;

            &:first-child {
                margin-left: 0;
            }
        }

        a.active {
            color: @red;
        }
    }
}

.itemsPerPageWrapper {
    font-size: 1.225em;
    margin-top: 45px;
    margin-bottom: 15px;
    color: @blue;
    text-align: right;
    .font-light;
    float: right;
    position: relative;

    @media @lg {
        float: none;
        text-align: left;
        margin-top: 0;
    }

    select {
        font-size: 0.769em;
        width: 90px;
    }
}


