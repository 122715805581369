#page {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
    }
}

#main {
    margin-bottom: 15px;
    position: relative;
    flex: 1 1 100%;
    display: flex;

    @media @lg {
        display: block;
    }
}


#mainCol {
    flex: 1 1 auto;
}

#leftCol {
    flex: 0 1 190px;
    margin-top: 20px;

    @media @lg {
        padding: 0 30px 30px;
        border: 0;
        margin-top: 0;
    }

    @media @md {
        padding: 0 20px 20px;
    }

    .leftCol__title {
        font-size: 1em;
    }

    .firstmenu {
        list-style: none;
        border-top: 1px solid @gray;
        margin: 15px 30px 20px 30px;
        min-width: 190px;

        a {
            color: @blue;
            display: block;
            padding: 13px 0 15px 15px;
            border-bottom: 1px solid @gray;
            line-height: 1.3em;
            text-decoration: none;
            font-size: 15px;
            position: relative;

            &::before {
                content: '';
                width: 5px;
                height: 5px;
                border-top: 1px solid @blue;
                border-right: 1px solid @blue;
                transform: rotate(45deg);
                position: absolute;
                left: 0;
                top: 20px;
            }

            &.open {
                .font-bold;
            }

            &:hover {
                color: @linksHover;
            }
        }

        ul {
            list-style: none;

            a {
                padding-left: 27px;
                font-size: 14px;

                &::before {
                    position: absolute;
                    left: 12px;
                    top: 19px
                }
            }

            ul a {
                padding-left: 40px;

                &::before {
                    position: absolute;
                    left: 25px;
                }
            }
        }

        li {

            &:last-child {
                border: 0;
            }
        }
    }
}

.fullWidth {
    width: 100%;
}

.halfBox {
    float: left;
    margin-bottom: 10px;
    margin-right: 0;
    width: 49.9%;
}

.threeCol {
    float: left;
    width: 33.3%;
}

.with-fullpage-component {
    .inner-container {
        margin: 0 30px;
        position: relative;
    }

    .footerBreadcrumb, .pager {
        margin: 40px 30px 0 30px;
    }
}

#content {
    margin: 0 30px !important;
    position: relative;

    @media @md {
        margin: 0 20px !important;
    }

    ul {
        margin-left: 15px;

        li {
            background: url("@{images}/listbullet.png") 0 5px no-repeat;
            line-height: 1.5em;
            margin-bottom: 5px;
            padding-left: 15px;
        }
    }

    ol {
        margin-left: 15px;
    }
}

.withoutcol-colum {

    #content {
        margin: 0 30px;
    }
}


#languageSwitcher {
    display: none;
}

#help {
    font-size: .8571em;
    padding: 15px;
}

.britanika_printButton {
    display: none;
}

@media @md {


    .halfBox,
    .threeCol {
        float: none;
        width: auto;
    }

    .tabButton {
        width: 130px !important;
    }
}

@media @xs {
    #languageSwitcher {
        margin-left: 20px !important;
        margin-right: 20px;
    }

    .container {
        margin: 0 -20px !important;
    }

    .tabButton {
        width: 100px !important;
    }
}
