.documentPerex {
    font-style: italic;
}

.documentData {
    font-size: 0.9em;
    text-align: right;
}

.box {
    font-size: 0.9em;
    line-height: 1.4em;
    margin-bottom: 20px;

    p {
        margin-bottom: 4px;
    }
}
