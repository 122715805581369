// // Fonts
// // ------------------------------------------------------------------------

@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'open_sanssemibold';
    src: url('../fonts/opensans-semibold-webfont.eot');
    src: url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff'),
         url('../fonts/opensans-semibold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: 600;
    font-style: normal;

}


@f-light: 'open_sanslight', sans-serif;
@f-regular: 'open_sansregular', sans-serif;
@f-bold: 'open_sanssemibold', sans-serif;

@fw-light: 300;
@fw-regular: 400;
@fw-bold: 600;

.font-light {
    font-family: @f-light;
    font-weight: @fw-light;
}
.font-regular {
    font-family: @f-regular;
    font-weight: @fw-regular;
}
.font-bold {
    font-family: @f-bold;
    font-weight: @fw-bold;
}
