#exportData {
    background-color: @white;
    text-align: center;
    margin: 0 auto 5px auto;
    max-width: 300px;
    padding: 20px;

    .box-shadow(0 0 50px rgba(0, 0, 0, .2));

    .link {
        &.active {
            background: @blue;
            color: @white;
            margin: 10px auto;
            // padding: 7px 20px;
            // font-size: 0.875em;
            display: block;
            text-align: center;
            width: 100%;

            &:hover {
                color: @orange;
            }
        }
    }
}

.export-data__help {
    margin-top: 15px;
    display: block;
}
