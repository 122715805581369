.last-sync {
    font-size: 10px;
    .font-regular;
    position: absolute;
    top: 0;
    right: 30px;
    color: @blue;
    opacity: .6;

    @media @md {
        right: 20px;
    }
}
