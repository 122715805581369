// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.clearfix() {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}

.c {
  .clearfix();
}

.trans {
  .transition(all .2s ease-in-out);
}

.transSlow {
  .transition(all .4s ease-in-out);
}

.border-box {
  .box-sizing(border-box);
}

// Webkit-style focus
// ------------------
.tab-focus() {
  // Default
  outline: thin dotted;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Sizing shortcuts
// -------------------------
.size(@width: 5px, @height: 5px) {
  width: @width;
  height: @height;
}
.square(@size: 5px) {
  .size(@size, @size);
}

// Placeholder text
// -------------------------
.placeholder(@color: @placeholderText) {
  &::-webkit-input-placeholder {
    color: @color;
    line-height: 20px;
    #font > #family > .open-sans();
  }
  &:-moz-placeholder {
    color: @color;
    line-height: 20px;
    #font > #family > .open-sans();
  }
  &::-moz-placeholder {
    color: @color;
    line-height: 20px;
    #font > #family > .open-sans();
  }
  &:-ms-input-placeholder {
    color: @color;
    line-height: 20px;
    #font > #family > .open-sans();
  }
}

// FONTS
// --------------------------------------------------

#font {
  #family {
    .serif() {
      font-family: Georgia, "Times New Roman", Times, serif;
    }
    .sans-serif() {
      font-family: "Arial CE", Arial, sans-serif;
    }
    .lato() {
      font-family: "Lato", "Arial CE", Arial, sans-serif;
    }
    .open-sans() {
      font-family: "Open Sans", "Arial CE", Arial, sans-serif;
    }
    .novecento() {
      font-family: "Novecento", "Arial CE", Arial, sans-serif;
    }
  }

  .size(@font-size: 16, @line: 1) {
    @rem: (@font-size / 10);
    font-size: @font-size * 1px;
    font-size: ~"@{rem}rem";
    line-height: @line;
  }

}

.font-size(@font-size: 16, @line: 1) {
  @rem: (@font-size / 10);
  font-size: @font-size * 1px;
  font-size: ~"@{rem}rem";
  line-height: @line;
}

.truncate-line(@bool: true) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-line(@bool) when (@bool = false) {
  white-space: normal;
}


// Float and clear
// --------------------------------------------------

.clear(@direction: left) {
  float: @direction;
  clear: @direction;
}



// CSS3 PROPERTIES
// --------------------------------------------------


// ANIMATE
.animation(@anime) {
  -webkit-animation: e(@anime);
  -moz-animation: e(@anime);
  -o-animation: e(@anime);
  -ms-animation: e(@anime);
  animation: e(@anime);
}

.animation-delay(@anime) {
  -webkit-animation-delay: @anime;
  -moz-animation-delay: @anime;
  -o-animation-delay: @anime;
  -ms-animation-delay: @anime;
  animation-delay: @anime;
}

.animation-delay(@anime) {
  -webkit-animation-delay: @anime;
  -moz-animation-delay: @anime;
  -o-animation-delay: @anime;
  -ms-animation-delay: @anime;
  animation-delay: @anime;
}

.animation-fill-mode(@anime) {
  -webkit-animation-fill-mode: @anime;
  -moz-animation-fill-mode: @anime;
  -o-animation-fill-mode: @anime;
  -ms-animation-fill-mode: @anime;
  animation-fill-mode: @anime;
}

// Border Radius
.border-radius(@radius: 5px) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}

// Drop shadows
.box-shadow(@shadow: 0 1px 3px rgba(0, 0, 0, .25)) {
  -webkit-box-shadow: @shadow;
     -moz-box-shadow: @shadow;
          box-shadow: @shadow;
}

// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
     -moz-transition: @transition;
      -ms-transition: @transition;
       -o-transition: @transition;
          transition: @transition;
}

.transitionDouble(@transition, @transition2) {
  -webkit-transition: @transition, @transition2;
     -moz-transition: @transition, @transition2;
      -ms-transition: @transition, @transition2;
       -o-transition: @transition, @transition2;
          transition: @transition, @transition2;
}

.transition-easing(@easing) {
  -webkit-transition-timing-function: @easing;
     -moz-transition-timing-function: @easing;
      -ms-transition-timing-function: @easing;
       -o-transition-timing-function: @easing;
          transition-timing-function: @easing;
}

// Transformations
.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
     -moz-transform: rotate(@degrees);
      -ms-transform: rotate(@degrees);
       -o-transform: rotate(@degrees);
          transform: rotate(@degrees);
}
.rotateY(@degrees) {
  -webkit-transform: rotateY(@degrees);
     -moz-transform: rotateY(@degrees);
      -ms-transform: rotateY(@degrees);
       -o-transform: rotateY(@degrees);
          transform: rotateY(@degrees);
}
.rotateX(@degrees) {
  -webkit-transform: rotateX(@degrees);
     -moz-transform: rotateX(@degrees);
      -ms-transform: rotateX(@degrees);
       -o-transform: rotateX(@degrees);
          transform: rotateX(@degrees);
}
.rotateZ(@degrees) {
  -webkit-transform: rotateZ(@degrees);
     -moz-transform: rotateZ(@degrees);
      -ms-transform: rotateZ(@degrees);
       -o-transform: rotateZ(@degrees);
          transform: rotateZ(@degrees);
}
.scale(@ratio) {
  -webkit-transform: scale(@ratio);
     -moz-transform: scale(@ratio);
      -ms-transform: scale(@ratio);
       -o-transform: scale(@ratio);
          transform: scale(@ratio);
}
.scale3d(@x, @y, @z) {
  -webkit-transform: scale3d(@x, @y, @z);
     -moz-transform: scale3d(@x, @y, @z);
      -ms-transform: scale3d(@x, @y, @z);
       -o-transform: scale3d(@x, @y, @z);
          transform: scale3d(@x, @y, @z);
}
.rotate3d(@ratio1, @ratio2, @ratio3) {
  -webkit-transform: rotate3d(@ratio1, @ratio2, @ratio3);
     -moz-transform: rotate3d(@ratio1, @ratio2, @ratio3);
      -ms-transform: rotate3d(@ratio1, @ratio2, @ratio3);
       -o-transform: rotate3d(@ratio1, @ratio2, @ratio3);
          transform: rotate3d(@ratio1, @ratio2, @ratio3);
}
.translate(@x: 0, @y: 0) {
  -webkit-transform: translate(@x, @y);
     -moz-transform: translate(@x, @y);
      -ms-transform: translate(@x, @y);
       -o-transform: translate(@x, @y);
          transform: translate(@x, @y);
}

.translateX(@x: 0) {
  -webkit-transform: translateX(@x);
     -moz-transform: translateX(@x);
      -ms-transform: translateX(@x);
       -o-transform: translateX(@x);
          transform: translateX(@x);
}

.translateY(@y: 0) {
  -webkit-transform: translateY(@y);
     -moz-transform: translateY(@y);
      -ms-transform: translateY(@y);
       -o-transform: translateY(@y);
          transform: translateY(@y);
}

.translateZ(@z: 0) {
  -webkit-transform: translateZ(@z);
     -moz-transform: translateZ(@z);
      -ms-transform: translateZ(@z);
       -o-transform: translateZ(@z);
          transform: translateZ(@z);
}

.translate2D(@x: 0, @y: 0, @deg) {
  -webkit-transform: @x @y @deg;
     -moz-transform: @x @y @deg;
      -ms-transform: @x @y @deg;
       -o-transform: @x @y @deg;
          transform: @x @y @deg;
}

.skew(@x: 0, @y: 0) {
  -webkit-transform: skew(@x, @y);
     -moz-transform: skew(@x, @y);
      -ms-transform: skew(@x, @y);
       -o-transform: skew(@x, @y);
          transform: skew(@x, @y);
}

.transform-origin(@origin) {
  -webkit-transform-origin: @origin;
     -moz-transform-origin: @origin;
       -o-transform-origin: @origin;
          transform-origin: @origin;
}

.perspective (@value: 1000) {
  -webkit-perspective:  @value;
     -moz-perspective:   @value;
      -ms-perspective:  @value;
          perspective:    @value;
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
.background-clip(@clip) {
  -webkit-background-clip: @clip;
     -moz-background-clip: @clip;
          background-clip: @clip;
}

// Background sizing
.background-size(@size){
  -webkit-background-size: @size;
     -moz-background-size: @size;
       -o-background-size: @size;
          background-size: @size;
}


// Box sizing
.box-sizing(@boxmodel) {
  -webkit-box-sizing: @boxmodel;
     -moz-box-sizing: @boxmodel;
          box-sizing: @boxmodel;
}

// User select
// For selecting text on the page
.user-select(@select) {
-webkit-touch-callout: none;
  -webkit-user-select: @select;
     -moz-user-select: @select;
       -o-user-select: @select;
          user-select: @select;
}


// Resize anything
.resizable(@direction: both) {
  resize: @direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
.content-columns(@columnCount, @columnGap: @gridColumnGutter) {
  -webkit-column-count: @columnCount;
     -moz-column-count: @columnCount;
          column-count: @columnCount;
  -webkit-column-gap: @columnGap;
     -moz-column-gap: @columnGap;
          column-gap: @columnGap;
}

.appearance(@appearance) {
  -webkit-appearance: @appearance;
  -moz-appearance:    @appearance;
  appearance:         @appearance;
}

// Opacity
.opacity(@opacity: 100) {
  opacity: @opacity / 100;
  filter: e(%("alpha(opacity=%d)", @opacity));
}


.basic-gradient (@color, @gradient-color-start, @gradient-color-end, @situation: bottom) {
  @c: @color;
  @gcs: @gradient-color-start;
  @gce: @gradient-color-end;
  @s: @situation;

  background: -moz-linear-gradient(@s, @gcs, @gce); /* FF3.6+ */
  background: -o-linear-gradient(@s, @gcs, @gce); /* Opera 11.10+ */
  background: -ms-linear-gradient(@s, @gcs, @gce); /* IE10+ */
  background: linear-gradient(@s, @gcs, @gce); /* W3C */
  background: -webkit-linear-gradient(@s, @gcs, @gce);
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=@gcs, endColorstr=@gce);
}


// POPOVER ARROWS
// -------------------------
// For tipsies and popovers
#popoverArrow {
  .top(@arrowWidth: 5px) {
    bottom: 0;
    left: 50%;
    margin-left: -@arrowWidth;
    border-left: @arrowWidth solid transparent;
    border-right: @arrowWidth solid transparent;
    border-top: @arrowWidth solid @black;
  }
  .left(@arrowWidth: 5px) {
    top: 50%;
    right: 0;
    margin-top: -@arrowWidth;
    border-top: @arrowWidth solid transparent;
    border-bottom: @arrowWidth solid transparent;
    border-left: @arrowWidth solid @black;
  }
  .bottom(@arrowWidth: 5px) {
    top: 0;
    left: 50%;
    margin-left: -@arrowWidth;
    border-left: @arrowWidth solid transparent;
    border-right: @arrowWidth solid transparent;
    border-bottom: @arrowWidth solid @black;
  }
  .right(@arrowWidth: 5px) {
    top: 50%;
    left: 0;
    margin-top: -@arrowWidth;
    border-top: @arrowWidth solid transparent;
    border-bottom: @arrowWidth solid transparent;
    border-right: @arrowWidth solid @black;
  }
}


.theadRedBorder {
    content: '';
    display: block;
    width: 14px;
    height: 100%;
    padding-bottom: 1px;
    position: absolute;
    right: -17px;
    top: 0;
    z-index: 10;
    background: @white;
    border-right: 3px solid @red;
}
.theadRedBorderPadding {
    padding-left: 33px;
    padding-bottom: 20px;
}
