.navigation {
    opacity: .6;

    .breadcrumbs {
        font-size: 0.875em;
        padding: 15px 0 0;

        strong {
            color: @blue;
            .font-regular;
        }
    }
}

.footerBreadcrumb {
    clear: both;
    margin-top: 40px;
}
