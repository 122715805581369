.news-item {
    padding: 10px 0 30px 0;
    margin: 0 0 55px 50px;
    border-bottom: 1px solid #ccc;

    h3 {
        position: relative;
        margin-top: 5px;

        &:before {
            content: "";
            position: absolute;
            left: -50px;
            top: -10px;
            background: url(../images/news_sprite.png) no-repeat;
            width: 37px;
            height: 35px;
        }
    }

    &.britanika-news {
        h3:before {
            left: -40px;
        }
    }

    &.hypoteka {
        h3:before {
            background-position: -83px 0;
        }
    }

    &.investice {
        h3:before {
            background-position: -126px 0;
        }
    }

    &.pojisteni {
        h3:before {
            background-position: -39px 0;
        }
    }

    .date {
        color: #848484;

        span {
            display: inline-block;
            position: relative;
            padding: 0 0 0 20px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
            }

            &.d1 {
                padding-right: 30px;

                &:before {
                    margin-top: -6px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 10px;
                    border-color: transparent transparent transparent #00386f;
                }
            }

            &.d2 {
                &:before {
                    width: 10px;
                    height: 10px;
                    background: #d20536;
                    margin-top: -5px;
                }
            }
        }
    }

    .file-item {
        display: inline-block;
        vertical-align: top;
        margin: 15px 50px 10px 0;
        padding: 8px 0 15px 42px;


        @media @sm {
            margin-right: 15px;
            margin-bottom: 5px;
        }

        .file-size {
            color: #848484;
        }
    }

    &.invalid {
        color: #848484;

        a {
            color: #848484;
        }

        h3 {
            color: #848484;
        }

        &.britanika-news {
            h3:before {
                background-position: 0 -43px;
            }
        }

        &.hypoteka {
            h3:before {
                background-position: -84px -43px;
            }
        }

        &.investice {
            h3:before {
                background-position: -126px -43px;
            }
        }

        &.pojisteni {
            h3:before {
                background-position: -40px -42px;
            }
        }

        .date {
            span {
                padding-left: 0;

                &:before {
                    content: none;
                }

                &.d2 {
                    display: none;
                }
            }
        }
    }
}
