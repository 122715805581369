html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
menu,
time,
mark,
audio,
video {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
article,
aside,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote {
  quotes: none;
}
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
ins {
  background-color: #ffff99;
  color: #000000;
  text-decoration: none;
}
mark {
  background-color: #ffff99;
  color: #000000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title] {
  border-bottom: 1px dotted #000000;
  cursor: help;
}
dfn[title] {
  border-bottom: 1px dotted #000000;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  font-size: 1em;
  margin-bottom: 15px;
  table-layout: fixed;
}
hr {
  border-top: 1px solid #cccccc;
  border: 0;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}
input,
select,
button {
  border: none;
  vertical-align: middle;
  -webkit-appearance: none;
  border-radius: 0;
}
label,
input[type=button],
input[type=submit],
button {
  cursor: pointer;
}
input[type="radio"] {
  vertical-align: text-bottom;
}
input[type="checkbox"] {
  vertical-align: bottom;
}
.ie6 input {
  vertical-align: text-bottom;
}
.ie7 img {
  -ms-interpolation-mode: bicubic;
}
/* wysiwyg reset */
font {
  color: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
}
@font-face {
  font-family: 'open_sanslight';
  src: url('../fonts/opensans-light-webfont.eot');
  src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans-light-webfont.woff2') format('woff2'), url('../fonts/opensans-light-webfont.woff') format('woff'), url('../fonts/opensans-light-webfont.ttf') format('truetype'), url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansregular';
  src: url('../fonts/opensans-regular-webfont.eot');
  src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans-regular-webfont.woff2') format('woff2'), url('../fonts/opensans-regular-webfont.woff') format('woff'), url('../fonts/opensans-regular-webfont.ttf') format('truetype'), url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanssemibold';
  src: url('../fonts/opensans-semibold-webfont.eot');
  src: url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans-semibold-webfont.woff2') format('woff2'), url('../fonts/opensans-semibold-webfont.woff') format('woff'), url('../fonts/opensans-semibold-webfont.ttf') format('truetype'), url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
  font-weight: 600;
  font-style: normal;
}
.font-light {
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
.font-regular {
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
}
.font-bold {
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
.c {
  *zoom: 1;
}
.c:before,
.c:after {
  display: table;
  content: "";
}
.c:after {
  clear: both;
}
.trans {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.transSlow {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.theadRedBorder {
  content: '';
  display: block;
  width: 14px;
  height: 100%;
  padding-bottom: 1px;
  position: absolute;
  right: -17px;
  top: 0;
  z-index: 10;
  background: #FFFFFF;
  border-right: 3px solid #283164;
}
.theadRedBorderPadding {
  padding-left: 33px;
  padding-bottom: 20px;
}
.navigation {
  opacity: .6;
}
.navigation .breadcrumbs {
  font-size: 0.875em;
  padding: 15px 0 0;
}
.navigation .breadcrumbs strong {
  color: #00376f;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
}
.footerBreadcrumb {
  clear: both;
  margin-top: 40px;
}
.link,
.button {
  background: #00376f;
  border: none;
  color: #FFFFFF;
  line-height: normal;
  display: inline-block;
  text-decoration: none;
  border-radius: 3px;
  outline: none;
  font-size: 0.875em;
  padding: 7px 15px;
  height: 34px;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media only screen and (max-width: 800px) {
  .link,
  .button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.link .dijitButtonNode,
.button .dijitButtonNode {
  background: none;
  border: none;
  padding: 0;
}
.link .dijitButtonText,
.button .dijitButtonText {
  background: none;
  border: none;
  padding: 0;
}
.link .dijitInline,
.button .dijitInline {
  display: inline;
}
.link:hover,
.button:hover,
.link:focus,
.button:focus,
.link:active,
.button:active {
  color: #FFAD2F;
  border: none;
}
.link.active,
.button.active {
  background: #FFAD2F;
  color: #00376f;
}
.link.-outline,
.button.-outline {
  background-color: transparent;
  border: 1px solid #00376f;
  color: #00376f;
  line-height: 1.3em;
}
@media only screen and (max-width: 800px) {
  .link.-outline,
  .button.-outline {
    line-height: normal;
  }
}
.link.-outline:hover,
.button.-outline:hover,
.link.-outline:focus,
.button.-outline:focus,
.link.-outline:active,
.button.-outline:active {
  color: #00376f;
  border: 1px solid #00376f;
  background-color: #FFAD2F;
}
.link.-outline.active,
.button.-outline.active {
  background: #00376f;
  color: white;
  cursor: auto;
}
.link.smallBtn,
.button.smallBtn {
  vertical-align: middle;
  font-size: 0.875em;
  padding: 7px 25px;
  height: 34px;
}
.link.dispatchBtn,
.button.dispatchBtn {
  background: transparent;
  color: #00376f;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline;
  position: relative;
  font-size: 1em;
  padding: 0;
  margin: 0 10px 4px 30px;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
}
.link.dispatchBtn:hover,
.button.dispatchBtn:hover {
  color: #99691F;
}
.link.dispatchBtn:before,
.button.dispatchBtn:before {
  content: '';
  display: block;
  width: 15px;
  height: 19px;
  position: absolute;
  left: -20px;
  top: 50%;
  margin-top: -9px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left top;
}
.link.dispatchBtn.icon-delete:before,
.button.dispatchBtn.icon-delete:before {
  background-image: url("../images/icon_red-trash_small.png");
}
.link.dispatchBtn.icon-edit:before,
.button.dispatchBtn.icon-edit:before {
  background-image: url("../images/icon_red-pen_small.png");
}
.link.dispatchBtn.icon-preview:before,
.button.dispatchBtn.icon-preview:before {
  background-image: url("../images/icon_red-eye_small.png");
}
.link.dispatchBtn.icon-close:before,
.button.dispatchBtn.icon-close:before {
  background-image: url("../images/icon_red-lock_small.png");
}
.link.dispatchBtn.icon-print:before,
.button.dispatchBtn.icon-print:before {
  background-image: url("../images/icon_red-printer_small.png");
}
.link.dispatchBtn.icon-newContract:before,
.button.dispatchBtn.icon-newContract:before {
  background-image: url("../images/icon_red-doc_small.png");
}
.link.dispatchBtn.icon-contracts:before,
.button.dispatchBtn.icon-contracts:before {
  background-image: url("../images/icon_red-folder_small.png");
}
.link.dispatchBtnIcon,
.button.dispatchBtnIcon {
  width: 15px;
  height: 19px;
  padding: 0;
  display: inline-block;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left top;
}
.link.dispatchBtnIcon span,
.button.dispatchBtnIcon span {
  display: none;
}
.link.dispatchBtnIcon.icon-delete,
.button.dispatchBtnIcon.icon-delete {
  background-image: url("../images/icon_red-trash_small.png");
}
.link.dispatchBtnIcon.icon-edit,
.button.dispatchBtnIcon.icon-edit {
  background-image: url("../images/icon_red-pen_small.png");
}
.link.dispatchBtnIcon.icon-preview,
.button.dispatchBtnIcon.icon-preview {
  background-image: url("../images/icon_red-eye_small.png");
}
.link.dispatchBtnIcon.icon-close,
.button.dispatchBtnIcon.icon-close {
  background-image: url("../images/icon_red-lock_small.png");
}
.link.dispatchBtnIcon.icon-print,
.button.dispatchBtnIcon.icon-print {
  background-image: url("../images/icon_red-printer_small.png");
}
.link.dispatchBtnIcon.icon-newContract,
.button.dispatchBtnIcon.icon-newContract {
  background-image: url("../images/icon_red-doc_small.png");
}
.link.dispatchBtnIcon.icon-contracts,
.button.dispatchBtnIcon.icon-contracts {
  background-image: url("../images/icon_red-folder_small.png");
}
.resetLink,
.resetButton {
  padding: 0;
  font-size: 1em;
  display: inline;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
}
.dijitCalendarPopup {
  font-size: .9em;
  background: #00376f;
  padding: 10px;
}
.dijitCalendarPopup .dijitCalendarContainer {
  border: none;
  background: #00376f;
  color: #FFFFFF;
  width: 275px !important;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitButtonNode {
  position: relative;
  width: auto;
  height: auto;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitButtonNode .dijitArrowButtonInner {
  display: none;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarMonthContainer th {
  background: none;
  padding-bottom: .5em;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarMonthContainer .dijitCalendarMonthLabel {
  color: #FFFFFF;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarDayLabelTemplate {
  background: #00376f;
  color: #a8a8a8;
  padding-top: .5em;
  border-top-color: #d6d6d6;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarPreviousMonth,
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarNextMonth,
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarCurrentMonth {
  background: none;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarPreviousMonth,
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarNextMonth {
  color: #a8a8a8;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarDateTemplate {
  padding: 0.3em;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarDateTemplate.dijitCalendarHoveredDate {
  background: #283164;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarYearContainer {
  background: none;
  color: #FFFFFF;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarYearContainer .dijitCalendarPreviousYear,
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarYearContainer .dijitCalendarNextYear,
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarYearContainer .dijitCalendarSelectedYear {
  color: #FFFFFF !important;
  margin: 0 .25em;
  padding: .2em;
  font-size: .9em;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
.dijitCalendarPopup .dijitCalendarContainer .dijitCalendarYearContainer .dijitCalendarSelectedYear {
  background: #283164 !important;
}
.dijitCalendarMonthMenu.dijitMenu {
  border-color: #00376f;
}
.dijitCalendarMonthMenu.dijitMenu .dijitCalendarMonthLabel {
  color: #00376f;
  padding: 2px 0;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
.dijitCalendarMonthMenu.dijitMenu .dijitCalendarMonthLabel.dijitCalendarMonthLabelHover {
  background: #283164;
  color: #FFFFFF;
}
.britanikaCustoms-inlineHeading {
  display: inline-block;
  margin-right: 25px;
}
.britanikaCustoms-switch {
  float: left;
  width: auto;
  margin-right: 50px;
}
.britanikaCustoms-switch .pipeSeparator {
  display: none;
}
.clients {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 25px;
}
@media only screen and (max-width: 800px) {
  .clients {
    display: block;
  }
}
.clients .clients__filter {
  margin-bottom: 0;
}
.clients__create {
  padding-left: 7px;
}
@media only screen and (max-width: 800px) {
  .clients__create {
    margin-top: 10px;
    padding-left: 0;
  }
}
.britanikaCustoms-alphaFilter {
  margin-top: 0;
}
@media only screen and (max-width: 800px) {
  .britanikaCustoms-alphaFilter {
    float: left;
    margin: -40px 0 0 0;
  }
}
.britanikaCustoms-alphaFilter .pipeSeparator {
  display: none;
}
.britanikaCustoms-alphaFilter .link {
  background: none;
  padding: initial;
  padding: 0;
  margin-right: 10px;
  display: inline;
  height: auto;
  font-size: 1.125em;
  line-height: 1.75em;
  text-decoration: none;
  background: transparent;
  border-bottom: 1px solid #b2c3d4;
  color: #00376f;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.britanikaCustoms-alphaFilter .link:hover {
  border-bottom: 1px solid #b2cbe4;
  color: #99691F;
}
.britanikaCustoms-alphaFilter .link.active {
  color: #283164;
  border: none;
}
img {
  border: none;
}
p {
  margin-bottom: 15px;
}
sup {
  bottom: 0.3em;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.2em;
  position: relative;
  vertical-align: baseline;
}
strong {
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
a,
.defaultLink {
  background: transparent;
  color: #00376f;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a:hover,
.defaultLink:hover {
  color: #99691F;
}
a.selected {
  cursor: text;
  text-decoration: none;
}
#content ul,
#content ol,
#rightCol ul,
#rightCol ol {
  list-style: none;
  margin: 0 0 15px 0;
}
#content ul ul,
#content ol ol,
#rightCol ul ul,
#rightCol ul ul {
  margin: 5px 0 0;
}
.-hidden {
  display: none;
}
.britanika_contactPerson {
  clear: both;
}
.contacts {
  margin-top: 55px;
}
.contacts .ib-box {
  margin-bottom: 60px;
}
.contact-img {
  float: left;
  width: 80px;
}
.contact-img img {
  border: 1px solid #e9e9e9;
  max-width: 100%;
}
.contact-img .border-box {
  width: 80px;
  height: 80px;
  border: 1px solid #c7c7c7;
}
.contact-info {
  padding-left: 100px;
}
.contact-info-advisors {
  padding-left: 0;
}
.contact-info p {
  margin-bottom: 10px;
}
.contact-info p.table {
  margin-bottom: 0;
}
.contact-info p.table span {
  padding-top: 3px;
  padding-bottom: 3px;
}
.contact-info .name {
  font-size: 25px;
  line-height: 1.25em;
}
.fotoBox {
  background-color: #FFFFFF;
  float: left;
  margin-right: 10px;
}
.fotoInfo {
  font-size: 0.9em;
  height: 40px;
  overflow: hidden;
}
.ib-box {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: 0 30px 25px 0;
  margin-bottom: 40px;
}
.ib-col-3 .ib-box {
  width: 33.1%;
}
.tel-number {
  border-bottom: none;
  color: #000000;
  cursor: default;
}
.tel-number:hover {
  border-bottom: none;
  color: #000000;
}
#exportData {
  background-color: #FFFFFF;
  text-align: center;
  margin: 0 auto 5px auto;
  max-width: 300px;
  padding: 20px;
  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}
#exportData .link.active {
  background: #00376f;
  color: #FFFFFF;
  margin: 10px auto;
  display: block;
  text-align: center;
  width: 100%;
}
#exportData .link.active:hover {
  color: #FFAD2F;
}
.export-data__help {
  margin-top: 15px;
  display: block;
}
.detail-info {
  text-align: left;
  margin-top: 40px;
  font-size: 14px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
.kz-dialog::backdrop {
  background-color: rgba(0, 54, 95, 0.6);
}
.kz-dialog {
  border: none;
  padding: 0;
  max-width: 800px;
  width: calc(99vw);
  margin: auto;
}
@supports not selector(dialog) {
  .kz-dialog {
    display: none;
  }
}
.kz-dialog.show-dialog:not([open]) {
  display: block;
  position: fixed;
  inset-block-start: 0;
  inset-block-end: 0;
  max-height: calc(92%);
  user-select: text;
  visibility: visible;
  overflow: auto;
  z-index: 30;
  box-shadow: 0 0 0 1000px rgba(0, 54, 95, 0.6);
}
.kz-dialog img {
  max-width: 100%;
  height: auto;
  aspect-ratio: 1.77777778;
}
.close-kz-dialog {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #00376f;
  border: none;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.close-kz-dialog:hover {
  background-color: #FFAD2F;
}
.close-kz-dialog:focus {
  outline: none;
  border: 0;
}
.close-kz-dialog svg {
  fill: white;
}
.dijitValidationContainer input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.dijitArrowButtonContainer input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.dijitTextBoxFocused {
  outline: none;
}
.tundra .dijitTextBox,
.tundra .dijitComboBox,
.tundra .dijitSpinner,
.tundra .dijitTextArea,
.tundra .dijitInlineEditor {
  position: relative;
}
.tundra .dijitTextBox input,
.tundra .dijitComboBox input,
.tundra .dijitSpinner input,
.tundra .dijitTextArea input,
.tundra .dijitInlineEditor input {
  font-size: 1em;
  color: #00376f;
  vertical-align: middle;
  font-family: "Open Sans", "Arial CE", Arial, sans-serif;
  padding: 6px 35px 6px 15px;
  margin: 0;
  outline: none;
}
.dijitSpinner,
.dijitTextArea[cols] {
  min-width: 40%;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
}
.dijitTextArea[cols] {
  min-height: 100px;
}
.tundra .dijitTextBox,
.tundra .dijitComboBox,
.tundra .dijitRadio,
.tundra .dijitCheckBox,
.tundra textarea {
  background: #FFFFFF;
  margin: 0;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  max-width: 700px;
  width: 100%;
}
.tundra .dijitCheckBox,
.tundra .dijitRadio {
  width: 24px;
  height: 24px;
  position: relative;
  top: -2px;
}
.tundra .dijitCheckBox.dijitCheckBoxChecked,
.tundra .dijitRadio.dijitCheckBoxChecked {
  background: #FFFFFF url("../images/icon_red-checked.png") center center no-repeat;
}
.tundra .dijitCheckBox.dijitRadioChecked::before,
.tundra .dijitRadio.dijitRadioChecked::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  background: #00376f;
  border-radius: 50%;
}
.tundra .dijitCheckBox .dijitCheckBoxInput,
.tundra .dijitRadio .dijitCheckBoxInput {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.tundra .dijitRadio {
  border-radius: 50%;
}
.tundra .dijitInputContainer {
  margin: 0;
}
.tundra .dijitInputContainer input {
  margin: 0;
}
.tundra .dijitInputContainer .dijitInputInner {
  padding-left: 11px !important;
  font-size: 1.1em;
  line-height: normal;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.tundra .dijitButtonNode {
  border: none;
  background: none;
  float: none;
  width: 35px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.tundra .dijitButtonNode:hover {
  top: 3px;
}
.tundra .dijitButtonNode input {
  background: url("../images/icon_red-caret-down.png") 50% 50% no-repeat;
  background-size: 17px 11px;
  width: 35px;
  height: 40px;
  position: absolute;
  top: 3px;
  left: 0;
}
.tundra .dijitButtonNode .dijitArrowButtonInner {
  background-position: center center;
}
.tundra .dijitMenu {
  border-color: #FFAD2F;
  background: #FFFFFF;
  color: #00376f;
}
.tundra .dijitMenu .dijitMenuItem {
  padding-left: 20px;
}
.tundra .dijitMenu .dijitMenuItemSelected {
  background: #283164;
  color: #FFFFFF;
}
.tundra .dijitPlaceHolder {
  color: #00376f;
  font-style: normal;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 10px;
}
table.dataTable thead .filterRow .dijitTextBox,
table.dataTable thead .filterRow .dijitComboBox,
table.dataTable thead .filterRow .dijitSpinner,
table.dataTable thead .filterRow .dijitTextArea,
table.dataTable thead .filterRow .dijitInlineEditor {
  position: relative;
  vertical-align: initial;
  margin-top: -1px;
}
table.dataTable thead .filterRow .dijitTextBox input,
table.dataTable thead .filterRow .dijitComboBox input,
table.dataTable thead .filterRow .dijitSpinner input,
table.dataTable thead .filterRow .dijitTextArea input,
table.dataTable thead .filterRow .dijitInlineEditor input {
  font-size: 1em;
  color: #00376f;
  vertical-align: middle !important;
  padding: 6px 15px 5px 7px;
  margin: 0;
}
table.dataTable thead .filterRow .dijitInputContainer {
  margin: 0 2px 0 0;
}
table.dataTable thead .filterRow .dijitInputContainer .dijitInputInner {
  padding-left: 8px !important;
  padding-right: 3px !important;
  font-size: .9em;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
table.dataTable thead .filterRow .dijitButtonNode {
  width: 18px;
  height: 18px;
}
table.dataTable thead .filterRow .dijitButtonNode:hover {
  top: 2px;
}
table.dataTable thead .filterRow .dijitButtonNode input {
  background: url("../images/icon_red-caret-down-indent_small.png") 100% 50% no-repeat;
  background-size: 20px auto;
  width: 22px;
  height: 20px;
  position: absolute;
  top: 2px;
  left: 0;
}
table.dataTable thead .filterRow .dijitButtonNode.dijitDownArrowButton {
  background-color: #FFFFFF !important;
  margin-top: 3px;
  margin-right: 2px;
  padding: 0;
}
table.dataTable thead .filterRow .dijitButtonNode.dijitDownArrowButton .dijitArrowButtonInner {
  background-position: right center;
  margin: 0;
  padding: 0;
}
table.dojoDndAvatar input[type=checkbox] {
  display: none;
}
@media only screen and (max-width: 800px) {
  .dijitTextBox,
  .dijitComboBox,
  .dijitSpinner,
  .dijitTextArea[cols] {
    width: 100%;
    box-sizing: border-box;
  }
}
.dijitTextBoxError .dijitValidationContainer {
  margin: 0 35px 0 0;
}
.tundra .dijitTextArea,
textarea {
  font-size: 1.1em;
  line-height: normal;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  color: #00376f;
  padding: 7px 13px;
  min-height: 120px;
}
.discussionPost {
  background: #f5f5f5;
  margin-bottom: 10px;
  padding: 10px 13px;
}
.discussionPost h4 {
  margin-bottom: 4px;
}
.discussionPost p {
  margin-bottom: 0;
}
.dispatch-client {
  padding: 0 0 10px 0;
}
.dispatch-client h3 {
  margin: 5px 20px 10px 0;
  display: inline-block;
  vertical-align: middle;
  color: #00376f;
}
.dispatch-client .link {
  height: auto;
}
.dispatch-client-contracts {
  border-collapse: collapse;
  border-spacing: 0;
  color: #000000;
  font-size: 14px;
  margin-bottom: 15px;
  width: 100%;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  margin: 15px 0 70px 0;
}
.dispatch-client-contracts th {
  border-bottom: 3px solid #FFAD2F;
  padding: 15px 10px 15px 0;
  text-align: left;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.3em;
  vertical-align: bottom;
}
.dispatch-client-contracts td {
  border-bottom: 1px solid #c7c7c7;
  padding: 15px 10px 15px 0;
}
.dispatch-client-contracts td.imageInside {
  padding-left: 45px !important;
  background-position: 5px 8px;
}
.dispatch-client-contracts td.money {
  text-align: right;
  padding-right: 30px;
}
.dispatch-client-contracts td[data-title="Popis škody"] {
  line-height: normal;
  opacity: .7;
  font-style: italic;
}
.dispatch-client-contracts tr:first-child td {
  padding-top: 20px;
}
.dispatch-client-contracts tr:first-child td.imageInside {
  background-position: 5px 23px;
}
.dispatch-client-contracts tr:last-child td {
  padding-bottom: 20px;
  border-bottom: 3px solid #FFAD2F;
}
.dispatch-client-contracts tr:last-child td.imageInside {
  background-position: 5px 8px;
}
.dispatch-client-contracts tr:hover td {
  background: #f2f2f2;
}
.dispatch-client-contracts thead tr.labels {
  word-wrap: break-word;
}
.dispatch-client-contracts thead tr.labels a {
  color: gray;
  background-size: 9px 9px;
  text-decoration: none;
  padding: 0;
  border: none;
}
.dispatch-client-contracts thead tr.labels a.otaznik {
  width: 18px;
  height: 18px;
  padding-right: 0;
  display: inline-block;
  background: url("../images/icon_circle-red-question.png") 0 0 no-repeat;
}
.dispatch-client-contracts thead tr.labels a.otaznik img {
  display: none;
}
.dispatch-client-contracts thead .filterRow input,
.dispatch-client-contracts thead .filterRow select {
  font-size: 0.875em;
  padding: 6px 15px 6px 7px;
  margin: 3px 2px 2px 2px;
}
.dispatch-client-contracts thead .filterRow select {
  background: #FFFFFF url("../images/icon_red-caret-down-indent_small.png") 100% 50% no-repeat;
  background-size: 20px auto;
}
.dispatch-client-contracts thead .filterRow th {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dispatch-client-contracts thead .filterRow.operators th {
  border-bottom: 1px solid #c7c7c7;
}
.dispatch-client-contracts thead .filterRow.operators td {
  text-align: center;
}
.dispatch-client-contracts thead .filterRow.operators select {
  max-width: 60px;
}
.dispatch-client-contracts thead .filterRow.values select[name=operator_commission_paid_date_till] {
  max-width: 60px;
}
.dispatch-client-contracts thead .filterRow.values select[name=operator_maturity_date_till] {
  max-width: 60px;
}
.dispatch-client-contracts tbody tr.red {
  background-color: #ff6666;
}
.dispatch-client-contracts tbody tr.red:hover td {
  background-color: #ff0000;
}
.dispatch-client-contracts tbody td {
  word-wrap: break-word;
}
.dispatch-client-contracts .buttons {
  text-align: center;
}
.dispatch-client-contracts .inline-buttons {
  width: 400px;
}
.dispatch-client-contracts .inline-buttons a {
  height: auto;
}
.dispatch-add-client,
.dispatch-edit-client {
  margin-top: 50px;
}
.dispatch-add-client h3,
.dispatch-edit-client h3 {
  margin-bottom: 30px;
}
.dispatch-add-client .headings.blue,
.dispatch-edit-client .headings.blue {
  color: #00376f;
}
.dispatch-add-client .headings.red,
.dispatch-edit-client .headings.red {
  color: #283164;
}
.dispatch-add-client .headings.big,
.dispatch-edit-client .headings.big {
  font-size: 1.625em;
  margin-bottom: 30px;
}
.dispatch-add-client .headings.medium,
.dispatch-edit-client .headings.medium {
  font-size: 1.250em;
  margin-bottom: 25px;
}
.dispatch-add-client .buttons .tabButton,
.dispatch-edit-client .buttons .tabButton {
  background: #f3f5f7;
  border: none;
  border-top: 3px solid #00376f;
  font-size: 1.25em;
  text-align: center;
  padding: 20px 15px;
  margin-right: 7px;
  width: 220px;
  display: inline-block;
}
.dispatch-add-client .buttons .tabButton:hover,
.dispatch-edit-client .buttons .tabButton:hover {
  color: #283164;
}
.dispatch-add-client .buttons .tabButton.active,
.dispatch-edit-client .buttons .tabButton.active {
  color: #283164;
  border-top: 3px solid #283164;
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
.dispatch-add-client .container,
.dispatch-edit-client .container {
  background: #f3f5f7;
  padding: 50px 30px 30px 30px;
  margin: 0 -30px;
}
.dispatch-add-client .fCell,
.dispatch-edit-client .fCell {
  margin-bottom: 35px;
}
.dispatch-add-client label,
.dispatch-edit-client label {
  color: #00376f;
  font-size: 1.625em;
  margin-bottom: 20px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  display: table;
}
.dispatch-add-client label .required,
.dispatch-edit-client label .required {
  color: #283164;
  float: right;
  margin-left: 7px;
}
.dispatch-add-client .dijit.checkbox,
.dispatch-edit-client .dijit.checkbox,
.dispatch-form .dijit.checkbox,
#RCDispatchNote_form .dijit.checkbox,
.dispatch-add-client input[dojotype="dijit.form.CheckBox"].checkbox,
.dispatch-edit-client input[dojotype="dijit.form.CheckBox"].checkbox,
.dispatch-form input[dojotype="dijit.form.CheckBox"].checkbox,
#RCDispatchNote_form input[dojotype="dijit.form.CheckBox"].checkbox {
  float: left;
  margin-right: 15px;
  margin-top: 3px;
}
.dispatch-add-client #documents_array,
.dispatch-edit-client #documents_array,
.dispatch-form #documents_array,
#RCDispatchNote_form #documents_array {
  padding-bottom: 20px;
}
.dispatch-add-client #documents_array h4,
.dispatch-edit-client #documents_array h4,
.dispatch-form #documents_array h4,
#RCDispatchNote_form #documents_array h4 {
  color: #00376f;
  margin-top: 25px;
  font-size: 1.65em;
}
.dispatch-add-client #documents_array h4 .required,
.dispatch-edit-client #documents_array h4 .required,
.dispatch-form #documents_array h4 .required,
#RCDispatchNote_form #documents_array h4 .required {
  color: #283164;
}
.dispatch-add-client #documents_array table,
.dispatch-edit-client #documents_array table,
.dispatch-form #documents_array table,
#RCDispatchNote_form #documents_array table {
  border-collapse: collapse;
  margin: 15px 0;
  width: 100%;
}
.dispatch-add-client #documents_array table td,
.dispatch-edit-client #documents_array table td,
.dispatch-form #documents_array table td,
#RCDispatchNote_form #documents_array table td {
  padding: 10px 15px 10px 0;
}
.dispatch-add-client #documents_array .item,
.dispatch-edit-client #documents_array .item,
.dispatch-form #documents_array .item,
#RCDispatchNote_form #documents_array .item {
  margin: 15px 15px 15px 0;
  min-width: 30%;
  display: inline-block;
  vertical-align: top;
}
.dispatch-add-client #documents_array .item label,
.dispatch-edit-client #documents_array .item label,
.dispatch-form #documents_array .item label,
#RCDispatchNote_form #documents_array .item label {
  font-size: 1.125em;
  color: #000000;
  vertical-align: middle;
  display: block;
  padding-top: 5px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  margin: 0;
}
.dispatch-add-client #documents_array .item label span,
.dispatch-edit-client #documents_array .item label span,
.dispatch-form #documents_array .item label span,
#RCDispatchNote_form #documents_array .item label span {
  width: auto;
  float: none;
  display: inline;
}
.dispatch-add-client label[for="form_element_form_foreign"],
.dispatch-edit-client label[for="form_element_form_foreign"],
.dispatch-form label[for="form_element_form_foreign"],
#RCDispatchNote_form label[for="form_element_form_foreign"] {
  font-size: 1.125em;
  color: #000000;
  vertical-align: middle;
  display: block;
  padding-top: 5px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
#RCDispatchNote_form label {
  color: #00376f;
  font-size: 1.625em;
  margin-bottom: 20px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  display: table;
  margin-top: 35px;
}
#RCDispatchNote_form label .required {
  color: #283164;
  float: right;
  margin-left: 7px;
}
#RCDispatchNote_form .dijitTextBoxDisabled .dijitButtonNode {
  right: 4px;
  top: 4px;
}
#RCDispatchNote_form .dijitTextBoxDisabled .dijitButtonNode .dijitArrowButtonInner {
  margin-left: 4px;
}
.dispatch-form {
  padding-left: 30px;
  margin-top: 20px;
  margin-bottom: 40px;
  border-left: 3px solid #00376f;
}
.dispatch-preview ul {
  list-style-type: disc !important;
  margin: 5px 10px 10px 30px !important;
  padding: 0 !important;
}
.dispatch-preview ul li {
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.dispatch-preview .details {
  margin-left: 30px;
}
.dispatch-preview .details h3 {
  font-size: 1.5em;
}
.smlouvy .dispatchBtn.icon-preview {
  display: inline-block;
  margin-bottom: 15px;
  height: auto;
}
@media only screen and (max-width: 800px) {
  .dispatchBtn.icon-preview {
    margin-left: 20px !important;
  }
}
@media only screen and (max-width: 400px) {
  .dispatchBtn.icon-preview {
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 520px) {
  .dispatch-client h3 {
    display: block;
  }
  .dispatchBtn {
    margin-left: 20px !important;
  }
}
.documentPerex {
  font-style: italic;
}
.documentData {
  font-size: 0.9em;
  text-align: right;
}
.box {
  font-size: 0.9em;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.box p {
  margin-bottom: 4px;
}
#content .fileStorage .fileList {
  margin-left: 0;
}
#content .fileStorage .fileList li {
  padding: 2px 0 6px 43px !important;
  line-height: 2em;
  background-position: 0 0;
  color: #bcbcbc;
}
@media only screen and (max-width: 800px) {
  #content .fileStorage .fileList li {
    padding: 6px 0 6px 43px !important;
  }
}
.highlighted {
  font-size: 1.3em;
  font-style: italic;
  margin: 20px 0;
}
.filter-stripe {
  background: #f3f5f7;
  padding: 30px 0;
  margin-bottom: 35px;
}
.filter-form fieldset {
  margin-bottom: 0 !important;
}
.filter-form .item {
  display: inline-block;
  position: relative;
  padding: 5px 35px 5px 0;
  box-sizing: border-box;
}
.filter-form .item:first-child {
  padding-left: 0;
}
.filter-form .item input,
.filter-form .item label {
  display: inline-block;
  vertical-align: middle;
}
.filter-form .item .checkbox-input {
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25) inset;
}
.filter-form .item input[type="checkbox"] {
  width: 30px;
  height: 30px;
  opacity: 0;
  position: relative;
  cursor: pointer;
  z-index: 5;
}
.filter-form .item input[type="checkbox"]:checked + span {
  background: #fff url(../images/checked.png) 8px 10px no-repeat;
}
.filter-form .item input[type="checkbox"]:checked + span + label {
  color: #000000;
}
.filter-form .item label {
  padding-left: 50px;
  margin: 0 0 0 10px;
  position: relative;
  color: #a2a3a5;
}
.filter-form .item label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  background: url(../images/news_sprite.png) no-repeat;
  width: 37px;
  height: 35px;
  margin-top: -17px;
}
.item-last .filter-form .item label {
  padding-left: 10px;
  background: none;
}
.filter-form .item.item-last label {
  padding-left: 10px;
}
.filter-form .item.item-last label:before {
  content: none;
}
.filter-form .item.britanika-news label {
  padding-left: 35px;
}
.filter-form .item.britanika-news label:before {
  background-position: 0 -42px;
}
.filter-form .item.britanika-news input:checked + span + label:before {
  background-position: 0 0;
}
.filter-form .item.hypoteka label:before {
  background-position: -84px -42px;
}
.filter-form .item.hypoteka input:checked + span + label:before {
  background-position: -83px 0;
}
.filter-form .item.investice label:before {
  background-position: -126px -42px;
}
.filter-form .item.investice input:checked + span + label:before {
  background-position: -126px 0;
}
.filter-form .item.pojisteni label:before {
  background-position: -40px -41px;
}
.filter-form .item input:checked + span + label:before {
  background-position: -39px 0;
}
.filter-form .filter-form_left {
  padding-right: 330px;
}
.filter-form .filter-form_right {
  position: absolute;
  padding-left: 70px;
  border-left: 1px solid #ccc;
  top: 0;
  right: 0;
}
#extensiveSearch {
  padding: 35px 0 30px 0;
  margin-top: 25px;
  border-top: 3px solid #FFAD2F;
  *zoom: 1;
}
#extensiveSearch:before,
#extensiveSearch:after {
  display: table;
  content: "";
}
#extensiveSearch:after {
  clear: both;
}
#extensiveSearch select {
  margin-left: 7px;
  margin-right: 40px;
  min-width: 110px;
  max-width: 150px;
}
#extensiveSearch .filterForm.halfBox {
  width: 80%;
}
#extensiveSearch .filterForm.halfBox label {
  font-size: 1.4em;
  color: #00376f;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
#extensiveSearch .searchButton.halfBox {
  width: 20%;
}
#extensiveSearch .searchButton.halfBox .link.active {
  background: #00376f;
}
#extensiveSearch .searchButton.halfBox .link.active:hover {
  background: #283164;
}
.filterState {
  margin: 0 0 25px 0;
  float: left;
}
#listingfilter-welcome .filterState {
  margin-top: 0;
}
.toggleButtons {
  margin-bottom: 25px;
  position: relative;
  float: right;
}
@media only screen and (max-width: 1100px) {
  .toggleButtons {
    float: none;
    margin-bottom: 20px;
  }
}
.toggleButtons p {
  margin-bottom: 0;
}
#listingfilter-contracts .toggleButtons {
  top: 3px;
}
.searchButton {
  text-align: right;
}
.toggleRightArrow {
  position: relative;
}
.toggleRightArrow.active:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  margin-right: -7px;
  bottom: -7px;
  left: 50%;
  z-index: 1;
  border-style: solid;
  border-width: 7px 7px 0;
  border-color: #00376f transparent transparent;
}
table.dataTable th > .dijitTextBox,
table.dataTable th > .dijitComboBox,
table.dataTable th > .dijitSpinner,
table.dataTable th > .dijitTextArea,
table.dataTable th > select,
table.dataTable th > input {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.filterSelectedItems {
  display: none;
}
@media only screen and (max-width: 1100px) {
  .filter-form .filter-form_left {
    padding-right: 275px;
  }
  .filter-form .item:not(.item-last) {
    width: 49.5%;
    padding-right: 5px;
  }
  .filter-form .filter-form_right {
    padding-left: 30px;
  }
  .filterState {
    float: none;
    width: 100%;
    margin-top: 25px;
  }
}
@media only screen and (max-width: 1024px) {
  .toggleButtons {
    float: none;
    width: 100%;
    text-align: left;
    position: static;
  }
}
@media only screen and (max-width: 800px) {
  .toggleButtons,
  .searchButton {
    text-align: left;
  }
  a#toggleFilters {
    display: none;
  }
  #listingfilter-welcome .filterState {
    margin-top: 0;
  }
  /* Filtered Search info*/
  #extensiveSearch {
    display: none !important;
  }
  .filterRow {
    display: none !important;
  }
  .filterSelectedItems {
    padding: 15px;
    margin-top: 15px;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    display: block;
    *zoom: 1;
  }
  .filterSelectedItems:before,
  .filterSelectedItems:after {
    display: table;
    content: "";
  }
  .filterSelectedItems:after {
    clear: both;
  }
  .filterSelectedItems h4 {
    margin-bottom: 5px;
  }
  .filterSelectedItems_resetBtn {
    display: table;
    margin-top: 15px;
    margin-left: 20px;
    position: relative;
  }
  .filterSelectedItems_resetBtn:before {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: -20px;
    width: 14px;
    height: 14px;
    background: url("../images/icon_red-close-cross.png") 0 0 no-repeat;
    background-size: 14px 14px;
  }
  #filterSettingsContent {
    width: auto;
    max-width: initial;
    padding: 15px 20px;
  }
  #exportData {
    width: auto;
    max-width: initial;
    padding: 15px 20px;
  }
  .filter-form .filter-form_right {
    position: static;
    border-left: none;
    padding-left: 0;
  }
  .filter-form .filter-form_left {
    padding-right: 0;
  }
}
@media only screen and (max-width: 520px) {
  .filter-form .item:not(.item-last) {
    width: 100%;
    padding-right: 0;
  }
}
.company-filter {
  display: flex;
  margin-bottom: 25px;
}
.company-filter select {
  visibility: hidden;
}
@media only screen and (max-width: 800px) {
  .company-filter {
    display: block;
  }
}
.company-filter label {
  margin: 9px 7px 9px 0;
  display: inline-block;
  color: #00376f;
  font-size: 1.4em;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300 !important;
}
.company-filter .dijitComboBox {
  width: 410px;
}
@media only screen and (max-width: 800px) {
  .company-filter .dijitComboBox {
    width: 100%;
  }
}
.myClientsBox {
  margin-bottom: 15px;
}
@media only screen and (max-width: 800px) {
  .myClientsBox {
    margin-top: 20px;
  }
}
.myClientsBox label {
  color: #00376f;
  font-size: 1.4em;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300 !important;
}
#footer {
  background: #e9e9e9;
  color: #00376f;
  font-size: 0.875em;
  padding: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
}
@media only screen and (max-width: 800px) {
  #footer {
    margin-top: 0;
  }
}
@media only screen and (max-width: 520px) {
  #footer {
    text-align: center;
    display: block;
  }
}
#footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media only screen and (max-width: 520px) {
  .footer-copy {
    float: none;
    margin: 0 0 20px;
  }
}
.footer-social ul {
  display: flex;
}
@media only screen and (max-width: 520px) {
  .footer-social ul {
    justify-content: center;
  }
}
.footer-social ul li a {
  width: 38px;
  height: 38px;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  border: 2px solid #00376f;
  margin-left: 15px;
}
@media only screen and (max-width: 520px) {
  .footer-social ul li a {
    margin: 0 7px;
  }
}
.footer-social ul li a span {
  display: none;
}
.footer-social ul li a:hover {
  background: #fff;
}
.footer-social ul li a:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  background-image: url("../images/sprite_social-icons.png");
  background-size: 75px 47px;
  background-repeat: no-repeat;
  transition: none;
}
.footer-social ul li.yt a:after {
  background-position: 2px 0;
}
.footer-social ul li.li a:after {
  background-position: -21px -1px;
}
.footer-social ul li.fb a:after {
  background-position: -14px -25px;
}
.footer-social ul li.tw a:after {
  background-position: -48px -1px;
}
.error {
  color: #7d0000;
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
}
fieldset .error {
  display: block;
  margin-bottom: 5px;
}
textarea {
  border: 1px solid #00376f;
  font-family: Tahoma, sans-serif;
  font-size: 1em;
  padding: 2px;
  vertical-align: middle;
}
#main fieldset {
  margin-bottom: 15px;
}
.fCell {
  margin-bottom: 10px;
}
label {
  display: block;
  margin-bottom: 5px;
  outline: none !important;
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
label span {
  display: block;
  float: left;
  width: 100px;
}
label.inlineLabel {
  display: inline;
  white-space: nowrap;
  vertical-align: middle;
}
label.inlineLabel span {
  display: inline;
  float: none;
  width: auto;
}
p.buttons {
  padding-left: 100px;
}
.searchForm .field {
  width: 300px;
}
.searchForm label {
  margin-right: 15px;
}
input {
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  font-size: 1em;
  color: #00376f;
  vertical-align: middle;
  padding: 10px 10px 10px 15px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
}
input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.inputCheckbox.inputCheckbox {
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  vertical-align: middle;
  cursor: pointer;
  margin-top: -1px;
  outline: none;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
}
.inputCheckbox.inputCheckbox:checked {
  background: #FFFFFF url("../images/icon_red-checked.png") center center no-repeat;
}
select {
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  font-size: 1em;
  color: #00376f;
  vertical-align: middle;
  padding: 6px 35px 6px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background: #FFFFFF url("../images/icon_red-caret-down_indent.png") 110% 50% no-repeat;
  background-size: 35px 11px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  width: 100%;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) inset;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
select:hover {
  background-position: 110% 58%;
}
select::-ms-expand {
  display: none;
}
.checkBoxLabel {
  font-size: 1.125em;
  color: #000000;
  vertical-align: middle;
  display: block;
  padding-top: 5px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
.formLabelBig {
  color: #00376f;
  font-size: 1.625em;
  margin-bottom: 20px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  display: table;
}
.formLabelBig .required {
  color: #283164;
  float: right;
  margin-left: 7px;
}
#RCReportClaim_form label[for="form_element_form_foreign"],
#RCCreateClient_form label[for="form_element_form_foreign"],
#RCDispatchNote_form label[for="form_element_form_foreign"] {
  font-size: 1.125em;
  color: #000000;
  vertical-align: middle;
  display: block;
  padding-top: 5px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
#RCReportClaim_form .fCell,
#RCCreateClient_form .fCell,
#RCDispatchNote_form .fCell {
  margin-bottom: 35px;
}
#RCReportClaim_form .fCell.rcDoc,
#RCCreateClient_form .fCell.rcDoc,
#RCDispatchNote_form .fCell.rcDoc {
  border-left: 3px solid #00376f;
  padding: 0 33px 0 30px;
}
#RCReportClaim_form .fCell.rcDoc .closeButton,
#RCCreateClient_form .fCell.rcDoc .closeButton,
#RCDispatchNote_form .fCell.rcDoc .closeButton {
  font-size: 0;
  line-height: 0;
  width: 23px;
  height: 23px;
  display: inline-block;
  padding: 0;
  margin-left: 25px;
  outline: none;
  background: url("../images/icon_close-cross.png") no-repeat;
  background-size: 46px 23px;
  background-position: -23px 0;
  transition: none;
}
#RCReportClaim_form .fCell.rcDoc .closeButton:hover,
#RCCreateClient_form .fCell.rcDoc .closeButton:hover,
#RCDispatchNote_form .fCell.rcDoc .closeButton:hover {
  background-position: 0 0;
}
#RCReportClaim_form .britanikaCustoms-userName,
#RCCreateClient_form .britanikaCustoms-userName,
#RCDispatchNote_form .britanikaCustoms-userName,
#RCReportClaim_form .britanikaCustoms-phone,
#RCCreateClient_form .britanikaCustoms-phone,
#RCDispatchNote_form .britanikaCustoms-phone,
#RCReportClaim_form .britanikaCustoms-mobile,
#RCCreateClient_form .britanikaCustoms-mobile,
#RCDispatchNote_form .britanikaCustoms-mobile,
#RCReportClaim_form .britanikaCustoms-mail,
#RCCreateClient_form .britanikaCustoms-mail,
#RCDispatchNote_form .britanikaCustoms-mail {
  margin-bottom: 8px;
}
#RCReportClaim_form .britanikaCustoms-userName span,
#RCCreateClient_form .britanikaCustoms-userName span,
#RCDispatchNote_form .britanikaCustoms-userName span,
#RCReportClaim_form .britanikaCustoms-phone span,
#RCCreateClient_form .britanikaCustoms-phone span,
#RCDispatchNote_form .britanikaCustoms-phone span,
#RCReportClaim_form .britanikaCustoms-mobile span,
#RCCreateClient_form .britanikaCustoms-mobile span,
#RCDispatchNote_form .britanikaCustoms-mobile span,
#RCReportClaim_form .britanikaCustoms-mail span,
#RCCreateClient_form .britanikaCustoms-mail span,
#RCDispatchNote_form .britanikaCustoms-mail span {
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
#RCReportClaim_form .britanikaCustoms-heading,
#RCCreateClient_form .britanikaCustoms-heading,
#RCDispatchNote_form .britanikaCustoms-heading {
  color: #00376f;
  font-size: 1.625em;
  margin: 50px 0 25px 0;
}
#RCReportClaim_form .britanikaCustoms-heading strong,
#RCCreateClient_form .britanikaCustoms-heading strong,
#RCDispatchNote_form .britanikaCustoms-heading strong {
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
#RCReportClaim_form .britanikaCustoms-mail,
#RCCreateClient_form .britanikaCustoms-mail,
#RCDispatchNote_form .britanikaCustoms-mail {
  margin-bottom: 40px;
}
#RCReportClaim_form .britanikaCustoms-addDoc .button,
#RCCreateClient_form .britanikaCustoms-addDoc .button,
#RCDispatchNote_form .britanikaCustoms-addDoc .button {
  margin-right: 20px;
  margin-bottom: 10px;
}
#RCReportClaim_form .britanikaCustoms-buttons .button,
#RCCreateClient_form .britanikaCustoms-buttons .button,
#RCDispatchNote_form .britanikaCustoms-buttons .button {
  margin-right: 20px;
  margin-bottom: 10px;
}
#RCReportClaim_form .britanikaCustoms-buttons a,
#RCCreateClient_form .britanikaCustoms-buttons a,
#RCDispatchNote_form .britanikaCustoms-buttons a {
  color: #283164;
}
#RCReportClaim_form .britanikaCustoms-buttons a:hover,
#RCCreateClient_form .britanikaCustoms-buttons a:hover,
#RCDispatchNote_form .britanikaCustoms-buttons a:hover {
  color: #99691F;
}
#RCLostUserPassword_form label,
#RCChangeUserPassword_form label {
  color: #00376f;
  font-size: 1.625em;
  margin-bottom: 20px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  display: table;
  font-size: 1.5em;
  margin-bottom: 10px;
}
#RCLostUserPassword_form label .required,
#RCChangeUserPassword_form label .required {
  color: #283164;
  float: right;
  margin-left: 7px;
}
#RCLostUserPassword_form .link,
#RCChangeUserPassword_form .link {
  padding: 0;
  font-size: 1em;
  display: inline;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  background: transparent;
  color: #00376f;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#RCLostUserPassword_form .link:hover,
#RCChangeUserPassword_form .link:hover {
  color: #99691F;
}
#RCLostUserPassword_form label {
  font-size: 1.250em;
}
#recaptcha_widget_div {
  margin-bottom: 25px;
}
#RCChangeUserPassword_form {
  margin: 35px 0 0 0;
}
#RCChangeUserPassword_form .dijitTextBox {
  margin: 5px 0 20px 0;
}
#RCCheckPIN_form {
  margin-top: 50px;
}
#RCCheckPIN_form label[for="personal_identification_number"] {
  color: #00376f;
  font-size: 1.625em;
  margin-bottom: 20px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  display: table;
}
#RCCheckPIN_form label[for="personal_identification_number"] .required {
  color: #283164;
  float: right;
  margin-left: 7px;
}
#RCCheckPIN_form label[for="form_element_form_foreign"] {
  font-size: 1.125em;
  color: #000000;
  vertical-align: middle;
  display: block;
  padding-top: 5px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
#RCCheckPIN_form .dijit.checkbox {
  float: left;
  margin-right: 15px;
  margin-top: 3px;
}
.createClient_form select,
.dispatchNote_form select {
  width: 240px;
  min-width: 40%;
  padding: 13px 18px;
  font-size: 1.25em;
}
@media only screen and (max-width: 800px) {
  #RCCreateClient_form .row .fCell {
    display: block;
  }
}
.fake-label {
  margin-bottom: 15px;
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
.radioCell,
.checkboxCell {
  display: flex;
  margin-bottom: 10px;
}
.radioCell div.dijitRadio,
.checkboxCell div.dijitRadio,
.radioCell div.dijitCheckBox,
.checkboxCell div.dijitCheckBox {
  margin: 0 10px 0 0;
  flex: 0 0 24px;
}
.report-claim-form {
  max-width: 550px;
  margin: 0 auto;
}
.imageGalleryBox {
  background: #EBEBEB;
  float: left;
  margin: 0 10px 10px 0;
  padding-top: 10px;
  text-align: center;
}
#logo {
  margin-left: 30px;
  border: none;
  line-height: 0;
}
@media only screen and (max-width: 800px) {
  #logo {
    position: relative;
    z-index: 500;
    margin-left: 20px;
  }
}
#logo img {
  max-height: 50px;
}
#top {
  margin-top: 35px;
  margin-bottom: 35px;
}
@media only screen and (max-width: 520px) {
  #top {
    margin-bottom: 95px;
  }
}
#top .claim {
  background: url("../images/claim.png") 0 50% no-repeat;
  min-height: 20px;
  min-width: 202px;
  display: none;
}
@media only screen and (max-width: 800px) {
  #top .claim {
    margin: 30px 0;
  }
}
@media only screen and (max-width: 1100px) {
  #top .claim {
    display: none;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loginBox {
  margin: 0 auto;
  min-width: 400px;
}
.user-box {
  margin-right: 30px;
  margin-left: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 800px) {
  .user-box {
    display: block;
    text-align: center;
  }
}
.user-info {
  color: #00376f;
  line-height: normal;
  padding: 0 20px 0 60px;
  background: url("../images/icon_user-avatar.png") 0 50% no-repeat;
  background-size: 46px 46px;
  min-height: 60px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
@media only screen and (max-width: 800px) {
  .user-info {
    text-align: center;
    padding: 60px 0 0 0;
    margin: 0 0 20px 0;
    font-size: 16px;
    background-position: top center;
    display: block;
    min-height: auto;
  }
}
.user-info p {
  margin: 0;
  padding: 0;
}
.user-info span {
  display: block;
}
.logged-user-fullName {
  font-size: 20px;
  line-height: 1em;
  white-space: nowrap;
}
@media only screen and (max-width: 1024px) {
  .logged-user-fullName {
    white-space: normal;
  }
}
.logged-user-company,
.logged-user-brokerName {
  font-size: 24px;
  margin: 0;
  padding: 0;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
.user-buttons {
  flex: 0 0 255px;
  text-align: right;
}
@media only screen and (max-width: 800px) {
  .user-buttons {
    text-align: center;
  }
}
.user-buttons .button {
  vertical-align: middle;
}
.user-buttons.loginForm {
  margin-top: 12px;
}
.user-buttons .logout-btn {
  padding-left: 34px;
  background-image: url("../images/icon_blue-lock_small.png");
  background-position: 15px 50%;
  background-repeat: no-repeat;
  background-size: 14px 14px;
}
.mobileMenuButton,
.mobileUserButton {
  position: absolute;
  top: 45px;
  z-index: 210;
  color: #FFFFFF;
  font-weight: 400;
  padding: 10px 20px 10px 45px;
  display: inline-block;
  text-decoration: none;
  border: none;
  background-color: #00376f;
  border-radius: 3px;
}
@media only screen and (max-width: 520px) {
  .mobileMenuButton,
  .mobileUserButton {
    top: 120px;
  }
}
.mobileMenuButton:before,
.mobileUserButton:before {
  content: '';
  display: block;
  width: 16px;
  height: 21px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
}
.mobileMenuButton:hover,
.mobileUserButton:hover {
  color: #FFAD2F;
  border: none;
}
.mobileMenuButton:focus,
.mobileUserButton:focus,
.mobileMenuButton:active,
.mobileUserButton:active {
  color: white;
}
.mobileMenuButton.active,
.mobileUserButton.active {
  background-color: #FFAD2F;
  color: white;
}
.mobileMenuButton.active:before,
.mobileUserButton.active:before {
  background-position: 0 -42px;
}
.mobileMenuButton {
  right: 20px;
}
.mobileMenuButton:before {
  left: 20px;
  top: 10px;
  background: url("../images/sprite_menu-close-icon.png") 0 0 no-repeat;
}
.mobileUserButton {
  right: 140px;
}
.mobileUserButton:before {
  left: 20px;
  top: 9px;
  background: url("../images/sprite_user-close-icon.png") 0 0 no-repeat;
}
.mobileUserButton.active {
  right: 20px;
  z-index: 220;
}
.mobileUserButton.active:before {
  background-position: 0 -41px;
}
h1 {
  color: #FFAD2F;
  font-size: 1.8em;
  margin-bottom: 20px;
  margin-top: 0;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
h2 {
  color: #FFAD2F;
  font-size: 1.6em;
  margin-top: 25px;
  margin-bottom: 30px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
h3 {
  color: #FFAD2F;
  font-size: 1.4em;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
h4,
h5,
h6 {
  color: #FFAD2F;
  font-size: 1.250em;
  margin: 0;
  padding: 0;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
h1,
h2,
h3,
h4,
h5 {
  line-height: normal;
}
h2.hl-documents {
  margin-top: 35px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 800px) {
  h1,
  h2 {
    font-size: 2.250em;
  }
}
@media only screen and (max-width: 400px) {
  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.clr {
  border: none;
  clear: both;
  height: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  visibility: hidden;
}
.clear {
  clear: both;
}
.none {
  display: none;
}
.bold {
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.nopadding {
  padding: 0;
}
.noindent {
  margin-left: 0;
  padding-left: 0;
}
.nobullet {
  list-style: none;
}
.small {
  font-size: 0.9em;
}
.hide {
  left: 0;
  position: absolute;
  top: -25000px;
}
.red-text {
  color: #283164;
}
.mt15 {
  margin-top: 15px;
}
.fs-13 {
  font-size: 13px;
}
.floatleft {
  float: left;
  margin: 0 10px 10px 0;
}
.floatright {
  float: right;
  margin: 0 0 10px 10px;
}
.rgt {
  float: right;
}
.lft {
  float: left;
}
img.lft {
  margin: 0 15px 7px 0;
}
img.rgt {
  margin: 0 0 7px 15px;
}
.toPrint {
  display: none;
}
.mobileDevice {
  display: none;
}
.tabletOnly {
  display: none;
}
#isTabletWrapper {
  display: none;
}
.middle {
  vertical-align: middle;
}
@media only screen and (max-width: 1024px) {
  .tabletOnly {
    display: block;
  }
  span.tabletOnly {
    display: inline;
  }
  #isTabletWrapper {
    display: block;
  }
}
@media only screen and (max-width: 800px) {
  .tabletOnly,
  span.tabletOnly,
  #isTabletWrapper {
    display: none;
  }
  .desktopDevice {
    display: none;
  }
  .mobileDevice {
    display: block;
  }
}
.highslide-container > table {
  display: none;
}
.highslide-wrapper {
  -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.3);
}
.highslide-wrapper .highslide-move {
  display: none;
}
.highslide-wrapper .highslide-close a {
  background: url("../images/close.gif") no-repeat;
  height: 14px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 14px;
}
.highslide-wrapper .highslide-close span {
  display: none;
}
.highslide-container .closeBtn {
  text-align: right;
}
.highslide-container .closeBtn a {
  width: 23px;
  height: 23px;
  display: inline-block;
  background: url("../images/icon_close-cross.png") no-repeat;
  background-size: 46px 23px;
  background-position: 0 0;
  border: none;
  transition: none;
}
.highslide-container .closeBtn a:hover {
  background-position: -23px 0;
}
.highslide-container .closeBtn a span {
  display: none;
}
.highslide-container div {
  font-family: 'open_sanslight', sans-serif;
  font-size: 1em;
}
.hp-login {
  text-align: center;
  position: relative;
  z-index: 20;
}
.hp-login h1 {
  margin-bottom: 10px;
}
.hp-login h2 {
  font-size: 30px;
  margin: 0 0 10px;
  color: #00376f;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
.no-credentials {
  max-width: 500px;
  margin: 0 auto 5px;
  background: #e9e9e9;
  border-radius: 3px;
  padding: 20px 23px;
}
.no-credentials h3 {
  margin: 0 0 10px;
  color: #00376f;
  font-size: 18px;
}
.no-credentials p {
  margin: 0;
  font-size: 14px;
}
@media only screen and (max-width: 1100px) {
  .homepage-britanika #loginBox {
    float: none;
    margin: 0 auto;
    min-width: 510px;
  }
  .homepage-britanika #loginBox .inputs {
    float: none;
    margin-top: 0;
  }
  .homepage-britanika #loginBox .fCell label {
    margin-right: 0;
  }
  .homepage-britanika #loginBox .buttons {
    float: none;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 800px) {
  .homepage-britanika #top {
    margin-bottom: 30px;
  }
  .homepage-britanika #loginBox {
    display: block;
    position: static;
    box-shadow: none;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: initial;
  }
  .homepage-britanika #loginBox .fCell {
    text-align: center;
  }
  .homepage-britanika #loginBox .inputs {
    margin: 0;
  }
  .homepage-britanika #loginBox .inputs input {
    min-width: 280px !important;
    text-align: center;
    display: block;
    margin: 5px auto;
  }
  .homepage-britanika #loginBox .fCell label {
    margin-right: 0;
    display: block;
    text-align: left;
    width: auto;
  }
  .homepage-britanika #loginBox .buttons {
    text-align: center;
    margin: 0 auto;
    display: block;
    border: none;
  }
  .homepage-britanika #loginBox .buttons.loginForm {
    padding-top: 0;
    margin-bottom: -15px;
  }
  .homepage-britanika #loginBox .buttons input.button {
    display: block;
    float: none;
    margin: 0 auto;
  }
  .homepage-britanika #loginBox .buttons a.button {
    float: none;
    display: inline-block;
    margin: 15px auto 0 auto;
  }
}
.info-bar {
  background: #00376f;
  padding: 1rem;
  color: white;
  margin-top: 2px;
  font-weight: 300;
  font-family: open_sanslight, sans-serif;
}
.info-bar p {
  margin: 0;
}
.info-bar a {
  color: #FFAD2F;
}
.last-sync {
  font-size: 10px;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  position: absolute;
  top: 0;
  right: 30px;
  color: #00376f;
  opacity: .6;
}
@media only screen and (max-width: 800px) {
  .last-sync {
    right: 20px;
  }
}
#page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #page {
    display: block;
  }
}
#main {
  margin-bottom: 15px;
  position: relative;
  flex: 1 1 100%;
  display: flex;
}
@media only screen and (max-width: 1024px) {
  #main {
    display: block;
  }
}
#mainCol {
  flex: 1 1 auto;
}
#leftCol {
  flex: 0 1 190px;
  margin-top: 20px;
}
@media only screen and (max-width: 1024px) {
  #leftCol {
    padding: 0 30px 30px;
    border: 0;
    margin-top: 0;
  }
}
@media only screen and (max-width: 800px) {
  #leftCol {
    padding: 0 20px 20px;
  }
}
#leftCol .leftCol__title {
  font-size: 1em;
}
#leftCol .firstmenu {
  list-style: none;
  border-top: 1px solid #e9e9e9;
  margin: 15px 30px 20px 30px;
  min-width: 190px;
}
#leftCol .firstmenu a {
  color: #00376f;
  display: block;
  padding: 13px 0 15px 15px;
  border-bottom: 1px solid #e9e9e9;
  line-height: 1.3em;
  text-decoration: none;
  font-size: 15px;
  position: relative;
}
#leftCol .firstmenu a::before {
  content: '';
  width: 5px;
  height: 5px;
  border-top: 1px solid #00376f;
  border-right: 1px solid #00376f;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: 20px;
}
#leftCol .firstmenu a.open {
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
#leftCol .firstmenu a:hover {
  color: #99691F;
}
#leftCol .firstmenu ul {
  list-style: none;
}
#leftCol .firstmenu ul a {
  padding-left: 27px;
  font-size: 14px;
}
#leftCol .firstmenu ul a::before {
  position: absolute;
  left: 12px;
  top: 19px;
}
#leftCol .firstmenu ul ul a {
  padding-left: 40px;
}
#leftCol .firstmenu ul ul a::before {
  position: absolute;
  left: 25px;
}
#leftCol .firstmenu li:last-child {
  border: 0;
}
.fullWidth {
  width: 100%;
}
.halfBox {
  float: left;
  margin-bottom: 10px;
  margin-right: 0;
  width: 49.9%;
}
.threeCol {
  float: left;
  width: 33.3%;
}
.with-fullpage-component .inner-container {
  margin: 0 30px;
  position: relative;
}
.with-fullpage-component .footerBreadcrumb,
.with-fullpage-component .pager {
  margin: 40px 30px 0 30px;
}
#content {
  margin: 0 30px !important;
  position: relative;
}
@media only screen and (max-width: 800px) {
  #content {
    margin: 0 20px !important;
  }
}
#content ul {
  margin-left: 15px;
}
#content ul li {
  background: url("../images/listbullet.png") 0 5px no-repeat;
  line-height: 1.5em;
  margin-bottom: 5px;
  padding-left: 15px;
}
#content ol {
  margin-left: 15px;
}
.withoutcol-colum #content {
  margin: 0 30px;
}
#languageSwitcher {
  display: none;
}
#help {
  font-size: .8571em;
  padding: 15px;
}
.britanika_printButton {
  display: none;
}
@media only screen and (max-width: 800px) {
  .halfBox,
  .threeCol {
    float: none;
    width: auto;
  }
  .tabButton {
    width: 130px !important;
  }
}
@media only screen and (max-width: 400px) {
  #languageSwitcher {
    margin-left: 20px !important;
    margin-right: 20px;
  }
  .container {
    margin: 0 -20px !important;
  }
  .tabButton {
    width: 100px !important;
  }
}
#loginBox {
  position: relative;
  *zoom: 1;
}
#loginBox:before,
#loginBox:after {
  display: table;
  content: "";
}
#loginBox:after {
  clear: both;
}
#loginBox strong {
  display: none;
}
#loginBox .fCell {
  float: left;
  margin-right: 10px;
  margin-bottom: 0;
}
#loginBox .fCell input {
  padding: 6px 8px 6px 12px;
}
#loginBox .error {
  padding-top: 10px;
}
#loginBox form label {
  display: block;
  margin-bottom: 3px;
  margin-left: 3px;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
}
#loginBox input[type="password"] {
  width: 100px;
}
#loginBox input[type="text"] {
  width: 100px;
}
#loginBox .buttons p {
  margin: 0;
}
#loginBox .loginForm.buttons {
  margin-top: 26px;
}
.hp-login #loginBox {
  max-width: 500px;
  margin: 0 auto;
}
.hp-login #loginBox input[type="text"],
.hp-login #loginBox input[type="password"] {
  min-width: 330px;
  max-width: 350px;
  padding: 10px 40px 10px 15px;
  margin-left: 15px;
  font-size: 1.25em;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  background-position: 95% 12px;
  background-repeat: no-repeat;
}
.hp-login #loginBox input[type="text"]:-webkit-autofill,
.hp-login #loginBox input[type="password"]:-webkit-autofill {
  background-color: #FFFFFF !important;
}
.hp-login #loginBox input[type="password"] {
  background-image: url("../images/icon_red-lock.png") !important;
  background-size: 20px 24px;
}
.hp-login #loginBox input[type="text"] {
  background-image: url("../images/icon_red-user.png") !important;
  background-size: 19px 23px;
}
.hp-login #loginBox label {
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  color: #00376f;
  font-size: 1.625em;
  display: inline-block;
  vertical-align: middle;
}
.hp-login #loginBox .fCell {
  float: none;
  text-align: right;
  margin: 20px 0;
}
.hp-login #loginBox .buttons {
  float: none;
  text-align: right;
  margin-bottom: 10px;
  overflow: hidden;
}
.hp-login #loginBox .buttons a.button {
  background: transparent;
  color: #00376f;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0;
  margin-right: 25px;
  margin-top: 12px;
  height: auto;
}
.hp-login #loginBox .buttons a.button:hover {
  color: #99691F;
}
.hp-login #loginBox .buttons input.button {
  float: right;
  padding-left: 37px;
  background: #00376f url("../images/icon_white-unlock.png") no-repeat 11px 7px;
  background-size: 16px 20px;
}
@media only screen and (max-width: 800px) {
  #loginBox {
    display: none;
    padding-top: 130px;
    padding-bottom: 50px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: initial;
    z-index: 190;
    background: #FFFFFF;
    -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0 0 25px rgba(0, 0, 0, 0.55);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.55);
  }
  #loginBox .inputs {
    float: none;
    margin: 0 auto 25px auto;
    text-align: center;
    *zoom: 1;
  }
  #loginBox .inputs:before,
  #loginBox .inputs:after {
    display: table;
    content: "";
  }
  #loginBox .inputs:after {
    clear: both;
  }
  #loginBox .fCell {
    float: none;
    margin: 10px 0;
  }
  #loginBox .fCell label {
    width: 50px;
    text-align: right;
    font-size: 1.125em;
    overflow: visible;
  }
  #loginBox .fCell input[type="text"],
  #loginBox .fCell input[type="password"] {
    min-width: 100px;
    max-width: none;
    width: 100%;
    padding: 12px;
    margin-left: 0;
    text-align: left;
  }
  #loginBox .buttons {
    float: none;
    text-align: center;
    padding: 30px 0 0 0;
    margin: 25px auto 0 auto;
    border-top: 1px solid #c7c7c7;
    display: table;
  }
  #loginBox .error {
    right: 0;
  }
}
.main-menu {
  display: flex;
}
@media only screen and (max-width: 800px) {
  .main-menu {
    display: block;
  }
}
.main-menu > div {
  flex: 1 1 100%;
}
#topMenuWrapper {
  background-color: #00376f;
  position: relative;
  z-index: 3;
  padding-right: 20px;
  min-height: 59px;
}
@media only screen and (max-width: 800px) {
  #topMenuWrapper {
    padding-right: 0;
  }
}
#topMenu {
  font-size: 18px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
}
@media only screen and (max-width: 1100px) {
  #topMenu {
    font-size: 16px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 1024px) {
  #topMenu {
    font-size: 14px;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 800px) {
  #topMenu {
    display: block;
    padding-left: 0;
  }
}
#topMenu ul {
  list-style: none;
}
#topMenu .firstmenu {
  display: flex;
}
#topMenu li {
  position: relative;
  flex: 1 1 auto;
}
#topMenu li:hover ul.submenu {
  display: block;
}
#topMenu li a {
  border: none;
  color: #FFFFFF;
  line-height: 1.4em;
  padding: 0 25px;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  text-decoration: none;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}
@media only screen and (max-width: 1100px) {
  #topMenu li a {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 1024px) {
  #topMenu li a {
    padding-left: 10px;
    padding-right: 10px;
  }
}
#topMenu li a:hover {
  color: #FFAD2F;
}
#topMenu li .hit-label {
  padding: 17px 0;
}
#topMenu li a.open {
  color: #00376f;
  background: #FFAD2F;
}
#topMenu li a.open:before,
#topMenu li a.open:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#topMenu li a.open:before {
  left: 0;
}
#topMenu li a.open:after {
  right: 0;
}
#topMenu li.hasSubmenu:hover:before {
  content: '';
  display: block;
  width: 100%;
  height: 7px;
  background: #FFFFFF;
  border-left: 2px solid #00376f;
  border-right: 2px solid #00376f;
  position: absolute;
  z-index: 80;
  bottom: -7px;
  left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#topMenu ul.firstmenu > li {
  float: left;
}
#topMenu ul.submenu {
  display: none;
  margin-top: 5px;
  padding: 20px 30px;
  width: auto;
  min-width: 350px;
  background-color: #FFFFFF;
  border: 2px solid #00376f;
  position: absolute;
  z-index: 70;
}
#topMenu ul.submenu li a {
  padding: 15px 0;
  color: #00376f;
  border-bottom: 1px solid #c7c7c7;
  justify-content: flex-start;
}
#topMenu ul.submenu li a:hover {
  color: #283164;
  font-family: 'open_sanssemibold', sans-serif;
  background: #FFFFFF;
}
#topMenu ul.submenu li a.open {
  background: #FFFFFF;
}
#topMenu ul.submenu li a.open:before,
#topMenu ul.submenu li a.open:after {
  display: none;
}
#topMenu ul.submenu li.active a {
  background: #FFFFFF;
}
#topMenu ul.submenu li.active a.open {
  color: #283164;
}
#topMenu ul.submenu li:last-child a {
  border: none;
}
#topMenu ul.submenu li:hover {
  background: #FFFFFF;
}
#topMenu ul.submenu li:hover:before {
  display: none;
}
#topMenu ul.submenu .hit-label {
  padding: 0;
}
#topMenu .secondBritanikaMenu {
  float: right;
  /**** MEGAMENU ***********************/
}
#topMenu .secondBritanikaMenu .submenu {
  right: 0;
  min-width: 270px;
}
#topMenu .secondBritanikaMenu .submenu:before {
  left: auto;
  right: 0;
}
#topMenu .secondBritanikaMenu.iconMenu {
  /* Hide the tooltip content by default */
  /* Position tooltip above the element */
  /* Triangle hack to make tooltip look like a speech bubble */
  /* Show tooltip content on hover */
}
#topMenu .secondBritanikaMenu.iconMenu li a {
  background-color: transparent;
  text-align: left;
  padding: 59px 0 0 70px;
  width: 32px;
  height: 32px;
}
#topMenu .secondBritanikaMenu.iconMenu li a .hit-label {
  display: none;
}
#topMenu .secondBritanikaMenu.iconMenu li a.open:after {
  background: transparent;
}
#topMenu .secondBritanikaMenu.iconMenu li.item0 a,
#topMenu .secondBritanikaMenu.iconMenu li.item1 a,
#topMenu .secondBritanikaMenu.iconMenu li.item2 a {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
}
#topMenu .secondBritanikaMenu.iconMenu li.item0 a {
  background-image: url("../images/icon_white-settings.png");
}
#topMenu .secondBritanikaMenu.iconMenu li.item1 a {
  background-image: url("../images/icon_white-info.png");
}
#topMenu .secondBritanikaMenu.iconMenu li.item2 a {
  background-image: url("../images/icon_white-rules.png");
}
#topMenu .secondBritanikaMenu.iconMenu li .submenu li {
  margin: 0;
}
#topMenu .secondBritanikaMenu.iconMenu li .submenu li a {
  width: auto;
  height: auto;
  padding: 15px 0;
  background-image: none;
}
#topMenu .secondBritanikaMenu.iconMenu li .submenu li a .hit-label {
  display: block;
}
#topMenu .secondBritanikaMenu.iconMenu li .submenu [data-tooltip]:before,
#topMenu .secondBritanikaMenu.iconMenu li .submenu [data-tooltip]:after {
  display: none;
}
#topMenu .secondBritanikaMenu.iconMenu li.last {
  margin-right: 15px;
}
#topMenu .secondBritanikaMenu.iconMenu li.last [data-tooltip]:before {
  left: auto;
  right: 0;
  top: auto;
  margin-bottom: 10px;
  margin-left: -75px;
}
#topMenu .secondBritanikaMenu.iconMenu [data-tooltip] {
  position: relative;
  z-index: 20;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#topMenu .secondBritanikaMenu.iconMenu [data-tooltip]:before,
#topMenu .secondBritanikaMenu.iconMenu [data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
#topMenu .secondBritanikaMenu.iconMenu [data-tooltip]:before {
  position: absolute;
  bottom: 110%;
  left: 50%;
  right: auto;
  top: auto;
  margin-bottom: 10px;
  margin-left: -75px;
  padding: 5px 15px;
  width: 150px;
  background-color: #00376f;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 0.813em;
  height: auto;
}
#topMenu .secondBritanikaMenu.iconMenu [data-tooltip]:after {
  position: absolute;
  bottom: 110%;
  left: 50%;
  right: auto;
  top: auto;
  margin-left: -10px;
  width: 0;
  height: auto;
  border-top: 10px solid #00376f;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}
#topMenu .secondBritanikaMenu.iconMenu [data-tooltip]:hover:before,
#topMenu .secondBritanikaMenu.iconMenu [data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
#topMenu .secondBritanikaMenu.iconMenu .item0 .submenu,
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu {
  width: 95vw;
  max-width: 1120px;
  right: -140px;
  padding-left: 30px;
  padding-right: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li,
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li {
  float: left;
  width: 25%;
  padding-left: 30px;
  padding-right: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li.hasSubmenu a,
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li.hasSubmenu a {
  border: none;
}
#topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li.hasSubmenu a .hit-label,
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li.hasSubmenu a .hit-label {
  display: inline-block !important;
}
#topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li.hasSubmenu a:after,
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li.hasSubmenu a:after {
  content: '';
  width: 18px;
  height: 10px;
  display: inline-block !important;
  margin: 0 0 0 15px;
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  visibility: visible;
  opacity: 1;
  border: none;
}
#topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li.hasSubmenu li,
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li.hasSubmenu li {
  float: none;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  background: url("../images/icon_red-dot.png") 0 25px no-repeat !important;
  background-size: 5px 5px !important;
}
#topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li.hasSubmenu li a,
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li.hasSubmenu li a {
  margin-left: 20px;
  padding: 15px 0;
  border-bottom: 1px solid #c7c7c7;
  background-image: none !important;
  position: relative;
}
#topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li.hasSubmenu li a:after,
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li.hasSubmenu li a:after {
  display: none !important;
}
#topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li.hasSubmenu li:last-child a,
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li.hasSubmenu li:last-child a {
  border: none;
  padding-right: 0;
}
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu {
  width: auto;
  min-width: 270px;
  padding-left: 0;
  padding-right: 0;
  right: 0;
}
#topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li {
  float: none;
  width: auto;
}
@media only screen and (max-width: 800px) {
  #languageSwitcher {
    display: none;
  }
  .navigation {
    display: none;
  }
  #topMenu {
    font-size: 1.2em;
  }
  #topMenu .firstmenu {
    display: block;
    max-width: none;
  }
  #topMenu ul {
    margin: 0 20px;
  }
  #topMenu li a {
    color: #00376f;
    border-bottom: 1px solid #c7c7c7;
    display: block;
    padding: 11px 0;
    background-color: transparent;
    justify-content: flex-start;
    text-align: left;
  }
  #topMenu li a:hover {
    color: #283164;
    background-color: transparent;
  }
  #topMenu li:hover {
    background-color: transparent;
  }
  #topMenu li a.open {
    background-color: transparent;
    color: #283164;
  }
  #topMenu li a.open:before,
  #topMenu li a.open:after {
    display: none;
  }
  #topMenu li.hasSubmenu:hover:before {
    display: none;
  }
  #topMenu ul.firstmenu > li {
    float: none;
    display: block;
    background-image: url("../images/mobile-menu_bullet.png");
    background-position: right 12px;
    background-repeat: no-repeat;
    background-size: 7px 22px;
  }
  #topMenu ul.submenu {
    display: block;
    min-width: initial;
    margin: -10px 0 0 0;
    padding: 0 0 10px 0;
    background-color: #FFFFFF;
    border: none;
    border-bottom: 1px solid #c7c7c7;
    position: static;
  }
  #topMenu ul.submenu li a {
    padding: 7px 15px;
    border: none;
    font-size: .9em;
  }
  #topMenu ul.submenu li a:hover {
    color: #283164;
    background: #FFFFFF;
  }
  #topMenu ul.submenu:before {
    display: none;
  }
  #topMenu .secondBritanikaMenu {
    float: none;
    /**** MEGAMENU ***********************/
    /**** MEGAMENU END ***********************/
  }
  #topMenu .secondBritanikaMenu.iconMenu li a {
    padding: 17px 0;
    width: auto;
    height: auto;
  }
  #topMenu .secondBritanikaMenu.iconMenu li a .hit-label {
    display: block;
  }
  #topMenu .secondBritanikaMenu.iconMenu li [data-tooltip]:before,
  #topMenu .secondBritanikaMenu.iconMenu li [data-tooltip]:after {
    display: none;
  }
  #topMenu .secondBritanikaMenu.iconMenu li.last {
    margin-right: 0;
  }
  #topMenu .secondBritanikaMenu.iconMenu li .submenu {
    right: auto;
    margin: -10px 0 0 0;
    padding: 0 0 10px 0;
  }
  #topMenu .secondBritanikaMenu.iconMenu li .submenu li {
    margin: 0;
  }
  #topMenu .secondBritanikaMenu.iconMenu li .submenu li a {
    background-color: #FFFFFF;
    padding: 7px 15px;
    background-image: none;
    border: none;
  }
  #topMenu .secondBritanikaMenu.iconMenu li .submenu li a .hit-label {
    display: block;
  }
  #topMenu .secondBritanikaMenu.iconMenu .item0 .submenu,
  #topMenu .secondBritanikaMenu.iconMenu .item2 .submenu {
    width: auto;
    min-width: initial;
    max-width: initial;
    right: auto;
    padding-left: 0;
    padding-right: 0;
  }
  #topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li,
  #topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li {
    float: none;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li.hasSubmenu li,
  #topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li.hasSubmenu li {
    background-position: 0 17px !important;
  }
  #topMenu .secondBritanikaMenu.iconMenu .item0 .submenu li.hasSubmenu li a,
  #topMenu .secondBritanikaMenu.iconMenu .item2 .submenu li.hasSubmenu li a {
    padding: 7px 0;
    border-bottom: none;
  }
  #topMenuWrapper {
    display: none;
    padding-top: 120px;
    padding-bottom: 50px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    background: #FFFFFF;
    -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0 0 25px rgba(0, 0, 0, 0.55);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.55);
  }
}
@media only screen and (max-width: 520px) {
  #topMenuWrapper {
    padding-top: 170px;
  }
}
.reportButton {
  white-space: nowrap;
  font-family: 'open_sanssemibold', sans-serif;
  font-size: 1em;
  text-decoration: none;
  background: #00376f;
  color: #00376f;
  display: flex;
  align-items: center;
  padding: 0 30px 0 0;
}
.on-line-hlaseni-skod .reportButton {
  display: none;
}
@media only screen and (max-width: 800px) {
  .reportButton {
    background: none;
    padding: 0 20px;
    margin-bottom: 30px;
  }
}
.reportButton span {
  display: block;
  background: #FFAD2F;
  border-radius: 3px;
  padding: 10px 20px;
  transition: all 300ms;
}
@media only screen and (max-width: 800px) {
  .reportButton span {
    margin: 0 auto;
    width: 100%;
    max-width: 350px;
    text-align: center;
  }
}
.reportButton:hover span,
.reportButton:active span,
.reportButton:focus span {
  background: #FFAD2F;
  color: #00376f;
}
.news-item {
  padding: 10px 0 30px 0;
  margin: 0 0 55px 50px;
  border-bottom: 1px solid #ccc;
}
.news-item h3 {
  position: relative;
  margin-top: 5px;
}
.news-item h3:before {
  content: "";
  position: absolute;
  left: -50px;
  top: -10px;
  background: url(../images/news_sprite.png) no-repeat;
  width: 37px;
  height: 35px;
}
.news-item.britanika-news h3:before {
  left: -40px;
}
.news-item.hypoteka h3:before {
  background-position: -83px 0;
}
.news-item.investice h3:before {
  background-position: -126px 0;
}
.news-item.pojisteni h3:before {
  background-position: -39px 0;
}
.news-item .date {
  color: #848484;
}
.news-item .date span {
  display: inline-block;
  position: relative;
  padding: 0 0 0 20px;
}
.news-item .date span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
}
.news-item .date span.d1 {
  padding-right: 30px;
}
.news-item .date span.d1:before {
  margin-top: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  border-color: transparent transparent transparent #00386f;
}
.news-item .date span.d2:before {
  width: 10px;
  height: 10px;
  background: #d20536;
  margin-top: -5px;
}
.news-item .file-item {
  display: inline-block;
  vertical-align: top;
  margin: 15px 50px 10px 0;
  padding: 8px 0 15px 42px;
}
@media only screen and (max-width: 520px) {
  .news-item .file-item {
    margin-right: 15px;
    margin-bottom: 5px;
  }
}
.news-item .file-item .file-size {
  color: #848484;
}
.news-item.invalid {
  color: #848484;
}
.news-item.invalid a {
  color: #848484;
}
.news-item.invalid h3 {
  color: #848484;
}
.news-item.invalid.britanika-news h3:before {
  background-position: 0 -43px;
}
.news-item.invalid.hypoteka h3:before {
  background-position: -84px -43px;
}
.news-item.invalid.investice h3:before {
  background-position: -126px -43px;
}
.news-item.invalid.pojisteni h3:before {
  background-position: -40px -42px;
}
.news-item.invalid .date span {
  padding-left: 0;
}
.news-item.invalid .date span:before {
  content: none;
}
.news-item.invalid .date span.d2 {
  display: none;
}
.pagination {
  margin-top: 45px;
  float: left;
  font-size: 1.125em;
  height: 22px;
}
@media only screen and (max-width: 1024px) {
  .pagination {
    float: none;
    text-align: left;
    margin-top: 30px;
  }
}
.pagination a {
  float: left;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1024px) {
  .pagination a {
    float: none;
  }
}
.pagination.linkGroup a {
  padding: 0;
  margin: 0 12px;
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
.pagination.linkGroup a:first-child {
  margin-left: 0;
}
.pagination.linkGroup a.active {
  color: #283164;
}
.itemsPerPageWrapper {
  font-size: 1.225em;
  margin-top: 45px;
  margin-bottom: 15px;
  color: #00376f;
  text-align: right;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
  float: right;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .itemsPerPageWrapper {
    float: none;
    text-align: left;
    margin-top: 0;
  }
}
.itemsPerPageWrapper select {
  font-size: 0.769em;
  width: 90px;
}
#recaptcha_table * {
  height: auto !important;
  width: auto !important;
}
#recaptcha_table input {
  border-color: #dfdfdf !important;
  width: 100% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#recaptcha_table tr:first-child td + td {
  padding-left: 0 !important;
}
#recaptcha_table td + td + td {
  padding: 0 !important;
}
#recaptcha_table tr + tr td:first-child {
  padding: 0 7px !important;
}
#recaptcha_table .recaptcha_image_cell {
  background: none;
}
#recaptcha_table #recaptcha_image img {
  max-width: 100%;
}
#recaptcha_table #recaptcha_response_field {
  font-size: 1em !important;
  padding: 2px !important;
}
#recaptcha_privacy {
  display: none;
}
#recaptcha_logo {
  display: none;
}
#search legend span {
  left: 0;
  position: absolute;
  top: -25000px;
}
#filterSettingsContent {
  display: none;
  background-color: #FFFFFF;
  margin: 0 auto 20px auto;
  max-width: 300px;
  padding: 30px 40px;
  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}
#filterSettingsContent .link {
  margin: 0 auto;
  text-align: center;
  display: block;
  background: #00376f;
}
#filterSettingsContent .link:hover {
  background: #283164;
}
#filterSettingsContent h2 {
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: 10px;
}
ul#settingsContainer {
  margin: 30px 0 25px 0;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #c7c7c7;
}
ul#settingsContainer li {
  background: none;
  margin: 0;
  padding: 7px 60px 7px 25px;
  position: relative;
}
ul#settingsContainer li.hidden label {
  color: #c1c1c1;
  font-family: 'open_sanslight', sans-serif;
  font-weight: 300;
}
ul#settingsContainer li.hidden .state {
  background-position: 0 -13px;
}
ul#settingsContainer li input[type=checkbox] {
  display: none;
}
ul#settingsContainer li label {
  margin: 0;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
}
ul#settingsContainer li .state {
  background: url("../images/icon_red-check.png") 0 0 no-repeat;
  background-size: 12px 13px;
  width: 12px;
  height: 13px;
  position: absolute;
  left: 0;
  top: 10px;
}
ul#settingsContainer .arrowContainer {
  padding-top: 10px;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
}
ul#settingsContainer .arrow {
  background: url("../images/icon_circle-red-blue-caret-up-down.png") no-repeat;
  background-size: 32px 32px;
  float: left;
  height: 16px;
  width: 16px;
}
ul#settingsContainer .arrow + .arrow {
  margin-left: 10px;
}
ul#settingsContainer .arrow.up {
  background-position: 0 -16px;
}
ul#settingsContainer .arrow.up:hover {
  background-position: -16px -16px;
}
ul#settingsContainer .arrow.down {
  background-position: 0 0;
}
ul#settingsContainer .arrow.down:hover {
  background-position: -16px 0;
}
ul#settingsContainer .dojoDndItem {
  border-top: 2px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
}
ul#settingsContainer .dojoDndItem.dojoDndItemAfter {
  border-bottom-color: #00376f;
}
ul#settingsContainer .dojoDndItem.dojoDndItemBefore {
  border-top-color: #00376f;
}
.table {
  display: table;
}
.table > * {
  display: table-cell;
  padding: 5px 5px 5px 0;
}
.table > *:last-child {
  padding-right: 0;
}
th {
  border: 0;
  padding: 5px 10px;
  text-align: left;
  vertical-align: top;
}
td {
  border: 0;
  padding: 5px 10px;
  text-align: left;
  vertical-align: top;
}
.dataTable {
  border-collapse: collapse;
  border-spacing: 0;
  color: #000000;
  font-size: 14px;
  margin-bottom: 15px;
  width: 100%;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
}
.dataTable th {
  border-bottom: 3px solid #FFAD2F;
  padding: 15px 10px 15px 0;
  text-align: left;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.3em;
  vertical-align: bottom;
}
.dataTable td {
  border-bottom: 1px solid #c7c7c7;
  padding: 15px 10px 15px 0;
}
.dataTable td.imageInside {
  padding-left: 45px !important;
  background-position: 5px 8px;
}
.dataTable td.money {
  text-align: right;
  padding-right: 30px;
}
.dataTable td[data-title="Popis škody"] {
  line-height: normal;
  opacity: .7;
  font-style: italic;
}
.dataTable tr:first-child td {
  padding-top: 20px;
}
.dataTable tr:first-child td.imageInside {
  background-position: 5px 23px;
}
.dataTable tr:last-child td {
  padding-bottom: 20px;
  border-bottom: 3px solid #FFAD2F;
}
.dataTable tr:last-child td.imageInside {
  background-position: 5px 8px;
}
.dataTable tr:hover td {
  background: #f2f2f2;
}
.dataTable thead tr.labels {
  word-wrap: break-word;
}
.dataTable thead tr.labels a {
  color: gray;
  background-size: 9px 9px;
  text-decoration: none;
  padding: 0;
  border: none;
}
.dataTable thead tr.labels a.otaznik {
  width: 18px;
  height: 18px;
  padding-right: 0;
  display: inline-block;
  background: url("../images/icon_circle-red-question.png") 0 0 no-repeat;
}
.dataTable thead tr.labels a.otaznik img {
  display: none;
}
.dataTable thead .filterRow input,
.dataTable thead .filterRow select {
  font-size: 0.875em;
  padding: 6px 15px 6px 7px;
  margin: 3px 2px 2px 2px;
}
.dataTable thead .filterRow select {
  background: #FFFFFF url("../images/icon_red-caret-down-indent_small.png") 100% 50% no-repeat;
  background-size: 20px auto;
}
.dataTable thead .filterRow th {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dataTable thead .filterRow.operators th {
  border-bottom: 1px solid #c7c7c7;
}
.dataTable thead .filterRow.operators td {
  text-align: center;
}
.dataTable thead .filterRow.operators select {
  max-width: 60px;
}
.dataTable thead .filterRow.values select[name=operator_commission_paid_date_till] {
  max-width: 60px;
}
.dataTable thead .filterRow.values select[name=operator_maturity_date_till] {
  max-width: 60px;
}
.dataTable tbody tr.red {
  background-color: #ff6666;
}
.dataTable tbody tr.red:hover td {
  background-color: #ff0000;
}
.dataTable tbody td {
  word-wrap: break-word;
}
/**/
table.contactTable {
  border-collapse: collapse;
  border-left: 1px solid #00376f;
  border-spacing: 0;
  border-top: 1px solid #00376f;
  width: 100%;
}
table.contactTable th {
  border-bottom: 1px solid #00376f;
  border-right: 1px solid #00376f;
  word-wrap: break-word;
}
table.contactTable td {
  border-bottom: 1px solid #00376f;
  border-right: 1px solid #00376f;
  word-wrap: break-word;
}
table.contactTable thead th {
  background-color: #dbe7ef;
}
table.contactTable thead td {
  background-color: #dbe7ef;
}
table.contactTable .summary {
  width: 60%;
}
table.contactTable .labels {
  width: 100px;
}
.sortArrow {
  margin: 0 0 3px 0;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
}
/**/
table.fancyTable {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 15px 0;
  border-top: 3px solid #283164;
  border-bottom: 3px solid #283164;
  width: 100%;
  max-width: 800px;
}
table.fancyTable th {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #c7c7c7;
  font-family: 'open_sanssemibold', sans-serif;
  font-weight: 600;
}
table.fancyTable td {
  padding: 15px;
  border-bottom: 1px solid #c7c7c7;
}
table.fancyTable td.imageInside {
  padding-left: 45px !important;
  background-position: 5px 8px;
}
table.fancyTable td.money {
  text-align: right;
  padding-right: 30px;
}
table.fancyTable td,
table.fancyTable th {
  vertical-align: middle;
}
table.fancyTable tr:last-child td {
  border-bottom: none;
}
table.fancyTable .money {
  text-align: right;
}
table.fancyTable .contract-yes-no {
  text-align: center;
}
table.fancyTable .contract-yes-no a {
  border: none;
  display: inline-block;
  vertical-align: middle;
}
table.fancyTable .contract-yes-no a img {
  display: block;
}
.responsiveTable.filter input {
  padding: 12px 10px 12px 15px;
}
.responsiveTable.filter input[type="submit"],
.responsiveTable.filter input[type="button"] {
  padding: 10px 30px;
}
table.britanikaCustoms-clientDetailTable td {
  padding: 5px 35px 5px 0;
}
table.britanikaCustoms-clientDetailTable .fancyTable td {
  padding: 15px;
}
/* tables */
@media only screen and (max-width: 1440px) {
  table.cols20,
  table.cols19,
  table.cols18,
  table.cols17 {
    font-size: .9em;
  }
  table.cols20 .filterRow.values,
  table.cols19 .filterRow.values,
  table.cols18 .filterRow.values,
  table.cols17 .filterRow.values {
    width: 100px;
  }
  table.cols20 thead,
  table.cols19 thead,
  table.cols18 thead,
  table.cols17 thead {
    float: left;
  }
  table.cols20 thead th,
  table.cols19 thead th,
  table.cols18 thead th,
  table.cols17 thead th,
  table.cols20 thead td,
  table.cols19 thead td,
  table.cols18 thead td,
  table.cols17 thead td {
    border-bottom: 1px solid #c7c7c7;
    position: relative;
  }
  table.cols20 thead th:after,
  table.cols19 thead th:after,
  table.cols18 thead th:after,
  table.cols17 thead th:after {
    content: '';
    display: block;
    width: 14px;
    height: 100%;
    padding-bottom: 1px;
    position: absolute;
    right: -17px;
    top: 0;
    z-index: 10;
    background: #FFFFFF;
    border-right: 3px solid #283164;
  }
  table.cols20 tbody,
  table.cols19 tbody,
  table.cols18 tbody,
  table.cols17 tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
    padding-left: 33px;
    padding-bottom: 20px;
  }
  table.cols20 tr,
  table.cols19 tr,
  table.cols18 tr,
  table.cols17 tr {
    display: inline-block;
    vertical-align: top;
  }
  table.cols20 th,
  table.cols19 th,
  table.cols18 th,
  table.cols17 th,
  table.cols20 td,
  table.cols19 td,
  table.cols18 td,
  table.cols17 td {
    display: block;
    line-height: 25px;
    min-height: 25px;
  }
  table.cols20 br,
  table.cols19 br,
  table.cols18 br,
  table.cols17 br {
    display: none;
  }
  table.cols20 span.tabletOnly,
  table.cols19 span.tabletOnly,
  table.cols18 span.tabletOnly,
  table.cols17 span.tabletOnly {
    display: inline;
  }
  table.cols20.dataTable thead tr.filterRow.operators,
  table.cols19.dataTable thead tr.filterRow.operators,
  table.cols18.dataTable thead tr.filterRow.operators,
  table.cols17.dataTable thead tr.filterRow.operators,
  table.cols20.dispatch-client-contracts thead tr.filterRow.operators,
  table.cols19.dispatch-client-contracts thead tr.filterRow.operators,
  table.cols18.dispatch-client-contracts thead tr.filterRow.operators,
  table.cols17.dispatch-client-contracts thead tr.filterRow.operators {
    margin-left: 20px;
  }
  table.cols20.dataTable thead tr.filterRow.operators th,
  table.cols19.dataTable thead tr.filterRow.operators th,
  table.cols18.dataTable thead tr.filterRow.operators th,
  table.cols17.dataTable thead tr.filterRow.operators th,
  table.cols20.dispatch-client-contracts thead tr.filterRow.operators th,
  table.cols19.dispatch-client-contracts thead tr.filterRow.operators th,
  table.cols18.dispatch-client-contracts thead tr.filterRow.operators th,
  table.cols17.dispatch-client-contracts thead tr.filterRow.operators th {
    padding: 15px 0;
    line-height: 25px;
    min-height: 25px;
    display: block;
  }
  table.cols20.dataTable thead tr.filterRow.values,
  table.cols19.dataTable thead tr.filterRow.values,
  table.cols18.dataTable thead tr.filterRow.values,
  table.cols17.dataTable thead tr.filterRow.values,
  table.cols20.dispatch-client-contracts thead tr.filterRow.values,
  table.cols19.dispatch-client-contracts thead tr.filterRow.values,
  table.cols18.dispatch-client-contracts thead tr.filterRow.values,
  table.cols17.dispatch-client-contracts thead tr.filterRow.values {
    margin-left: 0;
  }
  table.cols20.dataTable thead tr.filterRow.values th,
  table.cols19.dataTable thead tr.filterRow.values th,
  table.cols18.dataTable thead tr.filterRow.values th,
  table.cols17.dataTable thead tr.filterRow.values th,
  table.cols20.dispatch-client-contracts thead tr.filterRow.values th,
  table.cols19.dispatch-client-contracts thead tr.filterRow.values th,
  table.cols18.dispatch-client-contracts thead tr.filterRow.values th,
  table.cols17.dispatch-client-contracts thead tr.filterRow.values th {
    padding: 15px 0;
    line-height: 25px;
    min-height: 25px;
    display: block;
  }
  table.cols20.dataTable thead th,
  table.cols19.dataTable thead th,
  table.cols18.dataTable thead th,
  table.cols17.dataTable thead th,
  table.cols20.dispatch-client-contracts thead th,
  table.cols19.dispatch-client-contracts thead th,
  table.cols18.dispatch-client-contracts thead th,
  table.cols17.dispatch-client-contracts thead th {
    border-left: none;
  }
  table.cols20.dataTable thead th select,
  table.cols19.dataTable thead th select,
  table.cols18.dataTable thead th select,
  table.cols17.dataTable thead th select,
  table.cols20.dispatch-client-contracts thead th select,
  table.cols19.dispatch-client-contracts thead th select,
  table.cols18.dispatch-client-contracts thead th select,
  table.cols17.dispatch-client-contracts thead th select,
  table.cols20.dataTable thead th input,
  table.cols19.dataTable thead th input,
  table.cols18.dataTable thead th input,
  table.cols17.dataTable thead th input,
  table.cols20.dispatch-client-contracts thead th input,
  table.cols19.dispatch-client-contracts thead th input,
  table.cols18.dispatch-client-contracts thead th input,
  table.cols17.dispatch-client-contracts thead th input,
  table.cols20.dataTable thead th .dijitComboBox,
  table.cols19.dataTable thead th .dijitComboBox,
  table.cols18.dataTable thead th .dijitComboBox,
  table.cols17.dataTable thead th .dijitComboBox,
  table.cols20.dispatch-client-contracts thead th .dijitComboBox,
  table.cols19.dispatch-client-contracts thead th .dijitComboBox,
  table.cols18.dispatch-client-contracts thead th .dijitComboBox,
  table.cols17.dispatch-client-contracts thead th .dijitComboBox {
    margin: -5px 0;
  }
  table.cols20.dataTable thead th,
  table.cols19.dataTable thead th,
  table.cols18.dataTable thead th,
  table.cols17.dataTable thead th,
  table.cols20.dispatch-client-contracts thead th,
  table.cols19.dispatch-client-contracts thead th,
  table.cols18.dispatch-client-contracts thead th,
  table.cols17.dispatch-client-contracts thead th,
  table.cols20.dataTable thead td,
  table.cols19.dataTable thead td,
  table.cols18.dataTable thead td,
  table.cols17.dataTable thead td,
  table.cols20.dispatch-client-contracts thead td,
  table.cols19.dispatch-client-contracts thead td,
  table.cols18.dispatch-client-contracts thead td,
  table.cols17.dispatch-client-contracts thead td {
    word-wrap: normal;
    /*white-space: nowrap;*/
  }
  table.cols20.dataTable thead .filterRow.operators select + select,
  table.cols19.dataTable thead .filterRow.operators select + select,
  table.cols18.dataTable thead .filterRow.operators select + select,
  table.cols17.dataTable thead .filterRow.operators select + select,
  table.cols20.dispatch-client-contracts thead .filterRow.operators select + select,
  table.cols19.dispatch-client-contracts thead .filterRow.operators select + select,
  table.cols18.dispatch-client-contracts thead .filterRow.operators select + select,
  table.cols17.dispatch-client-contracts thead .filterRow.operators select + select {
    display: inline;
    margin: 0 10px 0 4px;
  }
  table.cols20.dataTable th > .dijitDateTextBox,
  table.cols19.dataTable th > .dijitDateTextBox,
  table.cols18.dataTable th > .dijitDateTextBox,
  table.cols17.dataTable th > .dijitDateTextBox,
  table.cols20.dispatch-client-contracts th > .dijitDateTextBox,
  table.cols19.dispatch-client-contracts th > .dijitDateTextBox,
  table.cols18.dispatch-client-contracts th > .dijitDateTextBox,
  table.cols17.dispatch-client-contracts th > .dijitDateTextBox {
    max-width: 50px;
  }
  table.cols20.dataTable tr:first-child td,
  table.cols19.dataTable tr:first-child td,
  table.cols18.dataTable tr:first-child td,
  table.cols17.dataTable tr:first-child td,
  table.cols20.dispatch-client-contracts tr:first-child td,
  table.cols19.dispatch-client-contracts tr:first-child td,
  table.cols18.dispatch-client-contracts tr:first-child td,
  table.cols17.dispatch-client-contracts tr:first-child td {
    padding-top: 15px;
  }
  table.cols20.dataTable tr:last-child td,
  table.cols19.dataTable tr:last-child td,
  table.cols18.dataTable tr:last-child td,
  table.cols17.dataTable tr:last-child td,
  table.cols20.dispatch-client-contracts tr:last-child td,
  table.cols19.dispatch-client-contracts tr:last-child td,
  table.cols18.dispatch-client-contracts tr:last-child td,
  table.cols17.dispatch-client-contracts tr:last-child td {
    padding-bottom: 15px;
    border-bottom: 1px solid #c7c7c7;
  }
  table.cols20.dataTable .filterRow.values th > .dijitDateTextBox,
  table.cols19.dataTable .filterRow.values th > .dijitDateTextBox,
  table.cols18.dataTable .filterRow.values th > .dijitDateTextBox,
  table.cols17.dataTable .filterRow.values th > .dijitDateTextBox,
  table.cols20.dispatch-client-contracts .filterRow.values th > .dijitDateTextBox,
  table.cols19.dispatch-client-contracts .filterRow.values th > .dijitDateTextBox,
  table.cols18.dispatch-client-contracts .filterRow.values th > .dijitDateTextBox,
  table.cols17.dispatch-client-contracts .filterRow.values th > .dijitDateTextBox {
    max-width: 100%;
  }
  table.cols20.dataTable .filterRow.values th > select + .dijitDateTextBox,
  table.cols19.dataTable .filterRow.values th > select + .dijitDateTextBox,
  table.cols18.dataTable .filterRow.values th > select + .dijitDateTextBox,
  table.cols17.dataTable .filterRow.values th > select + .dijitDateTextBox,
  table.cols20.dispatch-client-contracts .filterRow.values th > select + .dijitDateTextBox,
  table.cols19.dispatch-client-contracts .filterRow.values th > select + .dijitDateTextBox,
  table.cols18.dispatch-client-contracts .filterRow.values th > select + .dijitDateTextBox,
  table.cols17.dispatch-client-contracts .filterRow.values th > select + .dijitDateTextBox {
    max-width: 40px;
  }
  table.cols20.dataTable br,
  table.cols19.dataTable br,
  table.cols18.dataTable br,
  table.cols17.dataTable br,
  table.cols20.dispatch-client-contracts br,
  table.cols19.dispatch-client-contracts br,
  table.cols18.dispatch-client-contracts br,
  table.cols17.dispatch-client-contracts br {
    display: none;
  }
}
@media only screen and (max-width: 1280px) {
  .cols16,
  .cols15,
  .cols14,
  .cols13,
  .cols12,
  .cols11,
  .cols10 {
    font-size: .9em;
  }
  .cols16 .filterRow.values,
  .cols15 .filterRow.values,
  .cols14 .filterRow.values,
  .cols13 .filterRow.values,
  .cols12 .filterRow.values,
  .cols11 .filterRow.values,
  .cols10 .filterRow.values {
    width: 100px;
  }
  .cols16 thead,
  .cols15 thead,
  .cols14 thead,
  .cols13 thead,
  .cols12 thead,
  .cols11 thead,
  .cols10 thead {
    float: left;
  }
  .cols16 thead th,
  .cols15 thead th,
  .cols14 thead th,
  .cols13 thead th,
  .cols12 thead th,
  .cols11 thead th,
  .cols10 thead th,
  .cols16 thead td,
  .cols15 thead td,
  .cols14 thead td,
  .cols13 thead td,
  .cols12 thead td,
  .cols11 thead td,
  .cols10 thead td {
    border-bottom: 1px solid #c7c7c7;
    position: relative;
  }
  .cols16 thead th:after,
  .cols15 thead th:after,
  .cols14 thead th:after,
  .cols13 thead th:after,
  .cols12 thead th:after,
  .cols11 thead th:after,
  .cols10 thead th:after {
    content: '';
    display: block;
    width: 14px;
    height: 100%;
    padding-bottom: 1px;
    position: absolute;
    right: -17px;
    top: 0;
    z-index: 10;
    background: #FFFFFF;
    border-right: 3px solid #283164;
  }
  .cols16 tbody,
  .cols15 tbody,
  .cols14 tbody,
  .cols13 tbody,
  .cols12 tbody,
  .cols11 tbody,
  .cols10 tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
    padding-left: 33px;
    padding-bottom: 20px;
  }
  .cols16 tr,
  .cols15 tr,
  .cols14 tr,
  .cols13 tr,
  .cols12 tr,
  .cols11 tr,
  .cols10 tr {
    display: inline-block;
    vertical-align: top;
  }
  .cols16 th,
  .cols15 th,
  .cols14 th,
  .cols13 th,
  .cols12 th,
  .cols11 th,
  .cols10 th,
  .cols16 td,
  .cols15 td,
  .cols14 td,
  .cols13 td,
  .cols12 td,
  .cols11 td,
  .cols10 td {
    display: block;
    line-height: 25px;
    min-height: 25px;
  }
  .cols16 span.tabletOnly,
  .cols15 span.tabletOnly,
  .cols14 span.tabletOnly,
  .cols13 span.tabletOnly,
  .cols12 span.tabletOnly,
  .cols11 span.tabletOnly,
  .cols10 span.tabletOnly {
    display: inline;
  }
  .cols16 br,
  .cols15 br,
  .cols14 br,
  .cols13 br,
  .cols12 br,
  .cols11 br,
  .cols10 br {
    display: none;
  }
  .cols16.dataTable thead tr.filterRow.operators,
  .cols15.dataTable thead tr.filterRow.operators,
  .cols14.dataTable thead tr.filterRow.operators,
  .cols13.dataTable thead tr.filterRow.operators,
  .cols12.dataTable thead tr.filterRow.operators,
  .cols11.dataTable thead tr.filterRow.operators,
  .cols10.dataTable thead tr.filterRow.operators,
  .cols16.dispatch-client-contracts thead tr.filterRow.operators,
  .cols15.dispatch-client-contracts thead tr.filterRow.operators,
  .cols14.dispatch-client-contracts thead tr.filterRow.operators,
  .cols13.dispatch-client-contracts thead tr.filterRow.operators,
  .cols12.dispatch-client-contracts thead tr.filterRow.operators,
  .cols11.dispatch-client-contracts thead tr.filterRow.operators,
  .cols10.dispatch-client-contracts thead tr.filterRow.operators {
    margin-left: 20px;
  }
  .cols16.dataTable thead tr.filterRow.operators th,
  .cols15.dataTable thead tr.filterRow.operators th,
  .cols14.dataTable thead tr.filterRow.operators th,
  .cols13.dataTable thead tr.filterRow.operators th,
  .cols12.dataTable thead tr.filterRow.operators th,
  .cols11.dataTable thead tr.filterRow.operators th,
  .cols10.dataTable thead tr.filterRow.operators th,
  .cols16.dispatch-client-contracts thead tr.filterRow.operators th,
  .cols15.dispatch-client-contracts thead tr.filterRow.operators th,
  .cols14.dispatch-client-contracts thead tr.filterRow.operators th,
  .cols13.dispatch-client-contracts thead tr.filterRow.operators th,
  .cols12.dispatch-client-contracts thead tr.filterRow.operators th,
  .cols11.dispatch-client-contracts thead tr.filterRow.operators th,
  .cols10.dispatch-client-contracts thead tr.filterRow.operators th {
    padding: 15px 0;
    line-height: 25px;
    min-height: 25px;
    display: block;
  }
  .cols16.dataTable thead tr.filterRow.values,
  .cols15.dataTable thead tr.filterRow.values,
  .cols14.dataTable thead tr.filterRow.values,
  .cols13.dataTable thead tr.filterRow.values,
  .cols12.dataTable thead tr.filterRow.values,
  .cols11.dataTable thead tr.filterRow.values,
  .cols10.dataTable thead tr.filterRow.values,
  .cols16.dispatch-client-contracts thead tr.filterRow.values,
  .cols15.dispatch-client-contracts thead tr.filterRow.values,
  .cols14.dispatch-client-contracts thead tr.filterRow.values,
  .cols13.dispatch-client-contracts thead tr.filterRow.values,
  .cols12.dispatch-client-contracts thead tr.filterRow.values,
  .cols11.dispatch-client-contracts thead tr.filterRow.values,
  .cols10.dispatch-client-contracts thead tr.filterRow.values {
    margin-left: 0;
  }
  .cols16.dataTable thead tr.filterRow.values th,
  .cols15.dataTable thead tr.filterRow.values th,
  .cols14.dataTable thead tr.filterRow.values th,
  .cols13.dataTable thead tr.filterRow.values th,
  .cols12.dataTable thead tr.filterRow.values th,
  .cols11.dataTable thead tr.filterRow.values th,
  .cols10.dataTable thead tr.filterRow.values th,
  .cols16.dispatch-client-contracts thead tr.filterRow.values th,
  .cols15.dispatch-client-contracts thead tr.filterRow.values th,
  .cols14.dispatch-client-contracts thead tr.filterRow.values th,
  .cols13.dispatch-client-contracts thead tr.filterRow.values th,
  .cols12.dispatch-client-contracts thead tr.filterRow.values th,
  .cols11.dispatch-client-contracts thead tr.filterRow.values th,
  .cols10.dispatch-client-contracts thead tr.filterRow.values th {
    padding: 15px 0;
    line-height: 25px;
    min-height: 25px;
    display: block;
  }
  .cols16.dataTable thead th,
  .cols15.dataTable thead th,
  .cols14.dataTable thead th,
  .cols13.dataTable thead th,
  .cols12.dataTable thead th,
  .cols11.dataTable thead th,
  .cols10.dataTable thead th,
  .cols16.dispatch-client-contracts thead th,
  .cols15.dispatch-client-contracts thead th,
  .cols14.dispatch-client-contracts thead th,
  .cols13.dispatch-client-contracts thead th,
  .cols12.dispatch-client-contracts thead th,
  .cols11.dispatch-client-contracts thead th,
  .cols10.dispatch-client-contracts thead th {
    border-left: none;
  }
  .cols16.dataTable thead th select,
  .cols15.dataTable thead th select,
  .cols14.dataTable thead th select,
  .cols13.dataTable thead th select,
  .cols12.dataTable thead th select,
  .cols11.dataTable thead th select,
  .cols10.dataTable thead th select,
  .cols16.dispatch-client-contracts thead th select,
  .cols15.dispatch-client-contracts thead th select,
  .cols14.dispatch-client-contracts thead th select,
  .cols13.dispatch-client-contracts thead th select,
  .cols12.dispatch-client-contracts thead th select,
  .cols11.dispatch-client-contracts thead th select,
  .cols10.dispatch-client-contracts thead th select,
  .cols16.dataTable thead th input,
  .cols15.dataTable thead th input,
  .cols14.dataTable thead th input,
  .cols13.dataTable thead th input,
  .cols12.dataTable thead th input,
  .cols11.dataTable thead th input,
  .cols10.dataTable thead th input,
  .cols16.dispatch-client-contracts thead th input,
  .cols15.dispatch-client-contracts thead th input,
  .cols14.dispatch-client-contracts thead th input,
  .cols13.dispatch-client-contracts thead th input,
  .cols12.dispatch-client-contracts thead th input,
  .cols11.dispatch-client-contracts thead th input,
  .cols10.dispatch-client-contracts thead th input,
  .cols16.dataTable thead th .dijitComboBox,
  .cols15.dataTable thead th .dijitComboBox,
  .cols14.dataTable thead th .dijitComboBox,
  .cols13.dataTable thead th .dijitComboBox,
  .cols12.dataTable thead th .dijitComboBox,
  .cols11.dataTable thead th .dijitComboBox,
  .cols10.dataTable thead th .dijitComboBox,
  .cols16.dispatch-client-contracts thead th .dijitComboBox,
  .cols15.dispatch-client-contracts thead th .dijitComboBox,
  .cols14.dispatch-client-contracts thead th .dijitComboBox,
  .cols13.dispatch-client-contracts thead th .dijitComboBox,
  .cols12.dispatch-client-contracts thead th .dijitComboBox,
  .cols11.dispatch-client-contracts thead th .dijitComboBox,
  .cols10.dispatch-client-contracts thead th .dijitComboBox {
    margin: -5px 0;
  }
  .cols16.dataTable tr:first-child td,
  .cols15.dataTable tr:first-child td,
  .cols14.dataTable tr:first-child td,
  .cols13.dataTable tr:first-child td,
  .cols12.dataTable tr:first-child td,
  .cols11.dataTable tr:first-child td,
  .cols10.dataTable tr:first-child td,
  .cols16.dispatch-client-contracts tr:first-child td,
  .cols15.dispatch-client-contracts tr:first-child td,
  .cols14.dispatch-client-contracts tr:first-child td,
  .cols13.dispatch-client-contracts tr:first-child td,
  .cols12.dispatch-client-contracts tr:first-child td,
  .cols11.dispatch-client-contracts tr:first-child td,
  .cols10.dispatch-client-contracts tr:first-child td {
    padding-top: 15px;
  }
  .cols16.dataTable tr:last-child td,
  .cols15.dataTable tr:last-child td,
  .cols14.dataTable tr:last-child td,
  .cols13.dataTable tr:last-child td,
  .cols12.dataTable tr:last-child td,
  .cols11.dataTable tr:last-child td,
  .cols10.dataTable tr:last-child td,
  .cols16.dispatch-client-contracts tr:last-child td,
  .cols15.dispatch-client-contracts tr:last-child td,
  .cols14.dispatch-client-contracts tr:last-child td,
  .cols13.dispatch-client-contracts tr:last-child td,
  .cols12.dispatch-client-contracts tr:last-child td,
  .cols11.dispatch-client-contracts tr:last-child td,
  .cols10.dispatch-client-contracts tr:last-child td {
    padding-bottom: 15px;
    border-bottom: 1px solid #c7c7c7;
  }
  .dataTable thead th,
  .dispatch-client-contracts thead th,
  .dataTable thead td,
  .dispatch-client-contracts thead td {
    word-wrap: normal;
    /*white-space: nowrap;*/
  }
  .dataTable thead .filterRow.operators select + select,
  .dispatch-client-contracts thead .filterRow.operators select + select {
    display: inline;
    margin: 0 10px 0 4px;
  }
  .dataTable thead .filterRow.operators th:after,
  .dispatch-client-contracts thead .filterRow.operators th:after {
    display: none;
  }
  .dataTable th > .dijitDateTextBox,
  .dispatch-client-contracts th > .dijitDateTextBox {
    max-width: 50px;
  }
  .dataTable .inline-buttons,
  .dispatch-client-contracts .inline-buttons {
    width: auto;
  }
  .dataTable .filterRow.values th > .dijitDateTextBox,
  .dispatch-client-contracts .filterRow.values th > .dijitDateTextBox {
    max-width: 100%;
  }
  .dataTable .filterRow.values th > select + .dijitDateTextBox,
  .dispatch-client-contracts .filterRow.values th > select + .dijitDateTextBox {
    max-width: 40px;
  }
  .dataTable br,
  .dispatch-client-contracts br {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  /**/
  .dataTable,
  .dispatch-client-contracts {
    font-size: .9em;
    margin-top: 25px;
  }
  .dataTable thead,
  .dispatch-client-contracts thead {
    float: left;
    white-space: nowrap;
  }
  .dataTable thead tr.filterRow.operators,
  .dispatch-client-contracts thead tr.filterRow.operators {
    margin-left: 20px;
  }
  .dataTable thead tr.filterRow.operators th,
  .dispatch-client-contracts thead tr.filterRow.operators th {
    padding: 15px 0;
    line-height: 25px;
    min-height: 25px;
    display: block;
  }
  .dataTable thead tr.filterRow.operators select,
  .dispatch-client-contracts thead tr.filterRow.operators select,
  .dataTable thead tr.filterRow.operators input,
  .dispatch-client-contracts thead tr.filterRow.operators input,
  .dataTable thead tr.filterRow.operators .dijitComboBox,
  .dispatch-client-contracts thead tr.filterRow.operators .dijitComboBox {
    margin: -5px 0;
  }
  .dataTable thead tr.filterRow.values,
  .dispatch-client-contracts thead tr.filterRow.values {
    margin-left: 0;
  }
  .dataTable thead tr.filterRow.values th,
  .dispatch-client-contracts thead tr.filterRow.values th {
    padding: 15px 0;
    line-height: 25px;
    min-height: 25px;
    display: block;
  }
  .dataTable thead tr.filterRow.values select,
  .dispatch-client-contracts thead tr.filterRow.values select,
  .dataTable thead tr.filterRow.values input,
  .dispatch-client-contracts thead tr.filterRow.values input {
    margin: -5px 0;
  }
  .dataTable thead tr.filterRow.values .dijitComboBox,
  .dispatch-client-contracts thead tr.filterRow.values .dijitComboBox {
    margin: -5px 0;
    height: 30px;
  }
  .dataTable thead th,
  .dispatch-client-contracts thead th,
  .dataTable thead td,
  .dispatch-client-contracts thead td {
    border-bottom: 1px solid #c7c7c7;
    position: relative;
  }
  .dataTable thead th:after,
  .dispatch-client-contracts thead th:after {
    content: '';
    display: block;
    width: 14px;
    height: 100%;
    padding-bottom: 1px;
    position: absolute;
    right: -17px;
    top: 0;
    z-index: 10;
    background: #FFFFFF;
    border-right: 3px solid #283164;
  }
  .dataTable thead tr.labels,
  .dispatch-client-contracts thead tr.labels {
    word-wrap: normal;
  }
  .dataTable tbody,
  .dispatch-client-contracts tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    padding-left: 33px;
    padding-bottom: 20px;
  }
  .dataTable tr,
  .dispatch-client-contracts tr {
    display: inline-block;
    vertical-align: top;
  }
  .dataTable tr:first-child td,
  .dispatch-client-contracts tr:first-child td {
    padding-top: 15px;
  }
  .dataTable tr:first-child td.imageInside,
  .dispatch-client-contracts tr:first-child td.imageInside {
    background-position: 0px 8px;
  }
  .dataTable tr:last-child td,
  .dispatch-client-contracts tr:last-child td {
    padding-bottom: 15px;
    border-bottom: 1px solid #c7c7c7;
  }
  .dataTable th,
  .dispatch-client-contracts th,
  .dataTable td,
  .dispatch-client-contracts td {
    display: block;
    width: auto;
    line-height: 25px;
    min-height: 25px;
  }
  .dataTable td.imageInside,
  .dispatch-client-contracts td.imageInside {
    padding-left: 40px !important;
    background-position: 0px 8px;
  }
  .dataTable .filterRow.values,
  .dispatch-client-contracts .filterRow.values {
    width: 100px;
  }
}
@media only screen and (max-width: 800px) {
  table.dataTable,
  table.dispatch-client-contracts {
    display: block;
    border: none;
    float: none;
    overflow: auto;
    /**/
  }
  table.dataTable th > .dijitDateTextBox,
  table.dispatch-client-contracts th > .dijitDateTextBox {
    min-width: 50px;
  }
  table.dataTable thead,
  table.dispatch-client-contracts thead,
  table.dataTable tbody,
  table.dispatch-client-contracts tbody,
  table.dataTable tr,
  table.dispatch-client-contracts tr,
  table.dataTable th,
  table.dispatch-client-contracts th,
  table.dataTable td,
  table.dispatch-client-contracts td {
    display: block;
    border: none;
    float: none;
    overflow: auto;
  }
  table.dataTable tr,
  table.dispatch-client-contracts tr {
    border-top: 3px solid #283164;
    margin: 0;
  }
  table.dataTable tr:hover td,
  table.dispatch-client-contracts tr:hover td {
    background: none;
  }
  table.dataTable td,
  table.dispatch-client-contracts td {
    padding: 10px;
    position: relative;
    padding-left: 60%;
    text-align: right;
    white-space: normal;
    border-bottom: 1px solid #c7c7c7;
    min-height: 41px;
    line-height: 1.4em;
  }
  table.dataTable td:before,
  table.dispatch-client-contracts td:before {
    padding: 10px;
    content: attr(data-title);
    position: absolute;
    left: 0;
    top: 0;
    font-weight: bold;
  }
  table.dataTable td.imageInside,
  table.dispatch-client-contracts td.imageInside {
    position: relative;
    padding-left: 60%;
    text-align: right;
    white-space: normal;
    background-position: -100%;
    background-image: none !important;
  }
  table.dataTable td.inline-buttons,
  table.dispatch-client-contracts td.inline-buttons {
    padding-left: 0;
  }
  table.dataTable td[data-title="Popis škody"],
  table.dispatch-client-contracts td[data-title="Popis škody"],
  table.dataTable td[data-title="Pojistitel"],
  table.dispatch-client-contracts td[data-title="Pojistitel"] {
    font-size: 1em;
    line-height: normal;
  }
  table.dataTable br,
  table.dispatch-client-contracts br {
    display: block;
  }
  table.dataTable tbody,
  table.dispatch-client-contracts tbody {
    padding-left: 0;
  }
  table.dataTable thead,
  table.dispatch-client-contracts thead {
    font-size: .8em;
    margin-bottom: 5px;
    position: relative;
  }
  table.dataTable thead tr,
  table.dispatch-client-contracts thead tr,
  table.dataTable thead tr + tr,
  table.dispatch-client-contracts thead tr + tr {
    border: none;
  }
  table.dataTable thead tr,
  table.dispatch-client-contracts thead tr {
    /*	position: absolute; top: -9999px; left: -9999px;*/
    display: inline-block;
  }
  table.dataTable thead th,
  table.dispatch-client-contracts thead th,
  table.dataTable thead td,
  table.dispatch-client-contracts thead td {
    line-height: 30px;
    min-height: 30px;
    border-bottom: 1px solid #c7c7c7;
  }
  table.dataTable thead .labels,
  table.dispatch-client-contracts thead .labels {
    display: none;
  }
  table.dataTable thead .filterRow.operators,
  table.dispatch-client-contracts thead .filterRow.operators {
    /*width: 69.9%;*/
    display: block;
    padding-right: 150px;
  }
  table.dataTable thead .filterRow.operators th,
  table.dispatch-client-contracts thead .filterRow.operators th {
    position: relative;
    /*padding-left: 70%;*/
    text-align: right;
  }
  table.dataTable thead .filterRow.operators th:before,
  table.dispatch-client-contracts thead .filterRow.operators th:before {
    content: attr(data-title);
    position: absolute;
    left: 4px;
  }
  table.dataTable thead .filterRow.operators select + select,
  table.dispatch-client-contracts thead .filterRow.operators select + select {
    margin: 0 0 0 1px;
  }
  table.dataTable thead .filterRow.values,
  table.dispatch-client-contracts thead .filterRow.values {
    /*width: 29.9%;*/
    width: 150px;
    position: absolute;
    top: 0;
    right: 0;
  }
  table.responsiveTable,
  .documentText table {
    display: block;
  }
  table.responsiveTable thead,
  .documentText table thead,
  table.responsiveTable tbody,
  .documentText table tbody {
    display: inline-block;
    max-width: 49.9%;
  }
  table.responsiveTable th,
  .documentText table th,
  table.responsiveTable td,
  .documentText table td {
    display: block;
    min-height: 2.5em;
    line-height: 2em;
    white-space: nowrap;
  }
  table.simpleResponsive {
    display: block;
  }
  table.simpleResponsive thead,
  table.simpleResponsive tbody,
  table.simpleResponsive tr,
  table.simpleResponsive th,
  table.simpleResponsive td {
    display: block;
  }
  table.contactTable {
    display: block;
  }
  table.contactTable thead,
  table.contactTable tbody,
  table.contactTable tr,
  table.contactTable th,
  table.contactTable td,
  table.contactTable .summary,
  table.contactTable .labels {
    display: block;
    width: auto;
  }
  table.fancyTable td.britanikaCustoms-insurerWebsite {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  table.britanikaCustoms-clientDetailTable {
    border-collapse: collapse;
    border-spacing: none;
    width: 100%;
  }
  table.britanikaCustoms-clientDetailTable td {
    float: left;
    width: 100%;
    padding: 5px 0;
  }
  table.britanikaCustoms-clientDetailTable .fancyTable td {
    float: none;
    width: auto;
    padding: 15px;
  }
  .dataTable,
  .dispatch-client-contracts {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 520px) {
  table.fancyTable .contract-yes-no img {
    width: 19px;
  }
}
.filterRow {
  background: #f3f5f7;
}
#welcomeText {
  border: 1px solid #003777;
  display: none;
  margin-bottom: 22px;
  padding: 26px 28px 14px 28px;
  position: relative;
}
#welcomeCross {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  vertical-align: top;
}
#welcomeCross a {
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  text-decoration: underline;
}
#welcomeCross img {
  margin-left: 10px;
  margin-top: 2px;
  vertical-align: top;
}
* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  background-color: #FFFFFF;
  color: #000000;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.4em;
  margin: 0;
  padding: 0;
  height: 100%;
}
@media only screen and (max-width: 800px) {
  body {
    font-size: .9em;
  }
}
