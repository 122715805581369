#recaptcha_table {
    * {
        height: auto !important;
        width: auto !important;
    }

    input {
        border-color: #dfdfdf !important;
        width: 100% !important;
        .border-box;
    }

    tr {
        &:first-child {
            td + td {
                padding-left: 0 !important;
            }
        }
    }

    td + td + td {
        padding: 0 !important;
    }

    tr + tr {
        td {
            &:first-child {
                padding: 0 7px !important;
            }
        }
    }

    .recaptcha_image_cell {
        background: none;
    }

    #recaptcha_image {
        img {
            max-width: 100%;
        }
    }

    #recaptcha_response_field {
        font-size: 1em !important;
        padding: 2px !important;
    }
}

#recaptcha_privacy {
    display: none;
}

#recaptcha_logo {
    display: none;
}
