
#loginBox {
    position: relative;

    .clearfix();

    strong {
        display: none;
    }

    .fCell {
        float: left;
        margin-right: 10px;
        margin-bottom: 0;

        input {
            padding: 6px 8px 6px 12px;
        }
    }

    .error {
        // white-space: nowrap;
        padding-top: 10px;
    }

    form {
        label {
            display: block;
            margin-bottom: 3px;
            margin-left: 3px;
            .font-regular;
        }
    }

    input[type="password"] {
        width: 100px;
    }

    input[type="text"] {
        width: 100px;
    }

    .buttons p{
        margin: 0;
    }

    .loginForm.buttons {
        margin-top: 26px;
    }
}


.hp-login {

    #loginBox {
        max-width: 500px;
        margin: 0 auto;

        input[type="text"],
        input[type="password"] {
            min-width: 330px;
            max-width: 350px;
            padding: 10px 40px 10px 15px;
            margin-left: 15px;
            font-size: 1.25em;
            position: relative;
            z-index: 10;
            box-sizing: border-box;
            background-position: 95% 12px;
            background-repeat: no-repeat;

            &:-webkit-autofill {
                background-color: @white !important;
            }
        }

        input[type="password"] {
            background-image: url("@{images}/icon_red-lock.png") !important;
            background-size: 20px 24px;
        }

        input[type="text"] {
            background-image: url("@{images}/icon_red-user.png") !important;
            background-size: 19px 23px;
        }

        label {
            .font-light;
            color: @blue;
            font-size: 1.625em;
            display: inline-block;
            vertical-align: middle;
        }

        .fCell {
            float: none;
            text-align: right;
            margin: 20px 0;
        }

        .buttons {
            float: none;
            text-align: right;
            margin-bottom: 10px;
            overflow: hidden;

            a.button {
                .defaultLink;
                padding: 0;
                margin-right: 25px;
                margin-top: 12px;
                height: auto;
            }

            input.button {
                float: right;
                padding-left: 37px;
                background: @blue url("@{images}/icon_white-unlock.png") no-repeat 11px 7px;
                background-size: 16px 20px;

            }
        }

    }
}

@media @md {
    #loginBox {
        display: none;
        padding-top: 130px;
        padding-bottom: 50px;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-width: initial;
        z-index: 190;

        background: @white;
        .box-shadow(0 0 25px rgba(0, 0, 0, .55));

        .inputs {
            float: none;
            margin: 0 auto 25px auto;
            text-align: center;


            .clearfix;
        }

        .fCell {
            float: none;
            margin: 10px 0;


            label {
                width: 50px;
                text-align: right;
                font-size: 1.125em;
                overflow: visible;
            }

            input[type="text"],
            input[type="password"] {
                min-width: 100px;
                max-width: none;
                width: 100%;
                padding: 12px;
                margin-left: 0;
                text-align: left;
            }
        }

        .buttons {
            float: none;
            text-align: center;
            padding: 30px 0 0 0;
            margin: 25px auto 0 auto;
            border-top: 1px solid @graySeparator;
            display: table;
        }

        .error {
            right: 0;
        }
    }
}
