.highslide-container > table {
    display: none;
}

.highslide-wrapper {
    -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .3);
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, .3);

    .highslide-move {
        display: none;
    }

    .highslide-close {
        a {
            background: url("@{images}/close.gif") no-repeat;
            height: 14px;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 14px;
        }

        span {
            display: none;
        }
    }
}

.highslide-container {
    .closeBtn {
        text-align: right;

        a {
            width: 23px;
            height: 23px;
            display: inline-block;
            background: url("@{images}/icon_close-cross.png") no-repeat;
            background-size: 46px 23px;
            background-position: 0 0;
            border: none;
            transition: none;

            &:hover {
                background-position: -23px 0;
            }

            span {
                display: none;
            }
        }
    }

    div {
        font-family: @f-light;
        font-size: 1em;
    }
}
