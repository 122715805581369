.kz-dialog::backdrop {
  background-color: rgba(0, 54, 95, 0.6);
}

.kz-dialog {
  border: none;
  padding: 0;
  max-width: 800px;
  width: calc(100vw - 1rem);
  margin: auto;

  @supports not selector(dialog) {
    display: none;
  }

  &.show-dialog:not([open]) {
    display: block;
    position: fixed;
    inset-block-start: 0;
    inset-block-end: 0;
    max-height: calc((100% - 6px) - 2em);
    user-select: text;
    visibility: visible;
    overflow: auto;
    z-index: 30;
    box-shadow: 0 0 0 1000px rgba(0, 54, 95, 0.6);
  }

  img {
    max-width: 100%;
    height: auto;
    aspect-ratio: 1920 / 1080;
  }
}

.close-kz-dialog {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: @blue;
  border: none;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: @orange;
  }

  &:focus {
    outline: none;
    border: 0;
  }
}

.close-kz-dialog svg {
  fill: white;
}