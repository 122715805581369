.discussionPost {
    background: #f5f5f5;
    margin-bottom: 10px;
    padding: 10px 13px;

    h4 {
        margin-bottom: 4px;
    }

    p {
        margin-bottom: 0;
    }
}
