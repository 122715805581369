h1 {
    color: @orange;
    font-size: 1.8em;
    margin-bottom: 20px;
    margin-top: 0;
    .font-light;
}

h2 {
    color: @orange;
    font-size: 1.6em;
    margin-top: 25px;
    margin-bottom: 30px;
    .font-light;
}

h3 {
    color: @orange;
    font-size: 1.4em;
    margin-top: 20px;
    margin-bottom: 20px;
    .font-light;
}

h4, h5, h6 {
    color: @orange;
    font-size: 1.250em;
    margin: 0;
    padding: 0;
    .font-light;
}

h1, h2, h3, h4, h5 {
    line-height: normal;
}

h2.hl-documents {
    margin-top: 35px;
    margin-bottom: 15px;
}

@media only screen and (max-width : 800px) {

    h1, h2 {
        font-size: 2.250em;
    }
}

@media @xs {
    h1 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h2 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h3 {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
