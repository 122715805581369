.britanika_contactPerson {
    clear: both;
}

.contacts {
    margin-top: 55px;

    .ib-box {
        margin-bottom: 60px;
    }
}

.contact-img {
    float: left;
    width: 80px;

    img {
        border: 1px solid #e9e9e9;
        max-width: 100%;
    }

    .border-box {
        width: 80px;
        height: 80px;
        border: 1px solid #c7c7c7;
    }
}

.contact-info {
    padding-left: 100px;

    &-advisors {
        padding-left: 0;
    }

    p {
        margin-bottom: 10px;

        &.table {
            margin-bottom: 0;

            span {
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
    }

    .name {
        font-size: 25px;
        line-height: 1.25em;
    }

}

.fotoBox {
    background-color: @white;
    float: left;
    margin-right: 10px;
}

.fotoInfo {
    font-size: 0.9em;
    height: 40px;
    overflow: hidden;
}

.ib-box {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    padding: 0 30px 25px 0;
    margin-bottom: 40px;
}

.ib-col-3 {
    .ib-box {
        width: 33.1%;
    }
}


.tel-number {
    border-bottom: none;
    color: #000000;
    cursor: default;

    &:hover {
        border-bottom: none;
        color: #000000;
    }
}
