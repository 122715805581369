#filterSettingsContent {
    display: none;
    background-color: @white;
    margin: 0 auto 20px auto;
    max-width: 300px;
    padding: 30px 40px;

    .box-shadow(0 0 50px rgba(0, 0, 0, .2));

    .link {
        margin: 0 auto;
        text-align: center;
        display: block;
        background: @blue;

        &:hover {
            background: @red;
        }
    }

    h2 {
        font-size: 24px;
        margin-bottom: 15px;
        margin-top: 10px;
    }
}

ul#settingsContainer {
    margin: 30px 0 25px 0;
    padding: 0 0 20px 0;
    border-bottom: 1px solid @graySeparator;

    li {
        background: none;
        margin: 0;
        padding: 7px 60px 7px 25px;
        position: relative;

        &.hidden {
            label {
                color: #c1c1c1;
                .font-light;
            }

            .state {
                background-position: 0 -13px;
            }
        }

        input[type=checkbox] {
            display: none;
        }

        label {
            margin: 0;
            .font-regular;
        }

        .state {
            background: url("@{images}/icon_red-check.png") 0 0 no-repeat;
            background-size: 12px 13px;
            width: 12px;
            height: 13px;
            position: absolute;
            left: 0;
            top: 10px;
        }
    }

    .arrowContainer {
        padding-top: 10px;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
    }

    .arrow {
        background: url("@{images}/icon_circle-red-blue-caret-up-down.png") no-repeat;
        background-size: 32px 32px;
        float: left;
        height: 16px;
        width: 16px;
    }

    .arrow + .arrow {
        margin-left: 10px;
    }

    .arrow.up {
        background-position: 0 -16px;

        &:hover {
            background-position: -16px -16px;
        }
    }

    .arrow.down {
        background-position: 0 0;

        &:hover {
            background-position: -16px 0;
        }
    }

    .dojoDndItem {
        border-top: 2px solid @white;
        border-bottom: 2px solid @white;

        &.dojoDndItemAfter {
            border-bottom-color: @blue;
        }

        &.dojoDndItemBefore {
            border-top-color: @blue;
        }
    }
}
