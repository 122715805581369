.dijitCalendarPopup {
    font-size: .9em;
    background: @blue;
    padding: 10px;

    .dijitCalendarContainer {
        border: none;
        background: @blue;
        color: @white;
        width: 275px !important;

        .dijitButtonNode {
            position: relative;

            width: auto;
            height: auto;

            .dijitArrowButtonInner {
                display: none;
            }
        }

        .dijitCalendarMonthContainer {
            th {
                background: none;
                padding-bottom: .5em;
            }

            .dijitCalendarMonthLabel {
                color: @white;
                .font-light;
            }
        }

        .dijitCalendarDayLabelTemplate {
            background: @blue;
            color: #a8a8a8;
            padding-top: .5em;
            border-top-color: #d6d6d6;
        }

        .dijitCalendarPreviousMonth,
        .dijitCalendarNextMonth,
        .dijitCalendarCurrentMonth {
            background: none;
            .font-light;
        }

        .dijitCalendarPreviousMonth,
        .dijitCalendarNextMonth {
            color: #a8a8a8;
        }

        .dijitCalendarDateTemplate {
            padding: 0.3em;

            &.dijitCalendarHoveredDate {
                background: @red;
            }
        }

        .dijitCalendarYearContainer {
            background: none;
            color: @white;

            .dijitCalendarPreviousYear,
            .dijitCalendarNextYear,
            .dijitCalendarSelectedYear {
                color: @white !important;
                margin: 0 .25em;
                padding: .2em;
                font-size: .9em;
                .font-light;
            }

            .dijitCalendarSelectedYear {
                background: @red !important;
            }
        }
    }
}

.dijitCalendarMonthMenu {
    &.dijitMenu {
        border-color: @blue;

        .dijitCalendarMonthLabel {
            color: @blue;
            padding: 2px 0;
            .font-light;

            &.dijitCalendarMonthLabelHover {
                background: @red;
                color: @white;
            }
        }
    }
}
