.hp-login {
    text-align: center;
    position: relative;
    z-index: 20;

    h1 {
        margin-bottom: 10px;
    }

    h2 {
        font-size: 30px;
        margin: 0 0 10px;
        color: @blue;
        .font-light;
    }
}

.no-credentials {
    max-width: 500px;
    margin: 0 auto 5px;
    background: @gray;
    border-radius: 3px;
    padding: 20px 23px;

    h3 {
        margin: 0 0 10px;
        color: @blue;
        font-size: 18px;
    }

    p {
        margin: 0;
        font-size: 14px;
    }
}

@media @xl {
    .homepage-britanika {
        #loginBox {
            float: none;
            margin: 0 auto;
            min-width: 510px;

            .inputs {
                float: none;
                margin-top: 0;

            }

            .fCell {
                label {
                    margin-right: 0;
                }
            }

            .buttons {
                float: none;
                margin-right: 20px;
            }
        }
    }
}


@media @md {
    .homepage-britanika {
        #top {
            margin-bottom: 30px;
        }

        #loginBox {
            display: block;
            position: static;
            box-shadow: none;
            margin: 0;
            padding: 0;
            width: 100%;
            min-width: initial;

            .fCell {
                text-align: center;
            }

            .inputs {
                margin: 0;

                input {
                    min-width: 280px !important;
                    text-align: center;
                    display: block;
                    margin: 5px auto;
                }
            }


            .fCell {
                label {
                    margin-right: 0;
                    display: block;
                    text-align: left;
                    width: auto;
                }
            }

            .buttons {
                text-align: center;
                margin: 0 auto;
                display: block;
                border: none;

                &.loginForm {
                    padding-top: 0;
                    margin-bottom: -15px;
                }

                input.button {
                    display: block;
                    float: none;
                    margin: 0 auto;
                }

                a.button {
                    float: none;
                    display: inline-block;
                    margin: 15px auto 0 auto;
                }
            }
        }
    }
}
